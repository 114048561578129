var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.realContent,
          expression: "realContent",
        },
      ],
      staticClass: "img-container center tg-barcode",
    },
    [_c("img", { attrs: { alt: "" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }