var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        attrs: {
          href: _vm.data.clickUrl,
          "data-trace-id": _vm.data.traceId ? _vm.data.traceId : "",
          "data-store-id": _vm.data.storeId,
          "data-scp": _vm.scpC + "." + _vm.index,
          "data-bk": _vm.bk + "-" + _vm.data.id,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "pos-r bg-white",
            staticStyle: { overflow: "hidden", "border-radius": "8px" },
          },
          [
            _c("div", {}, [
              _c("img", { attrs: { alt: "", src: _vm.data.imageUrl } }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex1 paddingh20 paddingt10 paddingb20" },
              [
                _c("div", { staticStyle: { width: "100%" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "fs-13 text-darker",
                      class: { "text-fixed2": _vm.isTgzy },
                    },
                    [_c("strong", [_vm._v(_vm._s(_vm.data.title))])]
                  ),
                  _vm._v(" "),
                  !_vm.isTgzy || _vm.data.couponList.length
                    ? _c(
                        "div",
                        {
                          staticClass: "margint10",
                          staticStyle: { height: "22px", overflow: "hidden" },
                        },
                        [
                          _c("coupon", {
                            attrs: {
                              data: _vm.data.couponList,
                              isTgzy: _vm.isTgzy,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex-container bottom margint10",
                    staticStyle: { width: "100%" },
                  },
                  [
                    (_vm.showNewPrice && _vm.data.newPrice) ||
                    _vm.data.groupPrice
                      ? [
                          _vm.showNewPrice && _vm.data.newPrice
                            ? _c(
                                "div",
                                { staticClass: "flex-container left center" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "fs-12 text-red" },
                                    [_vm._v("新人价")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-red fs-18 text-right",
                                    },
                                    [
                                      _c("span", { staticClass: "fs-12" }, [
                                        _vm._v("¥"),
                                      ]),
                                      _vm._v(_vm._s(_vm.data.newPrice)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.data.originalPrice !== _vm.data.newPrice
                                    ? _c(
                                        "del",
                                        {
                                          staticClass:
                                            "text-light fs-12 marginl10 lh-16",
                                        },
                                        [_vm._v(_vm._s(_vm.data.originalPrice))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.groupPrice
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    { name: "else", rawName: "v-else" },
                                  ],
                                  staticClass: "flex-container left center",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "fs-12 text-red" },
                                    [_vm._v("拼团价")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "flex1 text-red fs-18 text-right",
                                    },
                                    [
                                      _c("span", { staticClass: "fs-12" }, [
                                        _vm._v("¥"),
                                      ]),
                                      _vm._v(_vm._s(_vm.data.groupPrice)),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "fs-12 text-darker marginl10",
                                    },
                                    [_vm._v("单买价" + _vm._s(_vm.data.price))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _c(
                          "div",
                          { staticClass: "flex-container left center" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "flex1 text-red fs-18 text-right",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _c("span", { staticClass: "fs-12" }, [
                                  _vm._v("¥"),
                                ]),
                                _vm._v(
                                  _vm._s(
                                    _vm.data.buyoutPrice || _vm.data.price
                                  ) + "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.data.originalPrice !== _vm.data.price
                              ? _c(
                                  "del",
                                  {
                                    staticClass:
                                      "text-light fs-12 marginl10 lh-16",
                                  },
                                  [_vm._v(_vm._s(_vm.data.originalPrice))]
                                )
                              : _vm._e(),
                          ]
                        ),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.isTgzy
                  ? _c("div", { staticStyle: { "min-height": "22px" } }, [
                      _vm.data.vipPrice
                        ? _c("div", { staticClass: "flex-container left" }, [
                            _c("span", { staticClass: "text-black fs-16" }, [
                              _c("span", { staticClass: "fs-12" }, [
                                _vm._v("¥"),
                              ]),
                              _vm._v(_vm._s(_vm.data.vipPrice)),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: "//image1.51tiangou.com/tgou2/img2/product/ico-up_05.png",
                                width: "48",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: _vm.getTagByItem(_vm.data).imgUrl,
                      alt: "",
                      height: "16px",
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }