<template>
<div class="tg-loading">
	<div class="loading" v-show="(state||$root.loadMoreState)==='loading'">
	</div>
	<div v-show="(state||$root.loadMoreState)==='end'" class="padding20 text-center end">
  <slot>哎呦~一不小心就到底啦！</slot>
	</div>
</div>
</template>
<style type="text/css" lang="scss">
.tg-loading{
  overflow: hidden;
  .loading{
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #ff4c48;
    -webkit-animation: tgload 1.1s infinite linear;
    animation: tgload 1.1s infinite linear;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
}
@-webkit-keyframes tgload {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes tgload {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
<script>
export default {
	props:{
		state:{type:String,default:''}
	}
}
</script>