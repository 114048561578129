import axios                                                      from 'axios';
import qs                                                         from 'qs';
import {getSec, setSec, getTimestamp, setTimestamp, cheatBreaker} from 'javascript/libs/tg/util/sec.js';
import memberService                                              from 'spa/lib/api/member.js';
import App from "../../javascript/libs/tg/app";

const Axios = axios.create({
        baseURL: '/'
        , timeout: 20000
        , responseType: 'json'
        , withCredentials: true
        , headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
    })
    , CancelToken = axios.CancelToken
    , requestMap = new Map()
    , requestData = new Map()
    ;

// 请求前置拦截器
Axios.interceptors.request.use((config)=>{
    // 防重复提交
    if( requestMap.get(config._keyString) ){
        // 取消当前请求
        config.cancelToken = new CancelToken((cancel)=>{
            cancel('请求频繁');
        });
    }

    requestMap.set(config._keyString, true);

    return new Promise((resolve)=>{
        // 防黄牛
        if( config.needSecKey ){
            memberService.secKey().then(()=>{
                _.assign(config.data, {
                    secKey: getSec()
                    , timestamp: getTimestamp()
                    , secValue: cheatBreaker()
                });

                resolve( config );
            });
        }
        else{
            resolve( config );
        }
    }).then((config)=>{   // 序列化
        // 未设置 contentType
        if( (config.method === 'post'
                || config.method === 'put'
                || config.method === 'delete')
            && !config.contentType ){

            config.data = qs.stringify( config.data );
        }

        if( config.contentType ){
            config.headers['Content-Type'] = config.contentType;
        }

        return config;
    });
}, (error)=>{
    return Promise.reject( error );
});

window.addEventListener('unhandledrejection', function(e){
	e.preventDefault();

	console.log( e );
});

// 返回响应拦截器
Axios.interceptors.response.use((res)=>{
    // 重置 requestMap
    const config = res.config
        ;

    requestMap.set(config._keyString, false);

    if( res.status === 200 && res.data ){
        const responseData = res.data
            , data = responseData.data
            , code = responseData.code
            ;

        if( responseData.success ){
            // 防黄牛
            setSec( responseData.secKey );
            setTimestamp( responseData.timestamp );
            // 缓存数据
            setCatchDate(config._keyString, data, config.useCatch);

            return data;
        }
        else{
            switch( code ){
                case 888:
                    console.log(config._keyString, '需要登录');
                    let href = `/login/login.html?loginBack=${encodeURIComponent( location.href )}`
                    if(maple.cookie('hybrid')){
                        let absUrl = '', appCode='dengLu';
                        absUrl = toAbsUrl(href);
                        App.tgChangePage(appCode,absUrl);
                        App.unmask();
                    } else {
                        location.href = href;
                    }
                    break;
                case 889:
                    maple.alert(responseData.message, ()=>{
                        let href = `/login/login.html?loginBack=${encodeURIComponent( location.href )}`
                        if(maple.cookie('hybrid')){
                            let absUrl = '', appCode='dengLu';
                            absUrl = toAbsUrl(href);
                            App.tgChangePage(appCode,absUrl);
                            App.unmask();
                        } else {
                            location.href = href;
                        }
                    });
                    break;
                case 100:
	                /**
	                 * 2021-11-03
	                 * 现在 100 错误类型为 第一次联合登录需要快捷登录，目前不再弹窗提示
	                 * */
	                // maple.confirm({
					// 	content: responseData.message
					// 	, cancelText: '算了'
					// 	, cancelFn: ()=>{}
					// 	, okText: '去登录'
					// 	, okFn: ()=>{
					// 		location.href = '/login/login.html?type=1';
					// 		// location.href = '/index/loadAndroid.html';
					// 	}
					// });
                    break;
                default:
                    _.isFunction( config.tgError )
                        ? config.tgError( responseData )
                        : maple.notice( responseData.message );
            }
            return Promise.reject( responseData.message );
        }
    }
    // todo 弹窗提示等
    maple.notice(`request error：${res}`);
}, (error)=>{
    requestMap.set(_.get(error, 'config._keyString'), false);

    if( location.host !== 'm.51tiangou.com' ){
        let { url } = error.config
            , serviceName = _.get(/\/\/(\w+)/.exec(url), '[1]')
            ;

        maple.confirm({
            title: (serviceName === 'midway') ? '某个后台接口把中间件弄挂了' : `${serviceName}挂了`
            , content: `接口地址为</br>${url}`
            , cancelText: '重新加载'
            , okText: '关闭弹窗'
            , cancelFn(){
                location.reload();
            }
        });
    }
    else{
        maple.notice('网络请求不给力');
    }

    return Promise.reject( error );
});

/**
 * 设置缓存数据
 * @param key 缓存标识
 * @param data 缓存数据
 * @param level 缓存等级 usecatch 为 0 或 false 不缓存
 *                                  1 变量级缓存，刷新失效
 *                                  2 session 级别缓存，关闭浏览器失效
 *                                  3 local 级缓存永久有效
 */
function setCatchDate(key, data, level){
	if( level ){
		requestData.set(key, data);

		switch( level ){
			case 2:
				maple.sessionData(key, data);
				break;
			case 3:
				maple.localData(key, data);
				break;
		}
	}
}

function getCatchDate(key){
	return requestData.get( key ) || maple.sessionData( key ) || maple.localData( key );
}

function togetherData(catchData){
	if( _.isArray( catchData ) && _.get(_.last( catchData ), 'key') === 'loadMore' ){
		const nextUrl = _.last( catchData ).data
            , _keyObject = {
                url: nextUrl.split('?')[0]
                , method: 'get'
                , data: maple.url( nextUrl ).params
            }
            , _keyString = qs.stringify( _keyObject )
            ;

		let tempCatchData = getCatchDate( _keyString )
            ;

		if( tempCatchData ){
			catchData.pop();
			catchData.push( ...togetherData(tempCatchData) );
		}
	}

	return catchData;
}

// url, data = {}, method = 'post',useCatch = true
const request = (url, data={}, method='post', useCatch=true)=>{
	if( url ){
		let tgError
            , needSecKey    // 黄牛算法
            , contentType
            ;

		if( _.isObject(url) ){
			({url, data, method, useCatch, tgError, needSecKey, contentType} = url);
		}

		data = data || {};

		const _keyString = qs.stringify({
                url
                , method
                , data
		    })
            ;
		let catchData = getCatchDate( _keyString )
            ;

		if( useCatch && catchData ){
			togetherData( catchData );
			return new Promise((res)=>res( catchData ) );
		}
		else if( method === 'get' ){
			_.assign(data, {
			    _: +new Date
			});
		}

		// 从接口读取数据
		return Axios({
			method
            , url
            , data
            , params: method.toUpperCase() === 'GET' && data
            , useCatch
            , tgError
            , _keyString
            , needSecKey
			, contentType
		});
	}
	else{
		throw 'request need arguments';
	}
};

/**
 * 将url转化为绝对路径
 * @param url
 * @returns {boolean}
 */
function toAbsUrl(url){
    if(!url){
        return false;
    }
    var a = document.createElement('a');
    a.href = url;
    return a.href;
}

export default request;
