<template>
<!-- 评价 -->
<div>
	<a data-scp="evaluate.00"
	   :href="evaluateBanner.clickUrl"
	   v-show="evaluateBanner && evaluateBanner.num!==0">
		<div class="flex-container bg-white margint20 padding20 text-dark" style="height: 45px;">
			<div v-text="'评价（'+evaluateBanner.num+'）'"></div>
			<div>
				<span class="text-red" v-text="'平均分：'+evaluateBanner.average"></span>
				<span class="lh-20 dis-i"><span class="icon-right fs-11 text-light"></span></span>
			</div>
		</div>
	</a>
	<div class="flex-container bg-white margint20 padding20 text-dark" style="height: 45px;"
		 v-show="evaluateBanner && evaluateBanner.num===0">
		<div>暂无评价</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'tg-evaluate'
	, props: {
		evaluateBanner: {
			/**
			 * @example
			 * {
				"num": 1,
				"average": 5,
				"clickUrl": "//m.test.66buy.com.cn/pingjia.html?itemId=5511520"
			}
			 * */
			type: Object
			, default: {}
		}
	}
};
</script>