<template>
<a v-show="traceSource.clickUrl"
   :href="traceSource.clickUrl"
   data-scp="traceSource.00">
	<div class="flex-container paddingh20 paddingv10 borderb lh-20" style="background: #f8f8f8;">
		<div class="flex-container text-orange fs-12">
			<img class="marginr10" src="//image1.51tiangou.com/tgou2/img2/product/icon-GGCT.png" width="14" height="16" alt="">
			直采精选，查看 [商品溯源]
		</div>
		<span class="dis-i"><span class="icon-right fs-11 text-light"></span></span>
	</div>
</a>
</template>

<script>
export default {
	name: 'tg-trace-source'
	, props: {
		traceSource: {
			type: Object
			, default: {}
		}
	}
};
</script>