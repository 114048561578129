var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shop.title,
          expression: "shop.title",
        },
      ],
      staticClass: "flex-container bg-white margint20 borderv padding20",
    },
    [
      _c("div", { staticClass: "flex-container left" }, [
        _c("img", {
          staticClass: "border",
          attrs: { width: "50", height: "50", src: _vm.shop.logoImageUrl },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "marginl20" }, [
          _c("div", {
            staticClass: "fs-15 text-darker",
            domProps: { textContent: _vm._s(_vm.shop.title) },
          }),
          _vm._v(" "),
          _vm.shop._prop_.type === 1
            ? _c(
                "div",
                { staticClass: "flex-container left margint10 text-dark" },
                [
                  _c("i", { staticClass: "icon-locate fs-12" }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "fs-10 marginl10",
                    domProps: { textContent: _vm._s(_vm.shop.address) },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.shop._prop_.type === 2 && _vm.shop.authorize
            ? _c(
                "span",
                {
                  staticClass: "badge badge-black pill margint10",
                  staticStyle: { "font-size": "10px" },
                },
                [_vm._v("品牌官方授权")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("a", { attrs: { href: _vm.shop.clickUrl, "data-scp": "shop.0" } }, [
          _c("span", {
            staticClass: "badge badge-reverse badge-fill badge-red",
            staticStyle: {
              "font-size": "10px",
              padding: "4px 5px",
              "border-radius": "20px",
            },
            domProps: { textContent: _vm._s(_vm.shop.btnName) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }