<template>
<div class="tg-poster"
     v-if="data.length">
	<component :is="`type${item._prop_.type}`"
	           class="tg-poster-item"
	           :data="item"
	           :index="index"
	           v-for="(item,index) in data"></component>
</div>
</template>

<script>
import * as posterItem from './posterItem/index.js';

export default {
	name: 'poster'
	, components: posterItem
	, props: {
		data: {
			type: Array
			, default: []
		}
	}
};
</script>

<style lang="scss" type="text/scss">
.tg-poster{
    .tg-poster-item{
        &:not(:last-child){
            margin-bottom:.2666666667rem;
        }
    }
    a{
        display: block;
    }
}
</style>