<template>
<div class="margint30 marginh20">
	<div class="bg-white border-radius"
	     style="background-image: linear-gradient(180deg, #FFF7F7 0%, #FFEBEB 100%);border-radius:4px;"
	     v-if="theme == 1">
		<!--             <div class="padding20 flex-container">
						<img src="//image1.51tiangou.com/tgou2/img2/mine/title-maibisheng.png" alt="" height="16px">
						<div class="fs-11 text-light" v-show="maiBiSheng.length > 8"><a href="/tempPage/maiBiSheng.html" data-scp="timeLimit.all" class="text-light">查看全部<i class="icon-right" style="color:#d8d8d8"></i></a></div>
					</div> -->
		<!--             <div class="padding20">
						<img src="//image1.51tiangou.com/tgou2/img2/mine/title-maibisheng_2.png" alt="" width="17px">
					</div> -->
		<div class="padding10 pos-r"
		     style="padding-left:45px;">
			<div class="pos-a"
			     style="left:14px;top:17px;">
				<img src="//image1.51tiangou.com/tgou2/img2/mine/title-maibisheng_2.png"
				     alt=""
				     width="17px">
			</div>
			<!-- theme 1 -->
			<!-- 小于4个商品 -->
			<div class="scroll-container flex-container vfull"
			     v-if="maiBiSheng.length < 5">
				<a :href="maiBiSheng.length > 1 ? '/tempPage/maiBiSheng.html' : get(item, index, 'item').clickUrl"
				   v-for="(item,index) in maiBiSheng.slice(0,4)"
				   :data-scp="'mproduct'+(from?'-'+from:'') + '.'+get(item, index, 'index')"
				   style="width: 100%">
					<div class="border-radius flex-container top bg-white"
					     :style="maiBiSheng.length > 1 ? 'width: 260px;margin-right:5px;overflow: hidden;border-radius:2px;' : 'overflow: hidden;border-radius:2px;'">
						<div class="ratio-container img-container"
						     style="min-width:120px;height:120px;">
							<img :src="get(item, index, 'item').imageUrl"
							     onerror="this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'"
							     alt="">
						</div>
						<div class="flex1 padding20 margint10"
						     style="white-space: normal;">
							<div class="text-fixed2 fs-13 text-darker text-bolder">
								{{ get(item, index, 'item').title }}
							</div>
							<div class="fs-10 text-red flex-container left"
							     style="flex-wrap: wrap;height: 24px;overflow: hidden;">
								<div class="border radius margint10 paddingh10 text-center marginr10"
								     style="background: #FFF6F6;border: 1px solid #FF4C48;line-height: 16px;border-radius: 2px;"
								     v-for="itemCoupon in get(item, index, 'item').couponList">{{ itemCoupon }}
								</div>
							</div>
							<div class="flex-container vfull left center margint10"
							     style="overflow: hidden;flex-wrap: wrap;height: 26px;">
                            <span class="text-red fs-13"
                                  v-if="get(item, index, 'item').newPrice"><span
                                style="flex-shrink: 0;">新人价</span> <span
                                class="fs-19 text-bolder flex1 marginl10">¥{{ get(item, index, 'item').newPrice }}</span></span>
								<span class="text-red fs-13 flex-container left"
								      v-if="get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice"><span
									style="flex-shrink: 0;">拼团价</span> <span
									class="fs-19 text-bolder flex1 marginl10">¥{{ get(item, index, 'item').groupPrice }}</span></span>
								<span class="text-red fs-19 text-right text-bolder"
								      v-if="!get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice">¥{{ get(item, index, 'item').price }}</span>
								<del class="text-light fs-11 marginl10 flex-container"
								     v-show="get(item, index, 'item').originalPrice !== get(item, index, 'item').price">
									¥{{ get(item, index, 'item').originalPrice }}
								</del>
							</div>
						</div>
					</div>
				</a>

				<div class="moreProduct bg-white border-radius fs-12 lh-20 text-center flex-container center"
				     v-if="maiBiSheng.length > 1">
					<a href="/tempPage/maiBiSheng.html"
					   :data-scp="'mproduct'+(from?'-'+from:'') + '.all'"
					   class="text-light">查看更多
					</a>
				</div>
			</div>
			<!-- 4个以上商品 -->
			<div class="scroll-container flex-container vfull"
			     v-if="maiBiSheng.length > 4">
				<a href="/tempPage/maiBiSheng.html"
				   :data-scp="'mproduct'+(from?'-'+from:'') + '.'+get(item, index, 'index')"
				   v-for="(item,index) in maiBiSheng.slice(0,8)">
					<div class="border-radius bg-white"
					     style="margin-right:5px;border-radius:2px;">
						<div class="ratio-container img-container pos-r"
						     style="width:100px;height:100px;">
							<img :src="get(item, index, 'item').imageUrl"
							     onerror="this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'"
							     alt="">
							<div class="text-center pos-a bottom10 text-red flex-container center"
							     style="top:auto;font-size: 9px;line-height: 14px;">
								<div class="paddingh20 text-limit1"
								     style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
								     v-if="get(item, index, 'item').newPrice">
									新人价 ¥{{ get(item, index, 'item').newPrice }}
								</div>
								<div class="paddingh20 text-limit1"
								     style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
								     v-if="get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice">
									拼团价 ¥{{ get(item, index, 'item').groupPrice }}
								</div>
								<div class="paddingh20 text-limit1"
								     style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
								     v-if="!get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice"
								     v-for="itemCoupon in (get(item, index, 'item').couponList || []).slice(0,1)">
									{{ itemCoupon }}
								</div>
							</div>
						</div>
						<div class="flex1 padding10">
							<div class="flex-container left center"><span
								class="text-red fs-14 text-right text-bolder">¥{{ get(item, index, 'item').price }}</span>
								<del class="text-light fs-10 marginl10 lh-16"
								     v-show="get(item, index, 'item').originalPrice !== get(item, index, 'item').price">
									¥{{ get(item, index, 'item').originalPrice }}
								</del>
							</div>
						</div>
					</div>
				</a>
				<div class="moreProduct bg-white border-radius fs-12 lh-20 text-center flex-container center">
					<a href="/tempPage/maiBiSheng.html"
					   :data-scp="'mproduct'+(from?'-'+from:'') + '.all'"
					   class="text-light">查看更多
					</a>
				</div>
			</div>
		</div>
	</div>
	<div v-if="theme == 2">
		<div class="scroll-container flex-container vfull">
			<a :href="maiBiSheng.length > 1 ? '/tempPage/maiBiSheng.html' : get(item, index, 'item').clickUrl"
			   :style="maiBiSheng.length > 1 ?'    min-width: 320px;margin-right:.2666666667rem;overflow: hidden;' : 'overflow: hidden;width: 100%'"
			   :data-scp="'mproduct'+(from?'-'+from:'') + '.'+get(item, index, 'index')"
			   v-for="(item,index) in maiBiSheng.slice(0,4)">
				<div class="flex-container vfull border-radius top bgTheme2">
					<div class="ratio-container img-container"
					     style="width:120px;height:120px;">
						<img :src="get(item, index, 'item').imageUrl"
						     onerror="this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'"
						     alt="">
					</div>
					<div class="flex1 paddingh20 flex-container-col">
						<div class="text-fixed2 fs-14 text-darker text-bolder"
						     style="width: 100%">{{ get(item, index, 'item').title }}
						</div>
						<div class="fs-10 text-red flex-container left"
						     style="flex-wrap: wrap;height: 24px;overflow: hidden;width: 100%">
							<div class="border radius margint10 paddingh10 text-center marginr10"
							     style="background: #FFF6F6;border: 1px solid #FF4C48;line-height: 16px;border-radius: 2px;"
							     v-for="itemCoupon in get(item, index, 'item').couponList">{{ itemCoupon }}
							</div>
						</div>
						<div class="flex-container left center margint10"
						     style="width: 100%">
                        <span class="flex-container left center text-red fs-12"
                              v-if="get(item, index, 'item').newPrice"><span
                            class="text-bolder">新人价</span><span
                            class="fs-18 marginl10">¥{{ get(item, index, 'item').newPrice }}</span></span>
							<span class="flex-container left center text-red fs-12"
							      v-if="get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice"><span class="text-bolder">拼团价</span><span
								class="fs-18 marginl10">¥{{ get(item, index, 'item').groupPrice }}</span></span>
							<span class="text-red fs-18 text-right"
							      v-if="!get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice">¥{{ get(item, index, 'item').price }}</span>
							<del class="text-light fs-12 marginl10"
							     v-show="get(item, index, 'item').originalPrice != get(item, index, 'item').price">
								¥{{ get(item, index, 'item').originalPrice }}
							</del>
						</div>
						<div class="flex-container left center margint10"
						     style="width: 100%">
							<div class="badge red pill big float-right"
							     style="padding: 7px 18px"><span
								class="text-limit1 text-white fs-14"
								style="">马上抢</span></div>
						</div>
					</div>
				</div>
			</a>
			<div class="moreProduct bg-white border border-radius fs-12 lh-20 text-center flex-container center"
			     v-if="maiBiSheng.length > 4">
				<a href="/tempPage/maiBiSheng.html"
				   :data-scp="'mproduct'+(from?'-'+from:'') + '.all'"
				   class="text-light">查看更多
				</a>
			</div>
		</div>
	</div>
	<div v-if="theme == 3">
		<div class="bg-white pos-r padding20 borderR8">
			<div class="flex-container">
				<div class="flex-container left">
					<img src="//image1.51tiangou.com/tgou2/img2/index/title-miaoSha.png"
					     height="24"
					     alt="">
					<img src="//image1.51tiangou.com/tgou2/img2/index/img-fengQiang.png"
					     class="marginl10"
					     height="16"
					     alt="">
				</div>
				<div class="fs-12 lh-1">
					<a href="/tempPage/maiBiSheng.html"
					   :data-scp="'mproduct'+(from?'-'+from:'') + '.all'"
					   class="text-light flex-container right"><span>更多</span>
						<img src="//image1.51tiangou.com/tgou2/img2/index/ico-right.png"
						     class="marginl10"
						     height="16"
						     alt="">
					</a>
				</div>
			</div>
			<!-- 4个以上商品 -->
			<div class="scroll-container margint20 flex-container vfull">
				<a :href="'/tempPage/maiBiSheng.html?index='+get(item, index, 'index')"
				   :data-scp="'mproduct'+(from?'-'+from:'') + '.'+get(item, index, 'index')"
				   v-for="(item,index) in maiBiSheng.slice(0,8)">
					<div class="border-radius bg-white"
					     style="margin-right:5px;border-radius:2px; width: 104px;">
						<div class="ratio-container img-container pos-r"
						     style="width:104px;height:104px;">
							<img :src="get(item, index, 'item').imageUrl"
							     onerror="this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'"
							     alt="">

							<div class="text-center pos-a bottom10 text-red flex-container center"
							     style="top:auto;font-size: 9px;line-height: 14px;">
								<div class="paddingh20 text-limit1"
								     style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
								     v-if="get(item, index, 'item').newPrice">
									新人价 ¥{{ get(item, index, 'item').newPrice }}
								</div>
								<div class="paddingh20 text-limit1"
								     style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
								     v-if="get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice">
									拼团价 ¥{{ get(item, index, 'item').groupPrice }}
								</div>
								<!-- <div class="paddingh20 text-limit1"
									 style="background: rgba(255,255,255,0.80);border: 1px solid #FF4C48;border-radius:20px;"
									 v-if="!get(item, index, 'item').groupPrice&&!get(item, index, 'item').newPrice"
									 v-for="itemCoupon in get(item, index, 'item').couponList.slice(0,1)">{{itemCoupon}}
								</div> -->
							</div>
						</div>
						<div class="paddingh10 margint10 text-black fs-12 text-limit1"
						     style="white-space: normal;"
						     v-text="get(item, index, 'item').title"></div>
						<div class="paddingh10">
							<div class="flex-container center"><span
								class="text-red fs-14 text-right">¥{{ get(item, index, 'item').price }}</span>
								<del class="text-light fs-10 marginl10 lh-16"
								     v-show="get(item, index, 'item').originalPrice !== get(item, index, 'item').price">
									¥{{ get(item, index, 'item').originalPrice }}
								</del>
							</div>
						</div>
						<div>
							<div class="lineNum pos-r">
								<div class="text-white fs-10 lh-1"
								     :style="`width:`+percent(item, index)+`%`">
									<span class="pos-a left0 bottom0 top0 right0 text-center"
									      v-text="`${percent(item, index)}%`"></span>
								</div>
							</div>
						</div>
					</div>
				</a>
				<!-- <div class="moreProduct bg-white border-radius fs-12 lh-20 text-center flex-container center"><a
						href="/tempPage/maiBiSheng.html" :data-scp="'timeLimit'+(from?'-'+from:'') + '.all'" class="text-light">查看更多</a></div> -->
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array
            , default: []
        }
        , theme: {
            type: Number
            , default: 1
        }
        , cTheme: {
            type: Number
            , default: 0
        }
        , from: {
            type: String
            , default: ''
        }
        , version: {
            type: Number
	        , default: 2
        }
    }
	, data: function(){
        return {
            maiBiSheng: this.data
        };
    }
    , methods: {
        get(item, index, key){
            if( this.version === 1 ){
                if( key === 'item' ){
                    return index;
		        }
                else if( key === 'index' ){
                    return item;
		        }
	        }
            else if( this.version === 2 ){
                if( key === 'item' ){
                    return item;
		        }
                else if( key === 'index' ){
                    return index;
		        }
	        }
        }
        , percent(item, index){
            if( this.get(item, index, 'item').stock === 0 ){
                return 100;
	        }

            return Math.floor((this.get(item, index, 'item').soldQty /(this.get(item, index, 'item').soldQty + this.get(item, index, 'item').stock )) * 100);
	    }
    }
};
</script>

<style scoped>
.moreProduct {
    min-width: 60px;
    padding: 0 20px;
    white-space: normal;
}
.borderR8{
  border-radius:8px;
}
.bgTheme2 {
    background: #FFF0F0;
    padding: 8px;
}
.lineNum{
  height:10px;
  overflow: hidden;
  background:#EBEBEB;
  border-radius: 8px;
  margin-left:2px;
  margin-right:2px;
}
.lineNum>div{
  background-image: linear-gradient(238deg, #FF6100 18%, #FF3D3D 78%);
  border-radius: 10px;
  height:100%;
}
</style>