var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "margint30 marginh20" }, [
    _vm.theme == 1
      ? _c(
          "div",
          {
            staticClass: "bg-white border-radius",
            staticStyle: {
              "background-image":
                "linear-gradient(180deg, #FFF7F7 0%, #FFEBEB 100%)",
              "border-radius": "4px",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "padding10 pos-r",
                staticStyle: { "padding-left": "45px" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm.maiBiSheng.length < 5
                  ? _c(
                      "div",
                      { staticClass: "scroll-container flex-container vfull" },
                      [
                        _vm._l(
                          _vm.maiBiSheng.slice(0, 4),
                          function (item, index) {
                            return _c(
                              "a",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  href:
                                    _vm.maiBiSheng.length > 1
                                      ? "/tempPage/maiBiSheng.html"
                                      : _vm.get(item, index, "item").clickUrl,
                                  "data-scp":
                                    "mproduct" +
                                    (_vm.from ? "-" + _vm.from : "") +
                                    "." +
                                    _vm.get(item, index, "index"),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border-radius flex-container top bg-white",
                                    style:
                                      _vm.maiBiSheng.length > 1
                                        ? "width: 260px;margin-right:5px;overflow: hidden;border-radius:2px;"
                                        : "overflow: hidden;border-radius:2px;",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ratio-container img-container",
                                        staticStyle: {
                                          "min-width": "120px",
                                          height: "120px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.get(item, index, "item")
                                              .imageUrl,
                                            onerror:
                                              "this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex1 padding20 margint10",
                                        staticStyle: {
                                          "white-space": "normal",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-fixed2 fs-13 text-darker text-bolder",
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.get(item, index, "item")
                                                    .title
                                                ) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fs-10 text-red flex-container left",
                                            staticStyle: {
                                              "flex-wrap": "wrap",
                                              height: "24px",
                                              overflow: "hidden",
                                            },
                                          },
                                          _vm._l(
                                            _vm.get(item, index, "item")
                                              .couponList,
                                            function (itemCoupon) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "border radius margint10 paddingh10 text-center marginr10",
                                                  staticStyle: {
                                                    background: "#FFF6F6",
                                                    border: "1px solid #FF4C48",
                                                    "line-height": "16px",
                                                    "border-radius": "2px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(itemCoupon) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-container vfull left center margint10",
                                            staticStyle: {
                                              overflow: "hidden",
                                              "flex-wrap": "wrap",
                                              height: "26px",
                                            },
                                          },
                                          [
                                            _vm.get(item, index, "item")
                                              .newPrice
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-red fs-13",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "flex-shrink": "0",
                                                        },
                                                      },
                                                      [_vm._v("新人价")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fs-19 text-bolder flex1 marginl10",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "¥" +
                                                            _vm._s(
                                                              _vm.get(
                                                                item,
                                                                index,
                                                                "item"
                                                              ).newPrice
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.get(item, index, "item")
                                              .groupPrice &&
                                            !_vm.get(item, index, "item")
                                              .newPrice
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-red fs-13 flex-container left",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "flex-shrink": "0",
                                                        },
                                                      },
                                                      [_vm._v("拼团价")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fs-19 text-bolder flex1 marginl10",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "¥" +
                                                            _vm._s(
                                                              _vm.get(
                                                                item,
                                                                index,
                                                                "item"
                                                              ).groupPrice
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.get(item, index, "item")
                                              .groupPrice &&
                                            !_vm.get(item, index, "item")
                                              .newPrice
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-red fs-19 text-right text-bolder",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "¥" +
                                                        _vm._s(
                                                          _vm.get(
                                                            item,
                                                            index,
                                                            "item"
                                                          ).price
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "del",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).originalPrice !==
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).price,
                                                    expression:
                                                      "get(item, index, 'item').originalPrice !== get(item, index, 'item').price",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-light fs-11 marginl10 flex-container",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t¥" +
                                                    _vm._s(
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).originalPrice
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _vm.maiBiSheng.length > 1
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "moreProduct bg-white border-radius fs-12 lh-20 text-center flex-container center",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-light",
                                    attrs: {
                                      href: "/tempPage/maiBiSheng.html",
                                      "data-scp":
                                        "mproduct" +
                                        (_vm.from ? "-" + _vm.from : "") +
                                        ".all",
                                    },
                                  },
                                  [_vm._v("查看更多\n\t\t\t\t\t")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.maiBiSheng.length > 4
                  ? _c(
                      "div",
                      { staticClass: "scroll-container flex-container vfull" },
                      [
                        _vm._l(
                          _vm.maiBiSheng.slice(0, 8),
                          function (item, index) {
                            return _c(
                              "a",
                              {
                                attrs: {
                                  href: "/tempPage/maiBiSheng.html",
                                  "data-scp":
                                    "mproduct" +
                                    (_vm.from ? "-" + _vm.from : "") +
                                    "." +
                                    _vm.get(item, index, "index"),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "border-radius bg-white",
                                    staticStyle: {
                                      "margin-right": "5px",
                                      "border-radius": "2px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ratio-container img-container pos-r",
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.get(item, index, "item")
                                              .imageUrl,
                                            onerror:
                                              "this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-center pos-a bottom10 text-red flex-container center",
                                            staticStyle: {
                                              top: "auto",
                                              "font-size": "9px",
                                              "line-height": "14px",
                                            },
                                          },
                                          [
                                            _vm.get(item, index, "item")
                                              .newPrice
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "paddingh20 text-limit1",
                                                    staticStyle: {
                                                      background:
                                                        "rgba(255,255,255,0.80)",
                                                      border:
                                                        "1px solid #FF4C48",
                                                      "border-radius": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t新人价 ¥" +
                                                        _vm._s(
                                                          _vm.get(
                                                            item,
                                                            index,
                                                            "item"
                                                          ).newPrice
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.get(item, index, "item")
                                              .groupPrice &&
                                            !_vm.get(item, index, "item")
                                              .newPrice
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "paddingh20 text-limit1",
                                                    staticStyle: {
                                                      background:
                                                        "rgba(255,255,255,0.80)",
                                                      border:
                                                        "1px solid #FF4C48",
                                                      "border-radius": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t拼团价 ¥" +
                                                        _vm._s(
                                                          _vm.get(
                                                            item,
                                                            index,
                                                            "item"
                                                          ).groupPrice
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              (
                                                _vm.get(item, index, "item")
                                                  .couponList || []
                                              ).slice(0, 1),
                                              function (itemCoupon) {
                                                return !_vm.get(
                                                  item,
                                                  index,
                                                  "item"
                                                ).groupPrice &&
                                                  !_vm.get(item, index, "item")
                                                    .newPrice
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "paddingh20 text-limit1",
                                                        staticStyle: {
                                                          background:
                                                            "rgba(255,255,255,0.80)",
                                                          border:
                                                            "1px solid #FF4C48",
                                                          "border-radius":
                                                            "20px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(itemCoupon) +
                                                            "\n\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "flex1 padding10" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-container left center",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-red fs-14 text-right text-bolder",
                                              },
                                              [
                                                _vm._v(
                                                  "¥" +
                                                    _vm._s(
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).price
                                                    )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "del",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).originalPrice !==
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).price,
                                                    expression:
                                                      "get(item, index, 'item').originalPrice !== get(item, index, 'item').price",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-light fs-10 marginl10 lh-16",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t¥" +
                                                    _vm._s(
                                                      _vm.get(
                                                        item,
                                                        index,
                                                        "item"
                                                      ).originalPrice
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "moreProduct bg-white border-radius fs-12 lh-20 text-center flex-container center",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text-light",
                                attrs: {
                                  href: "/tempPage/maiBiSheng.html",
                                  "data-scp":
                                    "mproduct" +
                                    (_vm.from ? "-" + _vm.from : "") +
                                    ".all",
                                },
                              },
                              [_vm._v("查看更多\n\t\t\t\t\t")]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.theme == 2
      ? _c("div", [
          _c(
            "div",
            { staticClass: "scroll-container flex-container vfull" },
            [
              _vm._l(_vm.maiBiSheng.slice(0, 4), function (item, index) {
                return _c(
                  "a",
                  {
                    style:
                      _vm.maiBiSheng.length > 1
                        ? "    min-width: 320px;margin-right:.2666666667rem;overflow: hidden;"
                        : "overflow: hidden;width: 100%",
                    attrs: {
                      href:
                        _vm.maiBiSheng.length > 1
                          ? "/tempPage/maiBiSheng.html"
                          : _vm.get(item, index, "item").clickUrl,
                      "data-scp":
                        "mproduct" +
                        (_vm.from ? "-" + _vm.from : "") +
                        "." +
                        _vm.get(item, index, "index"),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-container vfull border-radius top bgTheme2",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ratio-container img-container",
                            staticStyle: { width: "120px", height: "120px" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.get(item, index, "item").imageUrl,
                                onerror:
                                  "this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "flex1 paddingh20 flex-container-col",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-fixed2 fs-14 text-darker text-bolder",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.get(item, index, "item").title) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "fs-10 text-red flex-container left",
                                staticStyle: {
                                  "flex-wrap": "wrap",
                                  height: "24px",
                                  overflow: "hidden",
                                  width: "100%",
                                },
                              },
                              _vm._l(
                                _vm.get(item, index, "item").couponList,
                                function (itemCoupon) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "border radius margint10 paddingh10 text-center marginr10",
                                      staticStyle: {
                                        background: "#FFF6F6",
                                        border: "1px solid #FF4C48",
                                        "line-height": "16px",
                                        "border-radius": "2px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(itemCoupon) + "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-container left center margint10",
                                staticStyle: { width: "100%" },
                              },
                              [
                                _vm.get(item, index, "item").newPrice
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-container left center text-red fs-12",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-bolder" },
                                          [_vm._v("新人价")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fs-18 marginl10" },
                                          [
                                            _vm._v(
                                              "¥" +
                                                _vm._s(
                                                  _vm.get(item, index, "item")
                                                    .newPrice
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.get(item, index, "item").groupPrice &&
                                !_vm.get(item, index, "item").newPrice
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "flex-container left center text-red fs-12",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-bolder" },
                                          [_vm._v("拼团价")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fs-18 marginl10" },
                                          [
                                            _vm._v(
                                              "¥" +
                                                _vm._s(
                                                  _vm.get(item, index, "item")
                                                    .groupPrice
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.get(item, index, "item").groupPrice &&
                                !_vm.get(item, index, "item").newPrice
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-red fs-18 text-right",
                                      },
                                      [
                                        _vm._v(
                                          "¥" +
                                            _vm._s(
                                              _vm.get(item, index, "item").price
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "del",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.get(item, index, "item")
                                            .originalPrice !=
                                          _vm.get(item, index, "item").price,
                                        expression:
                                          "get(item, index, 'item').originalPrice != get(item, index, 'item').price",
                                      },
                                    ],
                                    staticClass: "text-light fs-12 marginl10",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t¥" +
                                        _vm._s(
                                          _vm.get(item, index, "item")
                                            .originalPrice
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(1, true),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.maiBiSheng.length > 4
                ? _c(
                    "div",
                    {
                      staticClass:
                        "moreProduct bg-white border border-radius fs-12 lh-20 text-center flex-container center",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "text-light",
                          attrs: {
                            href: "/tempPage/maiBiSheng.html",
                            "data-scp":
                              "mproduct" +
                              (_vm.from ? "-" + _vm.from : "") +
                              ".all",
                          },
                        },
                        [_vm._v("查看更多\n\t\t\t\t")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.theme == 3
      ? _c("div", [
          _c("div", { staticClass: "bg-white pos-r padding20 borderR8" }, [
            _c("div", { staticClass: "flex-container" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "fs-12 lh-1" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-light flex-container right",
                    attrs: {
                      href: "/tempPage/maiBiSheng.html",
                      "data-scp":
                        "mproduct" + (_vm.from ? "-" + _vm.from : "") + ".all",
                    },
                  },
                  [
                    _c("span", [_vm._v("更多")]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "marginl10",
                      attrs: {
                        src: "//image1.51tiangou.com/tgou2/img2/index/ico-right.png",
                        height: "16",
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "scroll-container margint20 flex-container vfull",
              },
              _vm._l(_vm.maiBiSheng.slice(0, 8), function (item, index) {
                return _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/tempPage/maiBiSheng.html?index=" +
                        _vm.get(item, index, "index"),
                      "data-scp":
                        "mproduct" +
                        (_vm.from ? "-" + _vm.from : "") +
                        "." +
                        _vm.get(item, index, "index"),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "border-radius bg-white",
                        staticStyle: {
                          "margin-right": "5px",
                          "border-radius": "2px",
                          width: "104px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ratio-container img-container pos-r",
                            staticStyle: { width: "104px", height: "104px" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.get(item, index, "item").imageUrl,
                                onerror:
                                  "this.src='//image1.51tiangou.com/tgou2/img2/dum/dum_dog.png'",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center pos-a bottom10 text-red flex-container center",
                                staticStyle: {
                                  top: "auto",
                                  "font-size": "9px",
                                  "line-height": "14px",
                                },
                              },
                              [
                                _vm.get(item, index, "item").newPrice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "paddingh20 text-limit1",
                                        staticStyle: {
                                          background: "rgba(255,255,255,0.80)",
                                          border: "1px solid #FF4C48",
                                          "border-radius": "20px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t新人价 ¥" +
                                            _vm._s(
                                              _vm.get(item, index, "item")
                                                .newPrice
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.get(item, index, "item").groupPrice &&
                                !_vm.get(item, index, "item").newPrice
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "paddingh20 text-limit1",
                                        staticStyle: {
                                          background: "rgba(255,255,255,0.80)",
                                          border: "1px solid #FF4C48",
                                          "border-radius": "20px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t拼团价 ¥" +
                                            _vm._s(
                                              _vm.get(item, index, "item")
                                                .groupPrice
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "paddingh10 margint10 text-black fs-12 text-limit1",
                          staticStyle: { "white-space": "normal" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.get(item, index, "item").title
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "paddingh10" }, [
                          _c("div", { staticClass: "flex-container center" }, [
                            _c(
                              "span",
                              { staticClass: "text-red fs-14 text-right" },
                              [
                                _vm._v(
                                  "¥" +
                                    _vm._s(_vm.get(item, index, "item").price)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "del",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.get(item, index, "item")
                                        .originalPrice !==
                                      _vm.get(item, index, "item").price,
                                    expression:
                                      "get(item, index, 'item').originalPrice !== get(item, index, 'item').price",
                                  },
                                ],
                                staticClass: "text-light fs-10 marginl10 lh-16",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t¥" +
                                    _vm._s(
                                      _vm.get(item, index, "item").originalPrice
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "lineNum pos-r" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-white fs-10 lh-1",
                                style:
                                  "width:" + _vm.percent(item, index) + "%",
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "pos-a left0 bottom0 top0 right0 text-center",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.percent(item, index) + "%"
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "pos-a", staticStyle: { left: "14px", top: "17px" } },
      [
        _c("img", {
          attrs: {
            src: "//image1.51tiangou.com/tgou2/img2/mine/title-maibisheng_2.png",
            alt: "",
            width: "17px",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex-container left center margint10",
        staticStyle: { width: "100%" },
      },
      [
        _c(
          "div",
          {
            staticClass: "badge red pill big float-right",
            staticStyle: { padding: "7px 18px" },
          },
          [
            _c("span", { staticClass: "text-limit1 text-white fs-14" }, [
              _vm._v("马上抢"),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-container left" }, [
      _c("img", {
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/title-miaoSha.png",
          height: "24",
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "marginl10",
        attrs: {
          src: "//image1.51tiangou.com/tgou2/img2/index/img-fengQiang.png",
          height: "16",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }