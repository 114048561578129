var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.clickUrl
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.powderShow,
              expression: "powderShow",
            },
          ],
          staticClass:
            "powderShow pos-f left20 right20 bottom50 bg-white paddingh30 paddingv20",
        },
        [
          _c(
            "a",
            { attrs: { "data-scp": "gz.0" }, on: { click: _vm.changePage } },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pos-a top0 right0 padding10",
              attrs: { "data-scp": "gb.0" },
              on: {
                click: function ($event) {
                  _vm.powderShow = false
                },
              },
            },
            [_c("i", { staticClass: "icon-roundclosefill text-light fs-20" })]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-container" }, [
      _c("div", { staticClass: "flex-container left" }, [
        _c("img", {
          attrs: {
            src: "//image1.51tiangou.com/tgou2/img2/index/img-powder_01.png",
            width: "38",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "fs-14 marginl20" }, [
          _c("div", [_vm._v("成为大商天狗粉丝")]),
          _vm._v(" "),
          _c("div", { staticClass: "fs-11" }, [
            _vm._v("\n                        马上领取新人"),
            _c("span", { staticClass: "text-red" }, [_vm._v("专享礼包")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "badge big red pill pos-r",
          staticStyle: {
            padding: "6.5px 16px",
            "font-size": "11px",
            "margin-right": "40px",
            "margin-bottom": "10px",
          },
        },
        [
          _vm._v("\n                关注\n                "),
          _c("img", {
            staticClass: "pos-a",
            staticStyle: { right: "-20px" },
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/index/img-powder_02.png",
              width: "32",
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }