import Super from "./class";
let serverList={
    get:{

    },
    post:{
        queryOverseaWords:'/search/oversea/queryCompleteWords',
        queryMarketWords:'/search/market/queryCompleteWords',
        queryMallWords:'/search/mall/queryCompleteWords',
        queryIndexWords:'/search/platform/queryCompleteWords',
        searchItemList:'/search/item/list',
        aggregationPlatformFilter: '/search/aggregation/platform/filter'
    },
};
export default new Super(serverList,'searchweb');