var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.storeProductBanner.clickUrl,
          expression: "storeProductBanner.clickUrl",
        },
      ],
      staticClass: "borderv bg-white paddingb20",
    },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.storeProductBanner.clickUrl,
            "data-scp": "supermarket.store",
          },
        },
        [
          _c("div", { staticClass: "flex-container margint20 padding20" }, [
            _c("div", { staticClass: "flex-container left" }, [
              _c("img", {
                staticClass: "border",
                attrs: {
                  src:
                    _vm.storeProductBanner.imageUrl ||
                    "//img1.tg-img.com/tgou2/img2/index/shop_default.png",
                  onerror:
                    "this.src='//img1.tg-img.com/tgou2/img2/index/shop_default.png'",
                  width: "50",
                  height: "50",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "marginl20" }, [
                _c("p", {
                  staticClass: "fs-15 text-darker",
                  domProps: {
                    textContent: _vm._s(_vm.storeProductBanner.title),
                  },
                }),
                _vm._v(" "),
                _vm.storeProductBanner.subTitle
                  ? _c("p", { staticClass: "fs-11 text-dark margint10" }, [
                      _c("span", { staticClass: "icon-locate text-dark" }),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.storeProductBanner.subTitle),
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "scroll-container bg-white paddingr20" },
        _vm._l(_vm.storeProductBanner.items, function (item, index) {
          return _c(
            "div",
            {
              staticClass: "border marginl20",
              staticStyle: { width: "100px" },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: item.clickUrl,
                    "data-scp": "supermarket.product" + index,
                  },
                },
                [
                  _c("div", { staticClass: "ratio-container" }, [
                    _c("img", {
                      attrs: {
                        src:
                          item.imageUrl ||
                          "//image1.51tiangou.com/tgou2/img/bg-load.png!s",
                        alt: "",
                        onerror:
                          "this.src='//image1.51tiangou.com/tgou2/img/bg-load.png!s'",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "padding10" }, [
                    _c("div", {
                      staticClass: "text-limit1 fs-10 text-darker",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-red flex-container margint10" },
                      [
                        _c("div", {
                          staticClass: "fs-13",
                          domProps: {
                            textContent: _vm._s("¥" + item.priceString),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.itemEvent(
                                  item,
                                  "supermarket.addToCart" + index
                                )
                              },
                            },
                          },
                          [_c("i", { staticClass: "icon-roundadd fs-16" })]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "span",
        {
          staticClass: "badge badge-reverse badge-pill badge-red",
          staticStyle: { "font-size": "10px", padding: "4px 7px" },
        },
        [_vm._v("进入门店")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }