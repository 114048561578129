import Super from './class.js';
let serverList={
	get:{
		cityList:'/publics/city/list',
		cityLocate:'/publics/city/locate',
		queryStoreInfoById:'/publics/store/queryStoreInfoById',
		hotStore:'/publics/city/hot/store',
		brandQueryAll:'/publics/brand/queryAll'
		, tenderList: '/publics/tender/listReport'  // 大商招标列表
		, tenderDetail: '/publics/tender/detail',    // 大商招标详情
		getNearestStore: '/publics/store/shopping/show',
		// 获取客服电话
		getKefuTel: '/publics/phone/common'
	},
	post:{
		judgeStoreByCity:'/publics/store/judgeStoreByCity',
        getAddressForArrive:'/publics/store/arriveHome',
		isHaveStore:'/publics/store/isHaveHomepage',
		queryVersionDate: '/publics/merchantInfo/api/versionDate/query'
	},
};
export default new Super(serverList,'base');