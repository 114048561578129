var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.property.items.length,
              expression: "property.items.length",
            },
          ],
          staticClass: "flex-container bg-white padding20 marginb20",
          attrs: { "data-scp": "parameter.0" },
          on: {
            click: function ($event) {
              _vm.propertyState = !_vm.propertyState
            },
          },
        },
        [
          _c("div", { staticClass: "text-dark" }, [_vm._v("产品参数")]),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c(
        "cmui-popup",
        {
          attrs: {
            visible: _vm.propertyState,
            position: "bottom",
            "stop-page-scroll": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.propertyState = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "top" }, slot: "top" }, [
            _c(
              "div",
              { staticClass: "text-dark text-center fs-15 padding20 pos-r" },
              [_vm._v("产品参数")]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "icon-wrong pos-a right20 top30 fs-12 text-light",
              on: {
                click: function ($event) {
                  _vm.propertyState = !_vm.propertyState
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "margint-n10",
              staticStyle: { overflow: "hidden", "margin-bottom": "-1px" },
            },
            _vm._l(_vm.property.items, function (item) {
              return _c(
                "div",
                {
                  staticClass: "paddingt40 paddingh30 bg-white",
                  staticStyle: { "margin-bottom": "-1px" },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.property.items.length > 1,
                        expression: "property.items.length>1",
                      },
                    ],
                    staticClass: "borderb paddingv30",
                    domProps: { textContent: _vm._s(item.title) },
                  }),
                  _vm._v(" "),
                  _vm._l(item.property, function (property) {
                    return _c(
                      "div",
                      { staticClass: "flex-container borderb paddingv30" },
                      [
                        _c("span", {
                          staticClass: "fs-13 text-dark lh-20",
                          staticStyle: { "min-width": "20%" },
                          domProps: {
                            textContent: _vm._s(property.propertyName + ":"),
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "flex1 fs-12 lh-20",
                          domProps: {
                            textContent: _vm._s(property.propertyValue),
                          },
                        }),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "bottom" }, slot: "bottom" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex-container center bg-red text-white fs-15 bordert lh-24 padding20 margint40",
                on: {
                  click: function ($event) {
                    _vm.propertyState = !_vm.propertyState
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dis-i marginl10" }, [
      _c("span", { staticClass: "icon-right fs-11 text-light" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }