import Super from './class.js';
let serverList={
    get:{

    },
    post:{
        likeCancel:'/front/liked/canceled',
        like:'/front/liked/selected'
        , resDiscovers: '/front/discover/new/recDiscovers'
        , cityDiscovers: '/front/discover/new/cityDiscovers'
        , concernedDiscovers: '/front/discover/new/concernedDiscovers'
        , tagById: '/front/label/getById'
        , tagDiscovers: '/front/label/discovers'
    },
};
export default new Super(serverList,'discover');