<template>
	<div>
		<div v-if="data.length===1">
<!--
			<a :href="data[0].clickUrl" :data-scp="`slider.0`" :data-bk="data[0].bk">
				<tg-img :src="data[0].imageUrl"></tg-img>
			</a>
-->
			<tg-wx-launch-poster :data="data[0]" :scp="`${scpC}.0`" :bk="data[0].bk" data-trace-id=""></tg-wx-launch-poster>
		</div>
		<cmui-slider
				v-else
				:watch="data" class="tg-slider"
				:page="true"
				:loop="loop"
				:options="options"
				@slide-change-transition-end="slideChangeTransitionEnd">
			<cmui-slider-item v-for="(item,index) in data" :key="index">
				<a v-if="!item.videoUrl"
                   :class="`js-slider${data[0].id}`"
				   :href="item.clickUrl"
				   :data-scp="`${scpC}.${index}`"
				   :data-bk="item.bk"
                   :data-real-index="index">
					<cmui-img :src="item.imageUrl" :pre-view-index="index" :pre-view-list="_selfList" :pre-view="preview" alt="" class="radiusShadow"> </cmui-img>
					<div class="pos-a img-container"
						 style="width: auto;height: auto;z-index: 2;left: 0;right: 0;top: 0;bottom: 0;"
						 v-if="actTagImage && ((!data[0].videoUrl && index === 0) || (data[0].videoUrl && index===1))">
						<img v-if="actTagImage" :src="actTagImage" alt="">
					</div>
				</a>
				<div class="ratio-container pos-r" v-else style="background: #000;" @click.stop="emptyFunc">
					<video id="video" :src="item.videoUrl" :poster="data[1].imageUrl" style="height: 100%;width: 100%;margin: auto;"
						   @click="playVideo($event, index)"
						   @play="playingVideo"
						   @pause="pauseVideo"
						   @ended="endVideo"
						   @error="errorVideo">
					</video>
					<i v-show="!isPlayingVideo" class="icon icon-discover_play text-center"
					   style="width: 80px;height: 80px;margin: auto;background: #fff; opacity: .5;border-radius: 50%;font-size: 30px;padding-left: 10px;line-height: 80px;" @click="playVideo($event, index)"></i>
					<span v-if="errorVideoLoad" style="text-align: center;height: 2em;color: #fff;bottom: 50px;top: auto;">视频加载失败，请检查网络后重试</span>
				</div>
			</cmui-slider-item>
		</cmui-slider>
	</div>

</template>
<script type="text/javascript">
import tgWxLaunchPoster from '../wxLaunch/poster.vue';
import tgs from "javascript/libs/tg/analyse";
export default{
	data:function(){
		let options = {
				autoplay: {
					delay: 3000
					, disableOnInteraction: false
				}
			}
			;

		if( !this.autoplay ){
			options.autoplay = false;
		}

		return {
			options

			, isPlayingVideo: false
			, errorVideoLoad: false,
            traceSwiperList: []
		}
	},
	props:{
		data:Array,
		loop:{type:Boolean,default:true},
		preview:{
		    type:Boolean,
			default:false
		}
		, autoplay: {
			type: Boolean
			, default: true
		}
		, actTagImage: {
			type: String
			, default: ''
		}
		, scpC: {
			type: String
			, default: 'slider'
		}
	},
	computed:{
	    _selfList(){
	        return this.data.map(item => item.imageUrl)
		}
	},
    mounted() {
        if(!this.data || !this.data.length) {
            return;
        }
        // 单独处理曝光
        let pageObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // 进入视口
                    const dom = entry.target;
                    const scp = dom.getAttribute('data-scp');
                    const bk = dom.getAttribute('data-bk');
                    const index = Number(dom.getAttribute('data-real-index'));
                    let traceId = `${scp}${bk}`;
                    if (dom.parentNode.classList.contains('swiper-slide-duplicate')) {
                        traceId += 'copy';
                    }
                    let sTime = null;
                    const observeData = { traceId, dom, index, sTime };
                    let flag = this.traceSwiperList.some(item => {
                        return item.traceId === traceId;
                    })
                    if (!flag) this.traceSwiperList.push(observeData);
                } else {
                    // 离开视口
                    const dom = entry.target;
                    const scp = dom.getAttribute('data-scp');
                    const bk = dom.getAttribute('data-bk');
                    let traceId = `${scp}${bk}`;
                    if (dom.parentNode.classList.contains('swiper-slide-duplicate')) {
                        traceId += 'copy';
                    }
                    this.traceSwiperList = this.traceSwiperList.filter(item =>{return item.traceId !== traceId})
                }
            });
        });
        setTimeout(() => {
            for (let item of document.getElementsByClassName(`js-slider${this.data[0].id}`)) {
                pageObserver.observe(item);
            }
            // 第一次曝光
            setTimeout(() => {
                if (this.data && this.data.length > 1) {
                    this.slideChangeTransitionEnd()
                }
            }, 10)
        }, 10);
    }
    , methods: {
		slideChangeTransitionEnd(swiper, firIndex = 0){
            // 曝光
            const realIndex = swiper ? swiper.realIndex : firIndex;
            let index = this.traceSwiperList.findIndex(item => {
                return item.index === realIndex;
            })
            if (index > -1) {
                this.traceSwiperList[index].sTime = new Date().getTime();
                setTimeout(() => {
                    if (this.traceSwiperList && this.traceSwiperList.length) {
                        let newIndex = this.traceSwiperList.findIndex(item => {
                            return item.index === realIndex;
                        })
                        const eTime = new Date().getTime();
                        if (eTime - this.traceSwiperList[newIndex].sTime > 1500) {
                            let scp = this.traceSwiperList[newIndex].dom.getAttribute('data-scp');
                            scp = (tg.page || '*.*') + '.' + scp + '.' + Math.random().toString(36).slice(2, 7);
                            const bk = this.traceSwiperList[newIndex].dom.getAttribute('data-bk');
                            const storeId = this.traceSwiperList[newIndex].dom.getAttribute('data-store-id');
                            tgs.baoguang(scp, bk, undefined, storeId);
                        }
                    }
                }, 1500)
            }
            swiper && this.$emit('slider-change', swiper.activeIndex);
		}

		, emptyFunc(){}
		, playVideo(e, i){
			if( this.errorVideoLoad ){
				return;
			}

			_tgs.trackSCP(tg.page + `.play.${i}`);

			let video = e.target.tagName === 'VIDEO' ? e.target : $('video')[0]
				;

			if( video.paused ){
				video.play();
			}
			else{
				video.pause();
			}
		}
		, playingVideo(){
			this.isPlayingVideo = true;
		}
		, pauseVideo(){
			this.isPlayingVideo = false;
		}
		, endVideo(){
			this.isPlayingVideo = false;
		}
		, errorVideo(){
			this.errorVideoLoad = true;
		}
	}
	, components: {
		tgWxLaunchPoster
	}
}
</script>