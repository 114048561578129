<template>
  <div class="tg-step">
    <div class='horizontal'>
      <div class="item-wrapper">
        <div class="item" :class="[{current:item.active}]" v-for='(item, index) of dataSource' :key="index" @click="switchTap(index)">
          <div class="line" v-if="index !=dataSource.length -1"></div>
          <div :class="[{active:item.active}]" v-if="type=='default'">{{index + 1}}</div>
          <div :class="[{active:item.active},{'icon-check':item.active}]" v-else></div>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tg-step',
  props: {
    dataSource: {
      type: Array
    },
    type: {
      type: String,
      default: () => {
        return 'default';
      }
    }
  },
  data () {
    return {
    };
  },
  computed: {
  },
  methods: {
    // 点击事件
    switchTap (index) {
      this.$emit('handle', index);
    }
  },
  mounted () {
  }
};
</script>

<style lang="scss" scoped>
.tg-step {
  margin-top: -30px;
  height: 100px;
  overflow: hidden;
  .horizontal{
    width: 100%;
    overflow: hidden;
      height: 100px;

    .item-wrapper {
      display: flex;
      height: 100px;

      .item{
        text-align: center;
        align-items: center;
        flex: 1;
        position: relative;
        color: #333;
        display: flex;
        justify-content: space-around;
        &.current {
          .line {
            background-color: #FF2238;
            border: none;
        }
      }
        p {
          font-size: 14px;
          position: absolute;
          bottom: 24px;
          font-weight: bold;

        }
        &.current p{
          color: #000;
          font-weight: bold;
        }
        .line{
          height: 1px;
          background: #ccc;
          width: 100%;
          position: absolute;
          border: none;
          top: 36px;
          left: 52%;
          z-index: 1;
        }
        div {
          background: #fff;
          border: 1px solid #ccc;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: inline-block;
          z-index: 2;
          position: absolute;
          top: 25px;
          font-size: 14px;
          color: #a9a9a9;
          line-height: 18px;
          font-weight: bold;
          &.active {
            border: 1px solid #FF2238;
            background-color: #FF2238;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
