var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { attrs: { href: "/discover/myDiscovery.html", "data-scp": "avatar.0" } },
    [
      _c("i", { staticClass: "icon", staticStyle: { "margin-right": "0" } }, [
        _c("img", {
          staticStyle: { "vertical-align": "baseline", "border-radius": "50%" },
          attrs: { src: _vm.avatar, alt: "", width: "22", height: "22" },
        }),
      ]),
      _vm._v(" "),
      _c("span"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }