var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.evaluateBanner && _vm.evaluateBanner.num !== 0,
            expression: "evaluateBanner && evaluateBanner.num!==0",
          },
        ],
        attrs: { "data-scp": "evaluate.00", href: _vm.evaluateBanner.clickUrl },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex-container bg-white margint20 padding20 text-dark",
            staticStyle: { height: "45px" },
          },
          [
            _c("div", {
              domProps: {
                textContent: _vm._s("评价（" + _vm.evaluateBanner.num + "）"),
              },
            }),
            _vm._v(" "),
            _c("div", [
              _c("span", {
                staticClass: "text-red",
                domProps: {
                  textContent: _vm._s("平均分：" + _vm.evaluateBanner.average),
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.evaluateBanner && _vm.evaluateBanner.num === 0,
            expression: "evaluateBanner && evaluateBanner.num===0",
          },
        ],
        staticClass: "flex-container bg-white margint20 padding20 text-dark",
        staticStyle: { height: "45px" },
      },
      [_c("div", [_vm._v("暂无评价")])]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "lh-20 dis-i" }, [
      _c("span", { staticClass: "icon-right fs-11 text-light" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }