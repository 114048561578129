<template>
    <a href="javascript:void(0)" data-scp="back.0" @click="goback">
        <i class="icon icon-back"></i>
        <span>返回</span>
    </a>
</template>

<script>
    export default {
        name: "back",
        methods:{
            goback(){
                let customBackEvent=this.$parent.$listeners.customback;
                if(_.isFunction(customBackEvent)){
                    customBackEvent.apply(this,arguments)
                }else if(history.length===1){
                    tg.changePage('/index.html')
                }else{
                    this.$router.back();
                }
            }
        }
    }
</script>

<style scoped>

</style>