var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.introList.items.length,
              expression: "introList.items.length",
            },
          ],
          staticClass: "marginv20 bg-white",
          attrs: { "data-scp": "clause.0" },
          on: {
            click: function ($event) {
              _vm.introState = !_vm.introState
            },
          },
        },
        [
          _c("div", { staticClass: "flex-container padding20 pos-r" }, [
            _c("div", {
              staticClass: "fs-11 text-dark",
              domProps: { innerHTML: _vm._s(_vm.introText) },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "cmui-popup",
        {
          attrs: {
            visible: _vm.introState,
            position: "bottom",
            "stop-page-scroll": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.introState = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "top" }, slot: "top" }, [
            _c(
              "div",
              { staticClass: "text-dark text-center fs-15 padding20 pos-r" },
              [_vm._v("天狗权益")]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "icon-wrong pos-a right20 top30 fs-12 text-light",
              on: {
                click: function ($event) {
                  _vm.introState = !_vm.introState
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "paddingh30",
              staticStyle: { "margin-bottom": "-1px" },
            },
            _vm._l(_vm.introList.items, function (item) {
              return _c(
                "div",
                { staticClass: "flex-container leftTop borderb paddingv30" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-red fs-16",
                      class: item.imageUrl ? "" : "icon-product_roundcheck",
                    },
                    [
                      item.imageUrl
                        ? _c("img", {
                            staticClass: "lh-16",
                            staticStyle: { "min-width": "16px" },
                            attrs: {
                              src: item.imageUrl,
                              width: "16",
                              height: "16",
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "marginl20 fs-13 text-darker" }, [
                    _c("div", {
                      staticClass: "fs-14 text-darker",
                      domProps: { textContent: _vm._s(item.title) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "fs-12 text-dark",
                      domProps: { textContent: _vm._s(item.subTitle) },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "bottom" }, slot: "bottom" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex-container center bg-red text-white fs-15 bordert lh-24 padding20 margint40",
                on: {
                  click: function ($event) {
                    _vm.introState = !_vm.introState
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "lh-24 dis-i marginl10" }, [
      _c("span", { staticClass: "icon-right fs-11 text-light" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }