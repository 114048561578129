<template>
    <div  class="tgPoster-type86">
        <a :href="data.items[0].clickUrl" :data-scp="`ad_${data.id}_${data.items[0].id}.0`">
            <img :src="data.items[0].imageUrl" alt="">
        </a>
        <div class="padding20 scroll-container">
            <a :href="item.clickUrl" v-for="(item,i) in data.items.slice(1)" class="marginr20" :data-scp="`ad_${data.id}_${item.id}.${i+1}`">
                <img :src="item.imageUrl" width="100" alt="">
            </a>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js';
    export default {
        name: "type86",
        mixins:[mixin],
    }
</script>

<style>

</style>