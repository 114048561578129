var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tg-loading" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: (_vm.state || _vm.$root.loadMoreState) === "loading",
          expression: "(state||$root.loadMoreState)==='loading'",
        },
      ],
      staticClass: "loading",
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: (_vm.state || _vm.$root.loadMoreState) === "end",
            expression: "(state||$root.loadMoreState)==='end'",
          },
        ],
        staticClass: "padding20 text-center end",
      },
      [
        _vm._t("default", function () {
          return [_vm._v("哎呦~一不小心就到底啦！")]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }