'use strict';

import itemService  from 'spa/lib/api/item.js';

export default {
	methods: {
		addItemToCart(item, scp){
			itemService.addShoppingCart({
				data: {
					itemId: item.id
					, quantity: 1
				}
			}).then(()=>{
				maple.notice('加入购物车成功');
			});

			_tgs.trackSCP(tg.page +'.'+ scp );
		}
	}
}