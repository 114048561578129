var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/mine/message/index.html", "data-scp": "noti.0" } },
      [
        _c("i", { staticClass: "icon icon-advice" }),
        _c("span", [_vm._v("消息")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }