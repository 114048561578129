<template>
    <a href="/mine/message/index.html" data-scp="noti.0"> <i class="icon icon-advice"></i><span>消息</span> </a>
</template>

<script>
    export default {
        name: "advice"
    }
</script>

<style scoped>

</style>