var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tgPoster-type86" }, [
    _c(
      "a",
      {
        attrs: {
          href: _vm.data.items[0].clickUrl,
          "data-scp": "ad_" + _vm.data.id + "_" + _vm.data.items[0].id + ".0",
        },
      },
      [_c("img", { attrs: { src: _vm.data.items[0].imageUrl, alt: "" } })]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "padding20 scroll-container" },
      _vm._l(_vm.data.items.slice(1), function (item, i) {
        return _c(
          "a",
          {
            staticClass: "marginr20",
            attrs: {
              href: item.clickUrl,
              "data-scp": "ad_" + _vm.data.id + "_" + item.id + "." + (i + 1),
            },
          },
          [_c("img", { attrs: { src: item.imageUrl, width: "100", alt: "" } })]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }