/**
 * Created by chenqifeng on 2016/9/1.
 */


import app from "./js_interface_for_app";
function getCookie(key){
	return maple.cookie(key)
}
let App = window.App = window.App || function(){
	this.target = null;

	let startTime = Date.now()
		, that = this
		;

	let tempFunc = function(){
		let target = null
			, currentTime = Date.now()
			;

		try{
			target = window.webkit.messageHandlers.app;
		}
		catch(e){}

		if( typeof target === 'function' || typeof target === 'object' ){
			that.target = target;

			that.getTgAppVersion();
		}
		else if( currentTime - startTime > 2000 ){  //
		}
		else{
			setTimeout(tempFunc, 50);
		}
	};
	tempFunc();

	// try{    // 新版 iOS APP WebView 的交互方式
	// 	if( getCookie('global') === 'ios' ){
	// 		this.target = window.webkit.messageHandlers.app;
	// 	}
	// }
	// catch( e ){
	// 	// 旧版
	// }
};

App.prototype.nativeCallbackCache = {};
App.prototype.eventList = {};

/**
 * 是否支持iosPay
 * @returns {boolean}
 */
App.prototype.isSupportIosPay = function(){
	try{
		if( this.target ){
			return this.postMessage('isSupportIosPay');
		}
		else{
			return window.app.isSupportIosPay() === 'true';
		}
	}
	catch( e ){
		return false;
	}
};

/**
 * 是否支持appPay(新版统一方法)
 * @returns {boolean}
 */
App.prototype.isSupportPhonePay = function(){
	try{
		if( this.target ){
			return this.postMessage('isSupportPhonePay');
		}
		else{
			return window.app.isSupportPhonePay() === 'true';
		}
	}
	catch( e ){
		return false;
	}
};


/**
 * 调用iosPay
 * @param orderId
 */
App.prototype.iosPay = function(orderId){
	try{
		if( this.target ){
			return this.postMessage('iosPay', [orderId]);
		}
		else{
			return window.app.iosPay(orderId);
		}
	}
	catch( e ){
	}
};

App.prototype.insertCookie = function(obj){
    try{
        if( this.target ){
            this.postMessage('insertCookie', [JSON.stringify(obj)]);
        }
        else{
            window.app.insertCookie(JSON.stringify(obj));
        }
    }
    catch(e){
        return 'false';
    }
};

/**
 * 推送消息，传递最大的id
 * @param maxId
 * @param messageType
 * @returns {*}
 */
App.prototype.getMessageMaxId = function(maxId, messageType){
	try{
		if( this.target ){
			return this.postMessage('getMessageMaxId', [maxId, messageType]);
		}
		else{
			return window.app.getMessageMaxId(maxId, messageType);
		}
	}
	catch( e ){
	}
};

/**
 * 弹出notice，msg消息，5000毫秒持续时间
 */
App.prototype.notice = function(msg, time){
	var that = this
	;

	try{
		setTimeout(function(){
			if( that.target ){
				that.postMessage('showNoticeDialog', [msg, time]);
			}
			else{
				window.app && window.app.showNoticeDialog && window.app.showNoticeDialog(msg, time)
			}
		}, 50)
	}catch( e ){
	}
};
/**
 * 打开遮罩 mask，msg消息
 */
App.prototype.mask = function(msg){
	var that = this
	;

	msg = msg || '加载中...';

	try{
		setTimeout(function(){
			if( that.target ){
				that.postMessage('showMaskDialog', [msg]);
			}
			else{
				window.app && window.app.showMaskDialog(msg)
			}
		}, 50)
	}catch( e ){
	}
};
/**
 * 关闭遮罩 mask
 */
App.prototype.unmask = function(){
	var that = this
	;

	try{
		setTimeout(function(){
			if( that.target ){
				that.postMessage('cancelMaskDialog');
			}
			else{
				window.app && window.app.cancelMaskDialog()
			}
		}, 50)
	}catch( e ){
	}
};
/**
 * 页面滚动到y位置
 */
App.prototype.scrollTo = function(y){
	y = y || 0;

	try{
		if( this.target ){
			this.postMessage('scrollTo', [y]);
		}
		else{
			window.app.scrollTo(y)
		}
	}catch( e ){
	}
};
/**
 * 弹出日期选择窗，callback回调方法
 */
App.prototype.showDateDialog = function(callback){
	callback = callback || function(){
	};
	window.tgOkCallback = function(time){
		callback(time)
	};

	try{

		if( this.target ){
			this.postMessage('showDateDialog', ['tgOkCallback']);
		}
		else{
			window.app.showDateDialog('tgOkCallback')
		}
	}catch( e ){
	}
};
/**
 * 保存一条数据，参数为Json字符串
 */
App.prototype.saveDB = function(key, value, time){
	try{
		if( this.target ){
			this.postMessage('saveDB', [key, value, time]);
		}
		else{
			window.app.saveDB(key, value, time)
		}
	}catch( e ){
	}
};
/**
 * 查询一条数据，id为主键key，返回Json字符串
 */
App.prototype.findDB = function(key){
	try{
		if( this.target ){
			return this.postMessage('findDB', [key]);
		}
		else{
			return window.app.findDB(key);
		}
	}catch( e ){
	}
};
/**
 * 打开二维码扫描
 */
App.prototype.codeScan = function(){
	try{
		if( this.target ){
			this.postMessage('codeScan');
		}
		else{
			window.app.codeScan()
		}
	}catch( e ){
	}
};
/**
 * 获取基础信息
 */
App.prototype.getBaseInfo = function(){
	try{
		if( this.target ){
			this.postMessage('getBaseInfo');
		}
		else{
			return window.app.getBaseInfo()
		}
	}catch( e ){
	}
};
/**
 * 页面是否被遮盖（如筛选），被遮盖isOverLay为true，否则为false
 */
App.prototype.isOverlay = function(flag){
	try{
		if( flag ){
			$(".tg_page").css("position", "fixed");
		}else{
			$(".tg_page").css("position", "");
		}
		if( this.target ){
			this.postMessage('isOverlay', [flag]);
		}
		else{
			window.app.isOverlay(flag)
		}
	}catch( e ){
	}
};
/**
 * 弹出alert，参数为Json字符串
 */
App.prototype.showAlertDialog = function(obj){
	try{
		if( this.target ){
			this.postMessage('showAlertDialog', [obj]);
		}
		else{
			window.app.showAlertDialog(obj);
		}
	}catch( e ){
	}
};
/**
 * 弹出confirm，参数为Json字符串
 */
App.prototype.showConfirmDialog = function(obj){
	try{
		if( this.target ){
			this.postMessage('showConfirmDialog', [obj]);
		}
		else{
			window.app.showConfirmDialog(obj)
		}
	}catch( e ){
	}
};
/**
 * 弹出列表窗，参数为Json字符串
 */
App.prototype.showListDialog = function(obj){
	try{
		if( this.target ){
			this.postMessage('showListDialog', [obj]);
		}
		else{
			window.app.showListDialog(obj)
		}
	}catch( e ){
	}
};
/**
 * 退出系统
 */
App.prototype.exit = function(){
	try{
		if( this.target ){
			this.postMessage('exit');
		}
		else{
			window.app.exit()
		}
	}catch( e ){
	}
};

/**
 * 覆盖App后退事件
 */
App.prototype.tgBackEvent = function(event){
	try{
		//根据版本号，进行不同处理

		if( this.target ){
			if( this.postMessage('getTgAppVersion') < 3 && getCookie('global') === 'android' ){
				event = event.split(':')[1]
			}
			this.postMessage('tgBackEvent', [event]);
		}
		else{


			if( this.getTgAppVersion() < 3 && getCookie('global') === 'android' ){
				event = event.split(':')[1]
			}
			window.app.tgBackEvent(event)
		}
	}catch( e ){
	}
};


/**
 * 页面加载完毕
 */
App.prototype.tgWebOver = function(){
	var flg = true
	;

	try{

		$('script').each(function(){
			var src = $(this).attr('src');
			if( /114\.247\.28\.96/.test(src) ){
				flg = false;
			}
		});
		if( this.target ){
			flg && this.postMessage('tgWebOver');
		}
		else{
			flg && window.app.tgWebOver()
		}
	}catch( e ){
	}
};

/**
 * 设置header标题
 */
App.prototype.setTgTitle = function(title){

	try{
		if( this.target ){
			this.postMessage('setAppHeaderTitle', [JSON.stringify({title})]);
		}
		else{
			if( this.getTgAppVersion() >= 7 ){
				window.app.setAppHeaderTitle(JSON.stringify({
					title: title
				}));
			}
			else{
				window.app.setTgTitle( title );
			}
		}
	}catch( e ){
	}
};

/**
 * 设置header标题
 */
App.prototype.addTgHistory = function(url, isNeedRefresh){
	isNeedRefresh = isNeedRefresh || 'false';


	try{
		if( this.target ){
			this.postMessage('addTgHistory', [url, isNeedRefresh]);
		}
		else{
			window.app.addTgHistory(url, isNeedRefresh);
		}
	}catch( e ){
	}
};
/**
 * 跳转原生页
 */
App.prototype.tgChangePage = function(code, url, event){
	try{
		if( this.target ){
			this.postMessage('tgChangePage', [code, url, event]);
		}
		else{
			window.app.tgChangePage(code, url, event);
		}
	}catch( e ){
	}
};

/**
 * 首页弹窗加载完毕
 */
App.prototype.tgShowWindow = function(){

	try{
		if( this.target ){
			this.postMessage('tgWebOver');
		}
		else{
			window.app.tgWebOver();
		}
	}catch( e ){
	}
};

/**
 * 通知app登陆密码修改
 */
App.prototype.tgUpdatePassword = function(password){

	if( !password ){
		return false;
	}


	try{
		if( this.target ){
			this.postMessage('tgUpdatePassword', [password]);
		}
		else{
			window.app.tgUpdatePassword(password);
		}
	}catch( e ){
	}
};

/**
 * 通知app后退
 */
App.prototype.tgPageBack = function(){

	try{
		if( this.target ){
			this.postMessage('tgPageBack');
		}
		else{
			window.app.tgPageBack();
		}
	}catch( e ){
	}
};
/**
 * 通知app退出登录
 */
App.prototype.tgLogout = function(){

	try{
		if( this.target ){
			this.postMessage('tgLogout');
		}
		else{
			window.app.tgLogout();
		}
	}catch( e ){
	}
};
/**
 * 通知app已经取消订阅
 */
App.prototype.cancelStore = function(state, storeId){

	try{
		if( this.target ){
			this.postMessage('cancelStore', [state, storeId]);
		}
		else{
			window.app.cancelStore(state, storeId);
		}
	}catch( e ){
	}
};
/**
 * 隐藏app的menus
 */
App.prototype.hideAppMenus = function(){

	try{
		if( this.target ){
			this.postMessage('hideAppMenus');
		}
		else{
			window.app.hideAppMenus();
		}
	}catch( e ){
	}
};
/**
 * 显示app的menus
 */
App.prototype.showAppMenus = function(){

	try{
		if( this.target ){
			this.postMessage('showAppMenus');
		}
		else{
			window.app.showAppMenus()
		}
	}catch( e ){
	}
};
App.prototype.getTgAppVersion = function(){
	try{
		if( this.target ){
			return Number(this.postMessage('getTgAppVersion').toString().match(/\d+/)[0]);
		}else{
			return Number(window.app.getTgAppVersion().toString().match(/\d+/)[0]);
		}
	}catch( e ){
		return Number(getCookie('hybridVersion')) || 0;
	}
};

App.prototype.setUserHeaderImg = function(){
	try{
		if( this.target ){
			this.postMessage('setUserHeaderImg');
		}
		else{
			window.app.setUserHeaderImg()
		}
	}catch( e ){
		return false;
	}
};

App.prototype.customShare = function(){
	try{
		if( this.target ){
			this.postMessage('customShare', [JSON.stringify(arguments[0])]);
		}
		else{
			if( this.getTgAppVersion() < 3 ){
				window.app.customShare(arguments[0], arguments[1], arguments[2]);
			}else{
				window.app.customShare(JSON.stringify(arguments[0]));
			}
		}
	}catch( e ){
	}
};

App.prototype.showWifi = function(msg){

	msg = msg || '抱歉，没有检测到免费Wi-Fi热点';
	try{
		if( this.target ){
			this.postMessage('showWifi', [msg]);
		}
		else{
			window.app.showWifi(msg)
		}
	}catch( e ){
	}
};

App.prototype.updateUsername = function(name){
	try{
		if( this.target ){
			this.postMessage('updateUsername', [name]);
		}
		else{

			window.app.updateUsername(name)
		}
	}catch( e ){
	}
};

/**
 * 设置当前门店
 * @param name
 */
App.prototype.setCurStoreName = function(name){
	var that = this;
	try{
		if( this.target ){
			this.postMessage('setAppHeaderTitle', [JSON.stringify({
				title: name
				, theme: 1
			})]);
		}
		else{
			if( this.getTgAppVersion() >= 9 ){
				window.app.setAppHeaderTitle(JSON.stringify({title: name, theme: 1}));
			}else{
				window.app.setCurStoreName(name)
			}
		}
	}catch( e ){
		// todo ?
		setTimeout(function(){
			that.setCurStoreName(name)
		}, 50)
	}
};

/**
 * 调用IM
 */
App.prototype.startChatWith = function(info){

	try{
		if( this.target ){
			this.postMessage('startChatWith', [JSON.stringify(info)]);
		}
		else{
			if( this.getTgAppVersion() >= 5 ){
				window.app.startChatWith(JSON.stringify(info));
			}
		}

	}catch( e ){
	}
};
/**
 * 调用 IM 联系客服
 * 用来部分替代 startChatWith，期望将用户之间聊天和用户联系客服分开
 * 参数在之前 startChatWith 的基础上添加 counterUrl,productInfo,orderInfo，分别对应专柜页面 url，商品信息，订单信息
 * startChatWithService 内部判断当 target 不存在 startChatWithService 方法时会降级调用 startChatWith
 * */
App.prototype.startChatWithService = function(info){
	try{
		if( this.target ){
			this.postMessage('startChatWithService', [JSON.stringify(info)]);
		}
		else{
			if( this.getTgAppVersion() >= 5 ){
				if( 'startChatWithService' in window.app ){
					window.app.startChatWithService(JSON.stringify(info));
				}
				else{
					window.app.startChatWith(JSON.stringify(info));
				}
			}
		}
	}
	catch( e ){
	}
};

/**
 * 百货首页箭头
 * @param direction
 */
App.prototype.setHeaderArrowDirection = function(direction){
	try{
		if( this.target ){
			this.postMessage('setHeaderArrowDirection', [direction]);
		}
		else{
			window.app.setHeaderArrowDirection(direction)
		}
	}catch( e ){
	}
};

/**
 * 我的关注
 * @param text
 */
App.prototype.setAppStateText = function(text){
	try{
		if( this.target ){
			this.postMessage('setAppStateText', [text]);
		}
		else{
			window.app.setAppStateText(text)
		}
	}catch( e ){
	}
};

/**
 * 空导中，
 */
App.prototype.setShareEnable = function(){
	try{
		if( this.target ){
			this.postMessage('setShareEnable');
		}
		else{
			window.app.setShareEnable();

		}
	}catch( e ){
	}
};

App.prototype.toggleDropList = function(){
};

App.prototype.setAppArrowDirection = function(direct){

	try{
		if( this.target ){
			this.postMessage('setAppArrowDirection', [direct]);
		}
		else{
			window.app.setAppArrowDirection(direct);

		}
	}catch( e ){
	}
};

App.prototype.setAppHeaderTitle = function(params){
	try{
		if( this.target ){
			this.postMessage('setAppHeaderTitle', [JSON.stringify(params)]);
		}
		else{
			if( this.getTgAppVersion() >= 7 ){
				window.app.setAppHeaderTitle(JSON.stringify(params));
			}else{
				window.app.setTgTitle(params.title)
			}
		}


	}catch( e ){
	}
};

App.prototype.selectTopic = function(name){

	try{
		if( this.target ){
			this.postMessage('selectTopic', [name]);
		}
		else{

			window.app.selectTopic(name);
		}
	}catch( e ){

	}
};

/**
 * 支付宝
 * @param name
 */
App.prototype.alipay = function(name){
	try{
		if( this.target ){
			this.postMessage('alipay', [name]);
		}
		else{
			window.app.alipay(name);

		}
	}catch( e ){

	}
};

App.prototype.pay = function(orderId, storeId, payMethod){
	try{
		if( this.target ){
			this.postMessage('pay', [orderId, storeId, payMethod]);
		}
		else{
			window.app.pay(orderId, storeId, payMethod);
		}
	}
	catch(e){}
};

App.prototype.allPay = function(method, data){
	try{
		if( this.target ){
			this.postMessage('allPay', [method, data]);
		}
		else{
			window.app.allPay(method, data);
		}
	}
	catch(e){}
};

App.prototype.contactCenter = function(param){
	try{
		if( this.target ){
			this.postMessage('contactCenter', [param]);
		}
		else{
			window.app.contactCenter(param);
		}
	}
	catch( e ){

	}
};

App.prototype.setShakeGuestureJSCallback = function(param){
	try{
		if( this.target ){
			this.postMessage('setShakeGuestureJSCallback', [param]);
		}
		else{
			window.app.setShakeGuestureJSCallback(param);
		}
	}
	catch( e ){

	}
};
/**
 * 发布发现
 * @param   {Object}    params  占位用
 * */
App.prototype.publishDiscover = function(params){

	// params = params || {};

	try{
		if( this.target ){
			if( params ){
				this.postMessage('publishDiscover', [JSON.stringify(params)]);
			}
			else{
				this.postMessage('publishDiscover');
			}
		}
		else{
			window.app.publishDiscover(JSON.stringify(params));
		}
	}
	catch( e ){
	}
};
/**
 * 播放视频
 * @param   {Object}    params
 * @param   {String}    params.imgUrl
 * @param   {String}    params.videoUrl
 * */
App.prototype.playVideo = function(params){
	params = params || {};

	try{
		if( this.target ){
			this.postMessage('playVideo', [JSON.stringify(params)]);
		}
		else{
			window.app.playVideo(JSON.stringify(params));
		}
	}
	catch( e ){
	}
};

/**
 * 更换封面
 * @param   {Object}    params
 * @param   params.memberId
 * */
App.prototype.changeCover = function(params){
	params = params || {};
	try{
		if( this.target ){
			this.postMessage('changeCover', [JSON.stringify(params)]);
		}
		else{
			window.app.changeCover(JSON.stringify(params));
		}
	}
	catch( e ){
	}
};
/**
 * 立刻分享撒娇礼
 * @param   {Object}    params  和分享接口参数相同
 * */
App.prototype.shareCrowd = function(params){
	params = params || {};

	try{
		if( this.target ){
			this.postMessage('shareCrowd', [JSON.stringify(params)]);
		}
		else{
			window.app.shareCrowd(JSON.stringify(params));
		}
	}
	catch( e ){
	}
};
/**
 * 专辑详情分享
 * @param   {Object}    params
 * */
App.prototype.share = function(params){
	params = params || {};

	params.imgUrl = params.imageUrl;

	try{
		if( this.target ){
			this.postMessage('share', [JSON.stringify(params)]);
		}
		else{
			//版本判断
			if( this.getTgAppVersion() < 3 ){
				//版本<3
				window.app.share(params.friendTitle, params.subTitle, params.imageUrl, params.url); //分享
			}else{
				//版本≥3
				window.app.share(JSON.stringify(params));
			}
		}
	}
	catch( e ){
	}
};
/**
 * 新人礼弹窗
 * */
App.prototype.popupHttpPoster = function(params){
	try{
		if( this.target ){
			this.postMessage('popupHttpPoster', [JSON.stringify(params)]);
		}
		else{
			window.app.popupHttpPoster(JSON.stringify(params));
		}
	}
	catch( e ){
	}
};

/**
 * 新版 iOS 调用 APP 方法
 * @param   {String}    name    方法名称
 * @param   {Array}    params  传递参数
 * */
App.prototype.postMessage = function(name, params = []){
	if( this.target ){
		try{
			if( name in this.nativeCallbackCache ){
				return this.nativeCallbackCache[name];
			}
			else{
				return this.target.postMessage(JSON.stringify({
					methodName: name
					, params: params
				}));
			}
		}
		catch( e ){
		}
	}
};
/**
 * 生成二维码分享信息
 * @param params
 */
App.prototype.makeQrImage = function (params) {
	if(this.getTgAppVersion() > 20171218){
		try {
			if(this.target){
                this.postMessage('makeQrImage', [JSON.stringify(params)]);
            }else{
                window.app.makeQrImage(JSON.stringify(params));
			}
		}
		catch (e){

		}
	}
};
/**
 * 通知app从搜索结果页跳回搜索中间页
 */

App.prototype.openSearchMiddlePage = function(params){

	try{
		if( this.target ){
			this.postMessage('openSearchMiddlePage', [JSON.stringify(params)]);
		}
		else{
			window.app.openSearchMiddlePage(JSON.stringify(params));
		}
	}catch( e ){
		console.log(e);
	}
};

/**
 * @summary 通知 app webview打开/取消下拉刷新
 * @param   {Boolean}        disable   是否订阅，true:禁用，2:打开刷新
 * */

App.prototype.disableRefresh = function(params){
    try{
        if( this.target ){
            this.postMessage('disableRefresh', [JSON.stringify(params)]);
        }
        else{
            window.app.disableRefresh(JSON.stringify(params));
        }
    }
    catch( e ){
    }
};

/**
 * @summary 获取APP前置页面
 * */

App.prototype.getLastUrlFromNativePage  = function(params){
    try{
        if( this.target ){
            return this.postMessage('getLastUrlFromNativePage');
        }else{
            return window.app.getLastUrlFromNativePage();
        }
    }catch( e ){

    }
};
/**
 * @summary 通知app刷新商品详情页
 * */

App.prototype.refreshProductListing  = function(params){
    try{
        if( this.target ){
            return this.postMessage('refreshProductListing');
        }else{
            return window.app.refreshProductListing();
        }
    }catch( e ){

    }
};


/**
 * 微信绑定（已经登录天狗的用户绑定微信）
 * @param params
 */
App.prototype.goBindWeixin = function () {
    try {
        if( this.target ){
            this.postMessage('goBindWeixin');
        }
        else{
            if(this.getTgAppVersion() >= 20170830 ){
                window.app.goBindWeixin();
            }
        }
    }catch (e){

    }
};

/**
 * @summary 通知 app 取消订阅结果
 * @param   {Boolean}
 * */

App.prototype.zhuxiaoSuccessed  = function(params){
    try{
        if( this.target ){
            return this.postMessage('zhuxiaoSuccessed');
        }else{
            return window.app.zhuxiaoSuccessed();
        }
    }catch( e ){

    }
};
/**
 * @summary 微信绑定成功的回调
 * @param
 * */

App.prototype.bindWeixinSuccess  = function(params){
    try{
        if( this.target ){
            return this.postMessage('zhuxiaoSuccessed');
        }else{
            return window.app.zhuxiaoSuccessed();
        }
    }catch( e ){

    }
};

/**
 * 跳转微信小程序
 * @param   {Object}    config
 * @param   {string}    config.path
 * @param   {string}    config.userName
 * @param   {number}    config.type
 * */
App.prototype.startWxapp = function(config){
	try{
		if( this.target ){
			return this.postMessage('startWxapp', [JSON.stringify( config )]);
		}
		else{
			return window.app.startWxapp( JSON.stringify(config) );
		}
	}
	catch(e){}
}

/**
 * 跳转支付宝小程序
 * @param   {Object}    config
 * @param   {string}    config.appId
 * @param   {string}    config.page
 * @param   {Object}    config.query
 * */
App.prototype.startAliMiniProgram = function(config){
	try{
		if( this.target ){
			return this.postMessage('startAliMiniProgram', [JSON.stringify( config )]);
		}
		else{
			return window.app.startAliMiniProgram( JSON.stringify(config) );
		}
	}
	catch(e){}
};

/**
 * 客服入口
 * */
App.prototype.showGuideQrCode = function(params){
	try{
		if( this.target ){
			return this.postMessage('showGuideQrCode', [JSON.stringify( params )]);
		}
		else{
			return window.app.showGuideQrCode( JSON.stringify(params) );
		}
	}
	catch(e){}
}

/**
 * 订单列表头部标题箭头
 * */
App.prototype.setAppArrowDirection = function(str){
	try{
		if( this.target ){
			return this.postMessage('setAppArrowDirection', [String(str)]);
		}
		else{
			return window.app.setAppArrowDirection( String(str) );
		}
	}
	catch(e){}
}

/**
 * 确认订单弹窗 - 关闭弹窗
 * */
App.prototype.closePreOrderPop = function(params){
	try{
		if( this.target ){
			return this.postMessage('closePreOrderPop', [JSON.stringify( params )]);
		}
		else{
			return window.app.closePreOrderPop( JSON.stringify(params) );
		}
	}
	catch(e){}
}

/**
 * 确认订单弹窗 - 需要重新加载url
 * */
App.prototype.reloadWithUrl = function(str){
	try{
		if( this.target ){
			return this.postMessage('reloadWithUrl', [String(str)]);
		}
		else{
			return window.app.reloadWithUrl( String(str) );
		}
	}
	catch(e){}
}

/**
 * 确认订单弹窗 - 点击sku
 * */
App.prototype.clickedProductSku = function(params){
	try{
		if( this.target ){
			return this.postMessage('clickedProductSku', [JSON.stringify( params )]);
		}
		else{
			return window.app.clickedProductSku( JSON.stringify(params) );
		}
	}
	catch(e){}
}

/**
 * 确认订单弹窗 - 切换关联商品
 * */
App.prototype.changeRelationProduct = function(params){
	try{
		if( this.target ){
			return this.postMessage('changeRelationProduct', [JSON.stringify( params )]);
		}
		else{
			return window.app.changeRelationProduct( JSON.stringify(params) );
		}
	}
	catch(e){}
}

// 地址列表页选择地址
App.prototype.setSelectedAddressId = function(addressId){
	try{
		if( this.target ){
			this.postMessage('setSelectedAddressId', [addressId]);
		}
		else{
			window.app.setSelectedAddressId(addressId);
		}
	}catch( e ){
	}
};

export {App}