export default {
	methods: {
		getTagByItem(item){
			if( item.source === 1 && item.counterType === 2 ){
				return {
					text: '百货'
					, className: 'badegBackground-3',
					imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-bh.png'
				};
			}

			if( item.source === 1 && item.counterType !== 2 ){
				return {
					text: '专柜'
					, className: 'badegBackground-3',
					imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-zg.png'
				};
			}

			if( item.source === 2 ){
				return {
					text: '超市'
					, className: 'badegBackground-4',
					imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-cs.png'
				};
			}

			if( item.source === 4 && item.storeType === 3 ){
				if( item.international ){
					return {
						text: '海外购'
						, className: 'badegBackground-2',
						imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-hwg.png'
					};
				}
				else{
					return {
						text: '自营'
						, className: 'badegBackground-5',
						imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-zy.png'
					};
				}
			}

			if( item.source === 4 && item.storeType === 2 ){
				return {
					text: '品牌'
					, className: 'badegBackground-1',
					imgUrl: '//image1.51tiangou.com/tgou2/img2/product/inter_store_tag-pp.png'
				};
			}

			return {};
		}
		, getStoreNameByItem(item){
			if( item.source === 4 && item.storeType === 3 && item.international === 1 ){
				return '';
			}

			return item.storeName;
		}
		, getPercentByItem(item){
			if( item.stock + item.soldQty === 0 ){
				return '100%';
			}
			else{
				return Math.floor(item.soldQty * 100 / (item.stock + item.soldQty)) + '%';
			}
		}
	}
};