<template>
<a :href="checkUrl"
   :data-scp="scp"
   :data-bk="bk"
   @click="goApp($event)">
	<div>
		<wx-open-launch-weapp v-if="isWeixin && isList"
		                      :username="webcast.userName"
		                      :path="webcast.list">
			<script type="text/wxtag-template">
				<img style="{{style}}"
				     width="{{width}}"
				     height="{{height}}"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<wx-open-launch-weapp v-else-if="isWeixin && isRoom"
		                      :username="webcast.userName"
		                      :path="`${webcast.room}?roomId=${roomId}`">
			<script type="text/wxtag-template">
				<img style="{{style}}"
				     width="{{width}}"
				     height="{{height}}"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<wx-open-launch-weapp v-else-if="isWeixin && isCoupon"
		                      :username="webcast.userName"
		                      :path="`${webcast.coupon}?couponId=${couponId}`">
			<script type="text/wxtag-template">
				<img style="{{style}}"
				     width="{{width}}"
				     height="{{height}}"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<wx-open-launch-weapp v-else-if="isWeixin && isMaotai"
		                      :username="webcast.userName"
		                      :path="`${webcast.maotai}`">
			<script type="text/wxtag-template">
				<img style="{{style}}"
				     width="{{width}}"
				     height="{{height}}"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<wx-open-launch-weapp v-else-if="isWeixin && isApplets"
		                      :username="checkName"
		                      :path="data.clickUrl">
			<script type="text/wxtag-template">
				<img style="{{style}}"
				     width="{{width}}"
				     height="{{height}}"
				     src="{{protocol}}{{data.imageUrl}}" alt="">
			</script>
		</wx-open-launch-weapp>
		<img v-else
		     :style="style"
		     :width="width"
		     :height="height"
		     :src="data.imageUrl" alt="">
	</div>
</a>
</template>
 v  .l,kjmxz
<script>
import domain   from 'javascript/libs/tg/domain.js';
import app  from 'javascript/libs/tg/app/index.js';

let isWeixin = maple.device.weixin
	, protocol = location.protocol
	;

export default {
	name: ''
	, props: {
		data: {
			type: Object
			, default: null
		}
		, scp: {
			type: String
			, default: ''
		}
		, bk: {
			type: String
			, default: ''
		}
		, width: {
			type: Number
		}
		, height: {
			type: Number
		}
		, style: {
			type: String
			, default: 'max-width:100%;'
		}
	}
	, created(){
	}
	, data(){
		return {
			isWeixin
			, isHybrid: maple.cookie('hybrid') === 'true'
			, webcast: {
				userName: domain.webcast.userName
				, list: `${domain.webcast.path}.html`
				, room: `${domain.webcast.room}.html`
				, coupon: `${domain.webcast.coupon}.html`
			},
      overSea: {
        userName: domain.overSea.userName
      }
			// , barCode: {
			// 	userName: domain.webcast.userName
			// 	, path: `${domain.webcast.barCode}.html`
			// }
			// , scan: {
			// 	userName: domain.scan.userName
			// 	, path: `${domain.scan.path}.html?storeId=`
			// }
			, protocol
			, roomId: ''
			, couponId: ''
		};
	}
	, methods: {
		goApp($event){
			if( this.isHybrid ){
				if( this.isCoupon ){
					$event.preventDefault();

					app.startWxapp({
						userName: domain.userName
						, path: this.data.clickUrl
					});
				}
				else if( this.isList || this.isRoom ){
					$event.preventDefault();

					app.startWxapp( domain.webcast );
				}
			}
		}
	}
	, watch: {
		data: {
			handler(newValue){
				if( newValue ){
					this.show = true;

					if( this.isRoom ){
						let rs = /webcast\.html.*?#\/room\?roomId=([^&]*)/.exec( newValue.clickUrl )
							;

						if( rs ){
							this.roomId = rs[1];
						}
					}
					else if( this.isCoupon ){
						let rs = /zhuli\.html.*?#\/zhuli\?couponId=([^&]*)/.exec( newValue.clickUrl )
							;

						if( rs ){
							this.couponId = rs[1];
						}
					}
				}
			}
			, immediate: true
		}
	}
	, computed: {
	  checkName() {
	    const item = this.data
      if(item.miniProgramKey && item.miniProgramKey === 'DASHANG_APPLET') {
        return this.webcast.userName
      } else {
        return this.overSea.userName
      }
    },
	  checkUrl() {
	    if (!this.isApplets) {
        return this.isWeixin && (this.isList || this.isRoom ) ? '' : this.data.clickUrl
      } else {
        if (!this.isWeixin) return `${this.protocol}/wechatQrcode.html?appletsType=${this.data.miniProgramKey}`
      }
    },
	  isApplets() {
	    return this.data.clickUrlType === 4
    },
		isList(){
			return /webcast\.html.*?#\/publicity/.test( this.data.clickUrl );
		}
		, isRoom(){
			return /webcast\.html.*?#\/room/.test( this.data.clickUrl );
		}
		, isCoupon(){
			return /zhuli\.html.*?#\/zhuli/.test( this.data.clickUrl );
		}
		, isMaotai(){
			return /tempActivity\.html.*?\/maotaiVXApp/.test( this.data.clickUrl );
		}
	}
};
</script>
