<template>
    <div  class="tgPoster-type4">
        <div class="list list-col2">
            <div class="list-item" v-for="(item,index) in data.items">
                <a :href="item.clickUrl" :data-scp="`ad_${data.id}_${item.id}.0`" class="borderb-light">
                    <img :src="item.imageUrl" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js';
    export default {
        name: "type4",
        mixins:[mixin],
    }
</script>

<style>

</style>