<template>
    <div class="entranceIcon">
        <cmui-list class="iconItem fs-15" :class="'margin_'+col" :col="col">
            <cmui-list-item v-for="(item, index) in showData" class="margint10">
                <a style="display: block;"
                   :href="checkUrl(item)"
                   :data-scp="`entranceIcon.${scpPrefix + index}`"
                   :data-bk="`id-${item.id}`">
                    <div class="text-center">
                        <wx-open-launch-weapp v-if="isWeixin&&isWebcast(item)"
                                              :username="webcast.userName"
                                              :path="webcast.path">
                            <script type="text/wxtag-template">
                                <div style="text-align: center;">
                                    <img alt="" width="50"
                                         src="{{protocol}}{{item.imageUrl}}">
                                    <div style="font-size: 11px;color: #222222;line-height: 20px;">
                                        <div class="text-limit1">{{item.title}}</div>
                                    </div>
                                </div>
                            </script>
                        </wx-open-launch-weapp>
	                      <wx-open-launch-weapp v-else-if="isWeixin&&isBarCode(item)"
	                                          :username="webcast.userName"
	                                          :path="webcast.barCode">
		                    <script type="text/wxtag-template">
			                    <div style="text-align: center;">
				                    <img alt="" width="50"
				                         src="{{protocol}}{{item.imageUrl}}">
				                    <div style="font-size: 11px;color: #222222;line-height: 20px;">
					                    <div class="text-limit1">{{item.title}}</div>
				                    </div>
			                    </div>
		                    </script>
	                    </wx-open-launch-weapp>
	                      <wx-open-launch-weapp v-else-if="isWeixin&&isScan(item)"
	                                          :username="scan.userName"
	                                          :path="scan.path">
		                    <script type="text/wxtag-template">
			                    <div style="text-align: center;">
				                    <img alt="" width="50"
				                         src="{{protocol}}{{item.imageUrl}}">
				                    <div style="font-size: 11px;color: #222222;line-height: 20px;">
					                    <div class="text-limit1">{{item.title}}</div>
				                    </div>
			                    </div>
		                    </script>
	                    </wx-open-launch-weapp>
                        <wx-open-launch-weapp v-else-if="isWeixin&&isApplets(item)"
                                            :username="checkName(item)"
                                            :path="item.clickUrl">
                        <script type="text/wxtag-template">
                          <div style="text-align: center;">
                            <img alt="" width="50"
                                 src="{{protocol}}{{item.imageUrl}}">
                            <div style="font-size: 11px;color: #222222;line-height: 20px;">
                              <div class="text-limit1">{{item.title}}</div>
                            </div>
                          </div>
                        </script>
                      </wx-open-launch-weapp>
                        <div v-else>
                            <img alt="" width="50"
                                 :src="item.imageUrl">
                            <div class="fs-11 text-darker lh-20 margint10"><div class="text-limit1" v-text="item.title"></div></div>
                        </div>
                    </div>
                </a>
            </cmui-list-item>
        </cmui-list>
    </div>
</template>

<script>
import domain from 'javascript/libs/tg/domain.js';

let isWeixin = maple.device.weixin
    , protocol = location.protocol
    ;

export default {
    name: "entranceIcon",
    props:{
        data:{type:Array,default:[]}
        , scpPrefix: {
            type: String
            , default: ''
        }
    },
    data(){
        return {
            isWeixin
            , webcast: {
                userName: domain.webcast.userName
                , path: `${domain.webcast.path}.html`
		        , barCode: `${domain.webcast.barCode}.html`
            }
            , scan: {
            	userName: domain.scan.userName
		        , path: `${domain.scan.path}.html`
	        },
          overSea: {
              userName: domain.overSea.userName
          }
            , protocol
        }
    }
    , computed: {
        showData(){
            let l = this.data.length
                ;

            if( l < 4 ){
                return [];
            }
            if( l < 5 ){
                return this.data.slice(0, 4);
            }
            else if( l > 7 && l < 10 ){
                return this.data.slice(0, 8);
            }
            else if( l < 8 ){
                return this.data.slice(0, 5);
            }
            else if( l > 9 ){
                return this.data.slice(0, 10);
            }
            return this.data.slice(0, 4);
        }
        , col(){
            let l = this.data.length
                ;

            if( l < 5 || (l > 7 && l < 10) ){
                return 4;
            }
            else if( l < 8 || (l > 9) ){
                return 5;
            }

            return 4;
        }
    }
    , methods: {
      checkUrl (item) {
        // 保持原来的逻辑
        if (!this.isApplets(item)) {
          return this.isWeixin&&(this.isWebcast(item)||this.isBarCode(item)||this.isScan(item))?'':item.clickUrl
        } else {
          // 新增的小程序链接类型
          if (!this.isWeixin) return `${this.protocol}/wechatQrcode.html?appletsType=${item.miniProgramKey}`
        }
      },
    checkName(item) {
        if(item.miniProgramKey && item.miniProgramKey === 'DASHANG_APPLET') {
          return this.webcast.userName
        } else {
          return this.overSea.userName
        }
    },
        isWebcast(item){    // 判断是否是直播
            return /webcast\.html.*?#\/publicity/.test( item.clickUrl );
        },
        isScan(item){ // 判断是否为扫码购
              return /store\/scavengingPurchase\.html/.test( item.clickUrl );
        },
        isBarCode(item){   // 判断是否为结算码
        	return /mine\/idCode\.html/.test( item.clickUrl );
		    },
        isApplets(item) { // 判断链接类型是否为小程序
              return item.clickUrlType === 4
        }
    }
};
</script>

<style scoped>
.entranceIcon .iconItem{

}
</style>
