<template>
    <a href="/discover/myDiscovery.html" data-scp="avatar.0">
        <i class="icon" style="margin-right: 0;">
            <img :src="avatar" alt="" width="22" height="22" style="vertical-align: baseline;border-radius: 50%;"/>
        </i>
        <span></span>
    </a>
</template>

<script>
import memberService  from 'spa/lib/api/member.js';
import domain   from 'javascript/libs/tg/domain.js';
export default {
    name: "avatar"
    , data(){
        return {
            avatar: '//image1.51tiangou.com/tgou2/img2/fitting/defaultPic.jpg'
        }
    }
    , created(){
        if( tg.getCookie('isLogin') === 'true' ){
            memberService.baseInfo().then((data)=>{
                if( data.photoUrl ){
                    this.avatar = domain.getImageUrl( data.photoUrl );
                }
            });
        }
    }
}
</script>

<style scoped>

</style>