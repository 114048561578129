import Super from "./class";
let serverList={
    get:{

    },
    post:{
        queryByTgouOrderId:'/publics/productSource/queryByTgouOrderId',
        queryByItemId:'/publics/productSource/queryByItemId'//根据营销品id查询跨境商品的溯源信息
    },
};
export default new Super(serverList,'wms');