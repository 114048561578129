<template>
    <div class="tgPoster-type9">
        <cmui-list :col="3" :border="true">
            <cmui-list-item v-for="(i,index) in data.items" v-if="index < (data.items.length - data.items.length%3)">
                <a :data-scp="`ad_${data.id}_${i.id}.${index}`" :data-bk="i.bk" :href="i.clickUrl||nolink"><img :src="i.imageUrl" alt="" width="100%"></a>
            </cmui-list-item>
        </cmui-list>
    </div>
</template>

<script>
    import mixin from './mixin.js';
    export default {
        name: "type9",
        mixins:[mixin],
        computed:{
        }
    }
</script>

<style>

</style>