/**
 * Created by chenqifeng on 2016/10/24.
 */

var secKey,sec_timestamp;

let getSec = function(){
    return secKey;
};

let setSec = function(sec){
    secKey = sec;
};

let getTimestamp = function(timestamp){
    return sec_timestamp;
};

let setTimestamp = function(timestamp){
    sec_timestamp = timestamp;
};

let cheatBreaker = function(sec,timestamp){
    var e = getSec() || sec || undefined,
        i = getTimestamp() || timestamp,
        k = (e || "") + "";
    if (e == null || e == -1) {
        return e;
    };

    return [
        (e + 8) * 8,
        e + i % 1000000,
        2 * 1000 + e,
        e + 821029,
        +k[k.length - 1],
        (e % 10) * (+k[k.length - 1]) - e,
        i + e,
        777,
        8 + 88 + 888 + 8888 + 88888 + 888888,
        3 + e
    ][e % 10];
};


export {getSec,setSec,getTimestamp,setTimestamp,cheatBreaker};