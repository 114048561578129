var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.traceSource.clickUrl,
          expression: "traceSource.clickUrl",
        },
      ],
      attrs: { href: _vm.traceSource.clickUrl, "data-scp": "traceSource.00" },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex-container paddingh20 paddingv10 borderb lh-20",
        staticStyle: { background: "#f8f8f8" },
      },
      [
        _c("div", { staticClass: "flex-container text-orange fs-12" }, [
          _c("img", {
            staticClass: "marginr10",
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/product/icon-GGCT.png",
              width: "14",
              height: "16",
              alt: "",
            },
          }),
          _vm._v("\r\n\t\t\t直采精选，查看 [商品溯源]\r\n\t\t"),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "dis-i" }, [
          _c("span", { staticClass: "icon-right fs-11 text-light" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }