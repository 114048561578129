<template>
    <a href="javascript:void(0)" data-scp="more.0" @click="click">
        <i class="icon icon-more"></i>
    </a>
</template>

<script>
    export default {
        name: "more",
        methods:{
            click(event){
                event.cancelBubble=true;
                this.$parent.showMenuList=!this.$parent.showMenuList;
            }
        }
    }
</script>

<style scoped>

</style>