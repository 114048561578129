import Super from './class.js';

let serverList = {
        get: {}
        , post: {
            save: '/member/face/save'
            , login: '/b2b/user/login'
            , query: '/b2b/contact/query'
            , comment: '/b2b/contact/comment'
            , add: '/b2b/contact/add'
            , queryVipKid: '/vipkid/query'
            , saveVipKid: '/vipkid/save'

            // 大商购物节活动接口
            , queryHitDetail: '/publics/lottery/queryHitDetail'
            , queryHit: '/publics/lottery/queryHit'
            // 大商购物街 erp 接口
            , lotteryStart: '/privates/erp/lottery/start'
            , lotteryEveryday: '/privates/erp/lottery/new/everyday'
            , lotteryBiggest: '/privates/erp/lottery/biggest'
            , lotteryPage: '/privates/erp/lottery/nextPage'
            , lotteryQueryBiggest: '/privates/erp/lottery/queryBiggest'

            // 换脸活动 获取活动详情
            , faceActivityInfo: '/publics/change/face/getActivityInfoById'
            // 换脸活动 合成图像
            , faceCompose: '/publics/change/face/replaceFace'
        }
    }
    ;

export default new Super(serverList, 'interact');