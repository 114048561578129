<template>
<!-- <cmui-list :col="2" :space="20">
<cmui-list-item v-for="item in data"> -->
<div>
	<a :href="data.clickUrl"
       :data-trace-id="data.traceId ? data.traceId : ''"
       :data-store-id="data.storeId"
       :data-scp="`${scpC}.${index}`"
       :data-bk="`${bk}-${data.id}`">
		<div class="pos-r bg-white" style="overflow: hidden;border-radius:8px;">
			<div class="">
				<img alt=""  :src="data.imageUrl">
				<!-- <span class="pos-a bottom0 left0 text-white text-limit1 lh-1" style="font-size: 8px;background: #BDA28B;padding: 3px;" v-text="getStoreNameByItem(data)" v-show="!(data.source==4&&data.international==1&&data.storeType==3)"></span> -->
			</div>
			<div class="flex1 paddingh20 paddingt10 paddingb20">
				<div style="width:100%">
					<!-- badge颜色  专柜/bg-bule 云专柜/bg-green2 品牌/bg-orange 超市/bg-green 自营/bg-red  -->
                    <div class="fs-13 text-darker" :class="{'text-fixed2': isTgzy}">
						<strong>{{data.title}}</strong>
					</div>
                    <div class="margint10" style="height: 22px;overflow: hidden;" v-if="!isTgzy || data.couponList.length">
                        <coupon :data="data.couponList" :isTgzy="isTgzy"></coupon>
                    </div>
				</div>
				<div class="flex-container bottom margint10" style="width:100%">
					<template v-if="(showNewPrice && data.newPrice)||data.groupPrice">
						<div class="flex-container left center" v-if="showNewPrice && data.newPrice">
							<span class="fs-12 text-red">新人价</span>
							<span class="text-red fs-18 text-right"><span class="fs-12">¥</span>{{data.newPrice}}</span>
							<del class="text-light fs-12 marginl10 lh-16" v-if="data.originalPrice !== data.newPrice">{{data.originalPrice}}</del>
						</div>
						<div class="flex-container left center" v-else v-if="data.groupPrice">
							<span class="fs-12 text-red">拼团价</span>
							<span class="flex1 text-red fs-18 text-right"><span class="fs-12">¥</span>{{data.groupPrice}}</span>
							<span class="fs-12 text-darker marginl10">单买价{{data.price}}</span>
						</div>
					</template>
					<div class="flex-container left center" v-else>
						<span class="flex1 text-red fs-18 text-right" style="font-weight: bold">
							<span class="fs-12">¥</span>{{data.buyoutPrice || data.price}}
						</span>
						<del class="text-light fs-12 marginl10 lh-16" v-if="data.originalPrice !== data.price">{{data.originalPrice}}</del>
					</div>
				</div>
				<div style="min-height:22px;" v-if="!isTgzy">
					<div class="flex-container left" v-if="data.vipPrice">
						<span class="text-black fs-16"><span class="fs-12">¥</span>{{data.vipPrice}}</span>
						<img src="//image1.51tiangou.com/tgou2/img2/product/ico-up_05.png" width="48" alt="">
					</div>
				</div>
				<div>
<!--                    <span class="fs-10 lh-1 badegBackground" v-text="getTagByItem(data).text"></span>-->
                    <img :src="getTagByItem(data).imgUrl" alt="" height="16px">
				</div>
			</div>
		</div>
	</a>
</div>
<!-- </cmui-list-item>
</cmui-list> -->
</template>

<script>
import mixin  from './mixin.js';
import coupon from 'spa/component/common/coupon.vue';

export default {
	name: 'recommended'
	, components: {
		coupon
	}
	, mixins: [mixin]
	, props: {
		similar: {
			type: Boolean
			, default: true
		}
		, data: {
			type: Object
			, default: {}
		}
		, showNewPrice: {   // 显示新人价
			type: Boolean
			, default: false
		}
		, index: {
			type: Number
			, default: 0
		}
		, scpC: {
			type: String
			, default: 'recommended'
		}
		, bk: {
			type: String
			, default: 'id'
		}
        , isTgzy: {
            type: Boolean
            , default: false
        }
    }
};
</script>

<style lang="scss" type="text/scss">
/* 品牌旗舰店 */
.badegBackground-1{
	background-image: linear-gradient(134deg, #3023AE 0%, #C86DD7 100%);
}

/* 自营海外购 */
.badegBackground-2{
	background: #FF2238 !important;
}

/* 商场专柜 商场门店 */
.badegBackground-3{
	background-image: linear-gradient(135deg, #FFA600 0%, #FF6D00 100%);
}
/* 超市优选 */
.badegBackground-4{
	background-image: linear-gradient(133deg, #16CC90 0%, #007C2F 100%);
}
/* 自营旗舰店 */
.badegBackground-5{
	background-image: linear-gradient(134deg, #004ACB 0%, #32C5FF 100%);
}
.badegBackground {
    padding:2px 4px;
    color: #FF2238;
    border-radius:4px;
    border: 1px solid #FF2238;
}
</style>