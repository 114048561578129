<!--红包雨-->
<template>
    <div class="full-screen">
        <!--开始弹窗-->
        <div v-if="state === 'ready'" style="width: 100%; height: 100%;">
            <div @click="closeRedPacket('close')" class="icon-close text-white btn-close text-center float-right" data-scp="gb.0"></div>
            <div class="modal-container" style="width: 245px">
                <img src="//image1.51tiangou.com/tgou2/img2/redpacket/redpacket-start.png" alt="" @click="startRain" data-scp="qqhb.0">
                <div @click="startRain" class="btn-start text-center text-bolder fs-16" data-scp="qqhb.0">去抢红包</div>
            </div>
        </div>
        <!--倒计时-->
        <div v-if="state === 'countdown'" class="text-center" style="width: 100%; height: 100%;">
            <div @click="openRule" class="btn-rule text-center text-bolder text-white float-left" data-scp="hdgz.0">活动规则</div>
            <div @click="closeRedPacket()" class="icon-close text-white btn-close text-center float-right" data-scp="gb.0"></div>
            <div class="animate-arise" style="width: 100%;height: 100%;">
                <img src="//image1.51tiangou.com/tgou2/img2/redpacket/start-countdown.png" alt="" style="margin-top: 134px;width: calc(100% - 48px)">
                <span class="num-count text-bolder">{{downTimes}}s后</span>
            </div>

            <!--活动规则弹窗-->
            <div v-if="showRule" class="modal-rule animate-arise">
                <div class="modal-bottom">
                    <p class="rule-title text-center text-bolder fs-16 text-black" style="margin-bottom: 21px">活动规则</p>
                    <p class="fs-14 text-dark text-left" style="height: 260px; overflow: auto">{{rulesMes}}</p>
                </div>
                <div @click="ruleStartRain" class="btn-start text-center text-bolder fs-16" style="margin: 40px auto 0">去抢红包</div>
            </div>
        </div>
        <!--红包雨-->
        <div v-if="state === 'raining'" class="pos-r" style="width: 100%;height: 100%;overflow: hidden">
            <!--光点闪烁-->
            <div class="light-point point-1"></div>
            <div class="light-point point-2"></div>
            <div class="light-point point-3"></div>
            <div class="light-point point-4"></div>
            <!--下落动画-->
            <div class="bg-red-rain"></div>
            <div class="pos-a text-white text-center click-count">{{clickCountDown}}s</div>
            <div class="pos-a fs-14 text-white" style="top: 122px; left: 45px">剩余时间</div>
            <div @click="closeRedPacket()" class="pos-a icon-close text-white btn-close text-center"
                 style="top: 80px; right: 40px; margin: 0" data-scp="gb.0"></div>
            <img src="//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-1.png" alt="" v-if="clickCount >= 4 && clickCount < 8"
                 class="pos-a animate-arise" style="top: calc(50% - 207px);left: calc(50% - 114px);width: 228px">
            <img src="//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-2.png" alt="" v-if="clickCount >= 8 && clickCount < 12"
                 class="pos-a animate-arise" style="top: calc(50% - 207px);left: calc(50% - 114px);width: 228px">
            <img src="//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-3.png" alt="" v-if="clickCount >= 12 && clickCount < 16"
                 class="pos-a animate-arise" style="top: calc(50% - 207px);left: calc(50% - 114px);width: 228px">
            <img src="//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-4.png" alt="" v-if="clickCount >= 16"
                 class="pos-a animate-arise" style="top: calc(50% - 207px);left: calc(50% - 114px);width: 228px">

            <p v-show="clickCount" class="pos-a text-bolder num-click">+{{ clickCount }}</p>
            <div class="big-red-packet shake" @click="addPacket" data-scp="qhb.0"></div>
        </div>
        <!--中奖结果-->
        <div v-if="state === 'over'" style="width: 100%; height: 100%;" class="animate-arise">
            <div @click="closeRedPacket()" class="icon-close text-white btn-close text-center float-right" data-scp="gb.1"></div>
            <div class="modal-container" style="width: 300px">
                <!--未中奖-->
                <img src="//image1.51tiangou.com/tgou2/img2/redpacket/no-lottery.png" alt="" v-if="!prizeMes.isWin && lotteryOver">
                <!--中奖了-->
                <div class="get-prize" v-if="prizeMes.isWin && lotteryOver">
                    <img src="//image1.51tiangou.com/tgou2/img2/redpacket/get-prize.png" alt="">
                    <div class="prize-info text-center pos-a">
                        <span style="font-size: 28px">{{prizeMes.couponInterestPoint}}</span>
                        <span class="fs-16 text-bolder" style="vertical-align: text-bottom">{{prizeMes.couponType}}</span>
                        <p class="fs-12 text-dark" style="margin-bottom: 10px">{{prizeMes.couponStartTime}}-{{prizeMes.couponEndTime}}</p>
                        <span class="fs-12 text-light">在我的-优惠券中查看</span>
                    </div>
                </div>
                <div @click="closeRedPacket()" class="btn-start text-center text-bolder fs-16"
                     v-if="lotteryOver"
                     style="margin: 40px auto 0;"
                     :data-scp="prizeMes.isWin ? 'kxsx.0' : 'wzdl.0'"
                     v-text="prizeMes.isWin ? '开心收下' : '我知道啦'"></div>
            </div>
        </div>
    </div>
</template>

<script>
import couponService  from 'spa/lib/api/coupon.js';
import timeUtil from "javascript/libs/tg/util/time";

const RP_OBJ = {
    maxW: 100,
    minW: 50,
    creat: 200//创建红包个数的快慢，单位ms
}
const STAR_OBJ = {
    maxH: 100,
    minH: 70,
    creat: 200//创建红包个数的快慢，单位ms
}
// 随机比例取值
const PER_SCALE = ['40%', '60%', '80%', '100%'];

export default {
    name: "rainRedPacket",
    props: {
        page: {
            type: String,
            default: ''
        },
        // 活动规则
        rulesMes: {
            type: String,
            default: ''
        },
        // 活动id
        redPacketId: {
            type: String,
            default: ''
        },
        // 下落元素
        showIcon: {
            type: String,
            default: '//image1.51tiangou.com/tgou2/img2/redpacket/fu.png'
        }
    },
    data() {
        return {
            state: 'ready',
            clickCount: 0,
            downTimes: 5,
            countDownTime: null,
            showRule: false,
            clickCountDown: 5,
            tipClickImg: '',
            prizeMes: {},
            showId: 0,
            // 抽奖接口完成
            lotteryOver: false
        }
    },
    watch: {
        downTimes(newV) {
            if (newV === 0) {
                this.state = 'raining';
                clearInterval(this.countDownTime);
                this.countDownTime = null;
                this.$nextTick(() => {
                    this.rainBg();
                });
            }
        }
    },
    methods: {
        // 点击红包
        addPacket() {
            this.clickCount++;
            if (this.clickCount === 1) {
                // 第一次点击红包展开图
                let redDom = $('.big-red-packet');
                redDom.css("background-image","url('//image1.51tiangou.com/tgou2/img2/redpacket/redpacket_open.png')");
                redDom.removeClass('shake');
            }
        },
        // 关闭红包雨
        closeRedPacket(flag) {
            this.$parent.isRain = false;
            $('body').css("overflow","auto");
            if (flag) {
                // 记录日期、页面、门店id（逛店页）
                const today = timeUtil.formatDate(new Date(), 'YYYY-MM-DD');
                let redArr = JSON.parse(localStorage.getItem('redPacketA')) || [];
                let redObj = {
                    date: today,
                    page: this.page
                };
                if (this.page === 'store') {
                    redObj.storeId = tg.getFromUC('storeId');
                }
                redArr.push(redObj);
                localStorage.setItem('redPacketA', JSON.stringify(redArr));
            }
        },
        // 点击活动规则
        openRule() {
            if (this.showRule) {
                return;
            }
            this.showRule = true;
            // 停止倒计时
            clearInterval(this.countDownTime);
            this.countDownTime = null;
        },
        // 点击开始抢红包
        startRain() {
            // 未登录
            if(!tg.isLogin()){
                location.href = `/login/login.html?loginBack=${encodeURIComponent( location.href )}`;
                return;
            }
            // 校验是否参加过活动
            couponService.isShowRedPacketActivity({
                data: {
                    redPacketId: this.redPacketId
                },
                contentType: 'application/json'
            }).then((dataA)=>{
                // 开始倒计时
                this.state = 'countdown';
                // 记录接口
                couponService.hasShowRedPacketActivity({
                    data: {
                        redPacketId: this.redPacketId
                    },
                    contentType: 'application/json'
                }).then((data) => {
                    this.showId = data;
                })
                this.countDownTime = setInterval(() => {
                    // 倒计时动效
                    this.downTimes--;
                }, 1000);
            }).catch(({code, message,data})=>{
                let mes = message || '网络请求不给力'
                maple.alert({
                    content:`<p class="text-center">${mes}</p>`,
                    okText: '我知道了',
                    okFn: () => {
                        this.closeRedPacket();
                    }
                })
            });
        },
        // 从活动规则进入抢红包
        ruleStartRain() {
            this.state = 'raining';
            clearInterval(this.countDownTime);
            this.countDownTime = null;
            this.$nextTick(() => {
                this.rainBg();
            });
        },
        // 背景动效
        rainBg() {
            const maxLeft = $('.bg-red-rain').width();
            let time1, timeStar, i = 0, j = 0;
            // 流星
            timeStar = setInterval(() => {
                // 随机left值
                let left = Math.random() * maxLeft - 8;
                left = left.toFixed(2);
                if (left < 0) {
                    left = 0;
                }
                // 随机长度
                let height = Math.random() * STAR_OBJ.maxH;
                height = height.toFixed(2);
                if (height < STAR_OBJ.minH) {
                    height = STAR_OBJ.minH;
                }

                j++;
                // 创建流星
                const star = `<div class="item-star star${j}" style="border-width: 3px 0 3px ${height}px; left:${left}px;"></div>`;
                $(`<style>.star${j}::before{left: -${height}px;}</style>`).appendTo('head');

                $('.bg-red-rain').append(star);
                this.moveBg($('.star' + j), 4000);
            }, STAR_OBJ.creat)
            // 掉落元素
            time1 = setInterval(() => {
                // 随机比例
                const scale = PER_SCALE[Math.floor((Math.random()*PER_SCALE.length))];
                // 随机亮度 70%-100%
                const light = Math.random()*(30)+70;

                // 随机left值
                let width = Math.random() * RP_OBJ.maxW;
                width = width.toFixed(2);
                if (width < RP_OBJ.minW) {
                    width = RP_OBJ.minW;
                }
                let left = Math.random() * maxLeft - width;
                left = left.toFixed(2);
                if (left < 0) {
                    left = 0;
                }
                i++;
                // 创建红包
                let item = `<div class="item-icon rain${i}" style="left:${left}px; scale:${scale}; filter: brightness(${light}%); background-image: url('${this.showIcon}')"></div>`;

                $('.bg-red-rain').append(item);
                // 红包移动
                this.moveBg($('.rain' + i), 4000);
            }, RP_OBJ.creat)

            // 五秒抢红包时间
            let clickCountDownTime = setInterval(() => {
                // 倒计时动效
                this.clickCountDown--;
            }, 1000);
            setTimeout(() => {
                clearInterval(clickCountDownTime);
                clickCountDownTime = null;
                clearInterval(timeStar);
                timeStar = null;
                clearInterval(time1);
                time1 = null;
                this.redPacketOver();
            }, 5000)
        },
        // 抢红包结束
        redPacketOver() {
            this.state = 'over';
            if (this.clickCount) {
                // 抽奖接口
                couponService.lotteryRedPacket({
                    data: {
                        redPacketId: this.redPacketId,
                        memberId: tg.getCookie('memberId'),
                        showId: this.showId
                    },
                    contentType: 'application/json'
                }).then((data)=>{
                    this.lotteryOver = true;
                     // 中奖
                    if (data.isWin) {
                        this.prizeMes = data;
                        this.prizeMes.couponEndTime = timeUtil.formatDate(new Date(data.couponEndTime), 'YYYY.MM.DD');
                        this.prizeMes.couponStartTime = timeUtil.formatDate(new Date(data.couponStartTime), 'YYYY.MM.DD');
                    }
                })
            }
        },
        moveBg(op, speed) {
            // 红包下落的高度，页面高度加上自身高度就能看到完全落到最底部
            const winH = $(window).height();
            const maxH = winH + op.height() + 300;

            op.animate({
                'top': maxH + 'px'
            }, speed, function () {
                // 删除该红包
                op.remove();
            });
        }
    }
};
</script>
<style>
.full-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100;
}

//开始状态
.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-start {
    margin: 40px auto 0;
    width: 220px;
    height: 44px;
    background: linear-gradient(90deg, #FDF1D6 0%, #FAE5AA 100%);
    border-radius: 44px;
    color: #FF2238;
    line-height: 44px;
}

//倒计时
.btn-rule {
    margin-top: 80px;
    margin-left: 40px;
    width: 80px;
    height: 28px;
    border: 1px solid #fff;
    border-radius: 89px;
    line-height: 28px;
}

.btn-close {
    margin-top: 80px;
    margin-right: 40px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: solid 1px #fff;
    line-height: 24px;
}

.num-count {
    position: absolute;
    top: 283px;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 0 0 20px rgba(255,253,225,0.53);
    color: #FFF5E1;
    font-size: 28px;
}

.animate-arise {
    animation: arise 0.3s;
}
@keyframes arise {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}

// 活动规则
.modal-rule {
    position: absolute;
    top: calc(50% - 212px);
    left: calc(50% - 130px);
}
.modal-bottom {
    padding: 13px 24px 24px;
    width: 260px;
    height: 340px;
    background: #fff;
    border-radius: 10px;
}

//红包雨
.big-red-packet {
    width: 191px;
    height: 242px;
    position: absolute;
    top: calc(50% - 83px);
    left: calc(50% - 95px);
    background: url("//image1.51tiangou.com/tgou2/img2/redpacket/redpacket_un_open.png") no-repeat;
    background-size: cover;
}
.big-red-packet:active {
    scale: 1.05;
}

.shake {
    animation: animateShake 2.5s .15s linear infinite;
    animation-delay: 1s;
}
@keyframes animateShake{
    10% {
        transform: rotate(15deg);
    }
    20% {
        transform: rotate(-10deg);
    }
    30% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(-5deg);
    }
    50%,100% {
        transform: rotate(0deg);
    }
}

// 背景
.bg-red-rain {
    width: 200%;
    height: 150%;
    transform: rotateZ(30deg) translateX(-36%);
}

.click-count {
    top: 80px;
    left: 40px;
    width: 67px;
    height: 38px;
    border: solid 1px #fff;
    border-radius: 38px;
    line-height: 38px;
    font-size: 24px;
}

.num-click {
    top: calc(50% - 144px);
    left: 50%;
    transform: translate(-50%);
    font-size: 40px;
    text-shadow: 0 0 8px #D35744;
    text-stroke: 1px #FF3828;
    color: #FFFBE8;
}

// 背景光点
.light-point {
    position: absolute;
    width: 8px;
    height: 8px;
    background: #FFC35F;
    filter: blur(4px);
    animation: twinkle 2s infinite alternate;
}
.point-1, .point-2 {
    width: 12px;
    height: 12px;
}
.point-1 {
    top: 169px;
    right: 91px;
}
.point-2 {
    top: 217px;
    left: 81px;
    animation-delay: 0.5s;
}
.point-3 {
    bottom: 401px;
    left: 68px;
    animation-delay: 1s;
}
.point-4 {
    bottom: 327px;
    right: 59px;
    animation-delay: 1.5s;
}
@keyframes twinkle {
    0% {
        filter: blur(4px) brightness(30%);
    }
    100% {
        filter: blur(4px) brightness(100%);
    }
}

// 掉落元素
.item-icon {
    background-size: cover;
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
}

// 流星
.item-star {
    position: absolute;
    transform: translate(-50%, -50%) rotate(-90deg);
    border: solid;
    border-radius: 2px 0 0 2px;
    transform-origin: 0 0 0;
    border-color: transparent transparent transparent #fff;
}

.item-star::before {
    content: "";
    position: absolute;
    top: -3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
}

// 中奖结果
.get-prize {
    margin: 0 auto;
    transform: translateX(-7px);
}
.prize-info {
    top: 93px;
    left: calc(50% + 10px);
    transform: translateX(-50%);
    color: #F11304;
}
</style>