var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      alt: "",
      width: "100%",
      "data-img-placeholder": _vm.placeholder,
      src: _vm.src,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }