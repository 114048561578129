<template>
    <div  class="tgPoster-type67">
        <cmui-list :border="true" :col="[2,3]">
            <cmui-list-item>
                <a :data-scp="'ad_'+data.id+'_'+data.items[0].id+'.0'" :data-bk="data.items[0].bk" :href="data.items[0].clickUrl||nolink"><img :src="data.items[0].imageUrl" alt="" width="100%" height="100%"></a>
            </cmui-list-item>
            <cmui-list-item>
                <cmui-list :border="true">
                    <cmui-list-item>
                        <a :data-scp="'ad_'+data.id+'_'+data.items[1].id+'.1'" :data-bk="data.items[1].bk" :href="data.items[1].clickUrl||nolink"><img :src="data.items[1].imageUrl" alt="" width="100%" height="100%"></a>
                    </cmui-list-item>
                    <cmui-list-item>
                        <cmui-list :col="2" :border="true">
                            <cmui-list-item>
                                <a :data-scp="'ad_'+data.id+'_'+data.items[2].id+'.2'" :data-bk="data.items[2].bk" :href="data.items[2].clickUrl||nolink"><img :src="data.items[2].imageUrl" alt="" width="100%" height="100%"></a>
                            </cmui-list-item>
                            <cmui-list-item>
                                <a :data-scp="'ad_'+data.id+'_'+data.items[3].id+'.3'" :data-bk="data.items[3].bk" :href="data.items[3].clickUrl||nolink"><img :src="data.items[3].imageUrl" alt="" width="100%" height="100%"></a>
                            </cmui-list-item>
                        </cmui-list>
                    </cmui-list-item>
                </cmui-list>
            </cmui-list-item>
        </cmui-list>
    </div>
</template>

<script>
    import mixin from './mixin.js';
    export default {
        name: "type67",
        mixins:[mixin],
    }
</script>

<style>

</style>