var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tg-foot flex-container fixed-bottom bg-white bordert" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "flex1 text-center",
          attrs: { "data-scp": item.scpC + ".00" },
        },
        [
          item.icon === "icon-live" && _vm.isWeixin
            ? _c(
                "div",
                [
                  _c(
                    "wx-open-launch-weapp",
                    {
                      attrs: {
                        username: _vm.webcast.userName,
                        path: _vm.webcast.path,
                      },
                    },
                    [
                      _c("script", { attrs: { type: "text/wxtag-template" } }, [
                        _vm._v(
                          '\n\t\t\t\t\t\t<img src="' +
                            _vm._s(_vm.protocol) +
                            '//image1.51tiangou.com/tgou2/img2/webcast/icon-webcast.png"\n\t\t\t\t\t\t\t width="26"\n\t\t\t\t\t\t\t height="22"\n\t\t\t\t\t\t\t style="display: block;margin: 2px auto 0;"\n\t\t\t\t\t\t\t alt="">\n\t\t\t\t\t\t<div style="font-size: 11px;color: #666;margin: 5px 0 2px;">' +
                            _vm._s(item.title) +
                            "</div>\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.change(item)
                    },
                  },
                },
                [
                  _vm.getIcon(item) !== "icon-shopping-current"
                    ? _c("i", {
                        staticClass: "icon dis-b",
                        class: _vm.getIcon(item),
                        staticStyle: { "font-size": "26px" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getIcon(item) !== "icon-shopping-current"
                    ? _c("span", { staticClass: "fs-10" }, [
                        _vm._v(_vm._s(item.title)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getIcon(item) === "icon-shopping-current"
                    ? _c("img", {
                        attrs: {
                          width: "40",
                          src: "//image1.51tiangou.com/tgou2/img2/index/icon-shop-cur.png",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }