var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tg-step" }, [
    _c("div", { staticClass: "horizontal" }, [
      _c(
        "div",
        { staticClass: "item-wrapper" },
        _vm._l(_vm.dataSource, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "item",
              class: [{ current: item.active }],
              on: {
                click: function ($event) {
                  return _vm.switchTap(index)
                },
              },
            },
            [
              index != _vm.dataSource.length - 1
                ? _c("div", { staticClass: "line" })
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "default"
                ? _c("div", { class: [{ active: item.active }] }, [
                    _vm._v(_vm._s(index + 1)),
                  ])
                : _c("div", {
                    class: [
                      { active: item.active },
                      { "icon-check": item.active },
                    ],
                  }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(item.text))]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }