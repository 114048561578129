var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.length
    ? _c(
        "div",
        { staticClass: "tg-poster" },
        _vm._l(_vm.data, function (item, index) {
          return _c("type" + item._prop_.type, {
            tag: "component",
            staticClass: "tg-poster-item",
            attrs: { data: item, index: index },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }