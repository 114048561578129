var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tgPoster-type9" },
    [
      _c(
        "cmui-list",
        { attrs: { col: 3, border: true } },
        _vm._l(_vm.data.items, function (i, index) {
          return index < _vm.data.items.length - (_vm.data.items.length % 3)
            ? _c("cmui-list-item", [
                _c(
                  "a",
                  {
                    attrs: {
                      "data-scp":
                        "ad_" + _vm.data.id + "_" + i.id + "." + index,
                      "data-bk": i.bk,
                      href: i.clickUrl || _vm.nolink,
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: i.imageUrl, alt: "", width: "100%" },
                    }),
                  ]
                ),
              ])
            : _vm._e()
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }