var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCouponPop,
          expression: "isCouponPop",
        },
      ],
      staticClass: "fixed-bottom mask-coupon-pop",
    },
    [
      _c(
        "div",
        { staticClass: "bg-coupon-pop pos-a", attrs: { id: "popBox" } },
        [
          _c("img", {
            staticClass: "pos-a",
            staticStyle: { left: "49px", top: "-60px" },
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/coupon/pop-yanhua.gif",
              width: "125",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "pos-a",
            staticStyle: {
              right: "49px",
              transform: "rotate(180deg)",
              top: "-60px",
            },
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/coupon/pop-yanhua.gif",
              width: "125",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "dis-b",
            staticStyle: { width: "5.3rem", "margin-top": "1.4rem" },
            attrs: { src: _vm.titleImg, alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "item-coupon flex-container left" }, [
            _c(
              "div",
              { staticClass: "text-center", staticStyle: { width: "90px" } },
              [
                _c("span", { staticStyle: { "font-size": "32px" } }, [
                  _vm.couponItem.subTitle.symbol
                    ? _c("span", { staticClass: "fs-20 lh-1" }, [
                        _vm._v(_vm._s(_vm.couponItem.subTitle.symbol)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "lh-1" }, [
                    _vm._v(_vm._s(_vm.couponItem.subTitle.main)),
                  ]),
                  _vm.couponItem.subTitle.main
                    ? _c("span", { staticClass: "fs-12 lh-1" }, [
                        _vm._v(_vm._s(_vm.couponItem.subTitle.desc)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.couponItem.limitStr,
                          expression: "couponItem.limitStr",
                        },
                      ],
                      staticClass: "fs-12",
                    },
                    [_vm._v(_vm._s(_vm.couponItem.limitStr))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.couponItem.max,
                          expression: "couponItem.max",
                        },
                      ],
                      staticClass: "fs-11",
                    },
                    [_vm._v(_vm._s(_vm.couponItem.max))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", {
                staticClass: "fs-16",
                domProps: {
                  textContent: _vm._s(_vm.getTypeName(_vm.couponItem.type)),
                },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "fs-12",
                domProps: { textContent: _vm._s(_vm.couponItem.time) },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "fs-12 text-center",
              staticStyle: { color: "#ff3f8e", "line-height": "32px" },
            },
            [_vm._v("在“我的”-“优惠券”中查看")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-receive text-white text-center",
              attrs: {
                "data-scp": "qsy.0",
                "data-trace-id": "",
                "data-bk": "id-" + _vm.couponId,
              },
              on: { click: _vm.receiveCoupon },
            },
            [_vm._v("领取去使用")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }