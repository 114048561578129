<template>
<!-- 专柜 -->
<div class="flex-container bg-white margint20 borderv padding20" v-show="shop.title">
	<div class="flex-container left">
		<img width="50" height="50" class="border"
			 :src="shop.logoImageUrl">
		<div class="marginl20">
			<div class="fs-15 text-darker" v-text="shop.title"></div>
			<div class="flex-container left margint10 text-dark" v-if="shop._prop_.type===1">
				<i class="icon-locate fs-12"></i>
				<span class="fs-10 marginl10" v-text="shop.address"></span>
			</div>
			<span class="badge badge-black pill margint10" style="font-size: 10px;"
				  v-if="shop._prop_.type===2 && shop.authorize">品牌官方授权</span>
		</div>
	</div>
	<div>
		<a :href="shop.clickUrl" data-scp="shop.0">
			<span class="badge badge-reverse badge-fill badge-red" style="font-size:10px;padding: 4px 5px;border-radius:20px;"
				  v-text="shop.btnName"></span>
		</a>
	</div>
</div>
</template>

<script>
export default {
	name: 'tg-shop'
	, props: {
		shop: {
			/**
			 * @example
			 * {
				"title": "植村秀（SHU UEMURA）",
				"address": "不要随便乱填地址，虽然这个也是乱写的！1楼",
				"logoImageUrl": "//test.img.tg-img.com/seller/201905/27/9283A44C-55B9-4F53-BE6E-A0FC3CCAF7DF.jpg!m",
				"clickUrl": "//m.test.66buy.com.cn/counter/index.html?id=124548",
				"btnName": "进入专柜"
				"_prop_": {
					"type": 1
				}
			}
			 * */
			type: Object
			, default: {
				_prop_: {}
			}
		}
	}
};
</script>