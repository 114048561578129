var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show && _vm.needScrollTop,
              expression: "show&&needScrollTop",
            },
          ],
          staticClass: "tg-scrollTop",
          on: { click: _vm.scrollTop },
        },
        [
          _c("img", {
            attrs: {
              src: "//image1.51tiangou.com/tgou2/img2/index/scrollToTop.png",
              width: "40",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pos-f right0",
          staticStyle: { bottom: "130px", display: "none", "z-index": "1" },
          attrs: { id: "topSearch" },
        },
        [
          _c("a", { attrs: { href: _vm.clickUrl } }, [
            _c("img", {
              attrs: {
                src: "//image1.51tiangou.com/tgou2/img2/index/topSearch.png",
                height: "40",
              },
            }),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }