<template>
      <transition name="fade">
        <div>
          <div class="tg-scrollTop" @click="scrollTop" v-show="show&&needScrollTop">
              <img src="//image1.51tiangou.com/tgou2/img2/index/scrollToTop.png" width="40">
          </div>
          <!-- 搜索页面 -->
          <div id="topSearch" class="pos-f right0" style="bottom:130px;display:none;z-index: 1;">
            <a :href="clickUrl">
                <img src="//image1.51tiangou.com/tgou2/img2/index/topSearch.png" height="40">
            </a>
          </div>
        </div>
      </transition>
</template>

<script>
    let scrollEvent=_.debounce(function(){
        this.show=maple.scrollBar('top').px>300;
    },300);
    let scrollTop=_.debounce(function(){
        $('html, body').animate({
            scrollTop: 0
        }, 200)
    },300,{
        'leading': true,
        'trailing': false
    });
    export default {
        name: "scrollTop",
        props:{
            needScrollTop:{type:Boolean,default:true}
        },
        methods:{
            scrollTop
        },
        data(){
            return {
                show:false,
                clickUrl: '/#/search?type=index&placeholder=放心购物上天狗'
            }
        },
        destroyed(){
            $(document).off('scroll',scrollEvent.bind(this));
        },
        created(){
            $(document).on('scroll',scrollEvent.bind(this));
            if (location.href.indexOf('activity.html')) {
                this.clickUrl = '/#/search?type=index&placeholder=放心购物上天狗&searchFromPage=activity'
            }
        }
    }
</script>

<style scoped lang="scss" type="text/scss">
    .tg-scrollTop{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        position: fixed;
        right: 15px;
        bottom: 70px;
        color:#999;
        span{
            display: block;
            line-height: .5;
            font-size: 12px;
        }
    }
</style>