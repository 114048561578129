var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { href: "javascript:void(0)", "data-scp": "share.0" },
      on: { click: _vm.share },
    },
    [_c("i", { staticClass: "icon icon-share" }), _c("span", [_vm._v("分享")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }