var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon" },
    _vm._l(_vm.data, function (item) {
      return _c(
        "div",
        {
          staticClass: "text-red text-limit1 ribbon_next marginr10",
          class: _vm.ribbonStyle(item),
        },
        [
          _c("div", { staticClass: "float-left" }, [
            _c("span", [_vm._v(_vm._s(item))]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }