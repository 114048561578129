var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cmui-popup",
    {
      attrs: {
        visible: _vm.visible,
        position: "bottom",
        "stop-page-scroll": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "top" }, slot: "top" }, [
        _c("div", { staticClass: "flex-container left pos-r" }, [
          _c(
            "div",
            {
              staticClass: "pos-a left20 bg-white",
              staticStyle: { top: "-20px" },
              on: { click: _vm.imgClick },
            },
            [
              _c("img", {
                staticClass: "border",
                attrs: { width: "90", height: "90", src: _vm.imgUrl },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "marginl20 margint20",
              staticStyle: { "padding-left": "100px" },
            },
            [
              _c("div", { staticClass: "fs-12" }, [
                _c("div", { staticClass: "text-red fs-15" }, [
                  _vm._v("¥"),
                  _c("span", {
                    staticClass: "fs-20",
                    domProps: { textContent: _vm._s(_vm.priceText) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-light",
                  domProps: { textContent: _vm._s(_vm.remainText) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-light" },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.chooseTag || _vm.tags.length,
                          expression: "chooseTag || tags.length",
                        },
                      ],
                      domProps: { textContent: _vm._s(_vm.chooseText) },
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.chooseTag || _vm.tags, function (item) {
                      return _c("span", {
                        staticClass: "marginr10",
                        domProps: { textContent: _vm._s(item) },
                      })
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-wrong pos-a right20 top30 fs-11 text-light",
            on: {
              click: function ($event) {
                _vm.visible = !_vm.visible
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 sku", staticStyle: { height: "300px" } },
        [
          _vm._l(_vm.tags, function (tag) {
            return _c("div", { staticClass: "paddingb30 margint20 borderb" }, [
              _c("div", {
                staticClass: "fs-13 text-dark",
                domProps: { textContent: _vm._s(tag) },
              }),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.tagsValues[tag].values, function (val) {
                  return _c(
                    "span",
                    {
                      staticClass:
                        "badge radius badge-reverse marginr20 margint20",
                      class:
                        _vm.choose[tag] && val === _vm.choose[tag]
                          ? "badge-red"
                          : _vm.term && _vm.term[tag] && _vm.term[tag][val]
                          ? "badge-black"
                          : "badge-black disabled",
                      staticStyle: { padding: "1px 10px", "min-width": "60px" },
                      on: {
                        click: function ($event) {
                          return _vm.pick(tag, val)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "dis-b paddingv10 fs-12",
                        domProps: { textContent: _vm._s(val) },
                      }),
                    ]
                  )
                }),
                0
              ),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "paddingb30 margint20 borderb" }, [
            _c("div", { staticClass: "fs-13 text-dark paddingb20" }, [
              _vm._v("数量"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-container left" },
              [
                _c("cmui-number", {
                  staticClass: "skuNumber",
                  staticStyle: { width: "105px" },
                  attrs: { max: _vm.currentMax, min: 1, value: _vm.quantity },
                  on: { input: _vm.changeNumber },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "fs-12 text-light marginl20",
                  domProps: { textContent: _vm._s(_vm.saleMaxText) },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.tax.amount
              ? _c(
                  "div",
                  {
                    staticClass: "fs-12 text-light lh-1 margint20",
                    on: { click: _vm.fold },
                  },
                  [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.taxText) },
                    }),
                    _vm._v(" "),
                    !(_vm.tax.type === 0 && _vm.tax.amount < 50)
                      ? _c("span", {
                          staticClass: "text-darker",
                          domProps: {
                            textContent: _vm._s("¥" + _vm.tax.amount),
                          },
                        })
                      : _c("span", { staticClass: "text-darker" }, [
                          _c("del", {
                            staticClass: "text-darker",
                            domProps: {
                              textContent: _vm._s("¥" + _vm.tax.amount),
                            },
                          }),
                          _vm._v("\r\n\t\t\t\t\t("),
                          _c("span", { staticClass: "text-green" }, [
                            _vm._v("关税≤50,免征收"),
                          ]),
                          _vm._v(")\r\n\t\t\t\t"),
                        ]),
                    _vm._v(" "),
                    !(_vm.tax.type === 0 && _vm.tax.amount < 50)
                      ? _c("i", {
                          staticClass: "fs-14",
                          class: !_vm.isFold ? "icon-unfold" : "icon-fold",
                          staticStyle: { "vertical-align": "middle" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.tax.type === 0 && _vm.tax.amount < 50)
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isFold,
                                expression: "isFold",
                              },
                            ],
                            staticClass: "text-darker padding20",
                            staticStyle: { background: "#ededed" },
                          },
                          [
                            _vm._v(
                              "此税费为预估金额，结算税费以提交订单时应付总额明细为准"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "bottom" }, slot: "bottom" }, [
        _vm.state === "findSimilar"
          ? _c("div", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.findSimilarUrl,
                    "data-scp": "skuSimilar.00",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-container center text-white fs-15 padding30",
                    },
                    [_vm._v("查看相似商品")]
                  ),
                ]
              ),
            ])
          : _c("div", [_vm._t("button")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }