var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { href: _vm.checkUrl, "data-scp": _vm.scp, "data-bk": _vm.bk },
      on: {
        click: function ($event) {
          return _vm.goApp($event)
        },
      },
    },
    [
      _c(
        "div",
        [
          _vm.isWeixin && _vm.isList
            ? _c(
                "wx-open-launch-weapp",
                {
                  attrs: {
                    username: _vm.webcast.userName,
                    path: _vm.webcast.list,
                  },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n\t\t\t\t<img style="' +
                        _vm._s(_vm.style) +
                        '"\n\t\t\t\t     width="' +
                        _vm._s(_vm.width) +
                        '"\n\t\t\t\t     height="' +
                        _vm._s(_vm.height) +
                        '"\n\t\t\t\t     src="' +
                        _vm._s(_vm.protocol) +
                        _vm._s(_vm.data.imageUrl) +
                        '" alt="">\n\t\t\t'
                    ),
                  ]),
                ]
              )
            : _vm.isWeixin && _vm.isRoom
            ? _c(
                "wx-open-launch-weapp",
                {
                  attrs: {
                    username: _vm.webcast.userName,
                    path: _vm.webcast.room + "?roomId=" + _vm.roomId,
                  },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n\t\t\t\t<img style="' +
                        _vm._s(_vm.style) +
                        '"\n\t\t\t\t     width="' +
                        _vm._s(_vm.width) +
                        '"\n\t\t\t\t     height="' +
                        _vm._s(_vm.height) +
                        '"\n\t\t\t\t     src="' +
                        _vm._s(_vm.protocol) +
                        _vm._s(_vm.data.imageUrl) +
                        '" alt="">\n\t\t\t'
                    ),
                  ]),
                ]
              )
            : _vm.isWeixin && _vm.isCoupon
            ? _c(
                "wx-open-launch-weapp",
                {
                  attrs: {
                    username: _vm.webcast.userName,
                    path: _vm.webcast.coupon + "?couponId=" + _vm.couponId,
                  },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n\t\t\t\t<img style="' +
                        _vm._s(_vm.style) +
                        '"\n\t\t\t\t     width="' +
                        _vm._s(_vm.width) +
                        '"\n\t\t\t\t     height="' +
                        _vm._s(_vm.height) +
                        '"\n\t\t\t\t     src="' +
                        _vm._s(_vm.protocol) +
                        _vm._s(_vm.data.imageUrl) +
                        '" alt="">\n\t\t\t'
                    ),
                  ]),
                ]
              )
            : _vm.isWeixin && _vm.isMaotai
            ? _c(
                "wx-open-launch-weapp",
                {
                  attrs: {
                    username: _vm.webcast.userName,
                    path: "" + _vm.webcast.maotai,
                  },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n\t\t\t\t<img style="' +
                        _vm._s(_vm.style) +
                        '"\n\t\t\t\t     width="' +
                        _vm._s(_vm.width) +
                        '"\n\t\t\t\t     height="' +
                        _vm._s(_vm.height) +
                        '"\n\t\t\t\t     src="' +
                        _vm._s(_vm.protocol) +
                        _vm._s(_vm.data.imageUrl) +
                        '" alt="">\n\t\t\t'
                    ),
                  ]),
                ]
              )
            : _vm.isWeixin && _vm.isApplets
            ? _c(
                "wx-open-launch-weapp",
                { attrs: { username: _vm.checkName, path: _vm.data.clickUrl } },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n\t\t\t\t<img style="' +
                        _vm._s(_vm.style) +
                        '"\n\t\t\t\t     width="' +
                        _vm._s(_vm.width) +
                        '"\n\t\t\t\t     height="' +
                        _vm._s(_vm.height) +
                        '"\n\t\t\t\t     src="' +
                        _vm._s(_vm.protocol) +
                        _vm._s(_vm.data.imageUrl) +
                        '" alt="">\n\t\t\t'
                    ),
                  ]),
                ]
              )
            : _c("img", {
                style: _vm.style,
                attrs: {
                  width: _vm.width,
                  height: _vm.height,
                  src: _vm.data.imageUrl,
                  alt: "",
                },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }