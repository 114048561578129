// tgou常用组件封装
import slider from './slider.vue';
Vue.component('tg-slider',slider);


import loading from './loading.vue';
Vue.component('tg-loading',loading);



import head from './head.vue';
Vue.component('tg-head',head);



import foot from './foot.vue';
Vue.component('tg-foot',foot);




import img from './img.vue';
Vue.component('tg-img',img);



import scrollTop from './scrollTop.vue';
Vue.component('tg-scroll-top',scrollTop);



import poster from './poster.vue';
Vue.component('tg-poster',poster);


import step from './step.vue';
Vue.component('tg-step',step);

import stationMail from './stationMail.vue';
Vue.component('station-mail',stationMail);

import couponPop from './couponPop.vue';
Vue.component('tg-coupon-pop',couponPop)
