<template>
    <a href="/index.html" data-scp="index.0">
        <i class="icon icon-index"></i><span>首页</span>
    </a>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped>

</style>