import Super from "./class";
let serverList={
    get:{
        attention:'/publics/member/counter/attention',
        memberInfo:'/publics/member/info',
        addressList:'/publics/memberAddress/list',
        createCard:'/publics/memberCard/createCard',
        noValidate:'/publics/memberCard/bind/noValidate'
        , baseInfo: '/publics/mine/baseInfo'

        , eDalianClientId: '/web/member/eDalian/clientId'

        /**
         * 2021-06-29
         * #50108   付费会员——频道页
         * /publics/member/vip/coupon/items => /web/member/vip/coupon/items
         * @deprecated
         * */
        , tgVipProduct: '/publics/member/vip/coupon/items'
        /**
         * 2021-06-29
         * #50108   付费会员——频道页
         * /publics/member/vip/info         => /web/member/vip/info
         * */
        , tgVipInfo: '/web/member/vip/info'
        ,
        /**
         * 获取upVip 详细信息
         * 2021-07-19
         * #50162
         */
        upVipInfo: '/web/member/vip/info',
    },
    post:{
        /**
         * 2021-06-28
         * #50115   付费会员——涉及到登录接口全部替换成新接口
         * /publics/login/wechatLogin => /web/member/wechatLogin
         * */
        wechatLogin:'/web/member/wechat-login',
        flagStoreSubscribed:'/publics/member/brand/queryMemberSubscribed',
        secKey:'/publics/app/secKey',
        recordArriveAddress: '/publics/memberAddress/recordArriveAddress',
        queryArriveAddress: '/publics/memberAddress/queryArriveAddress',
        queryAddressByStore: '/publics/memberAddress/arriveHome/storeId',
        storeToCard:'/publics/memberCard/storeToCard',
        bingCard:'/publics/memberCard/bind/noValidate',
        cardCreateAble:'/publics/memberCard/store/cardCreateAble',
        creatCard:'/publics/memberCard/createCard',
        addOrUpdate:'/publics/memberIdentity/special/addOrUpdate',
        queryByMemberId:'/publics/memberIdentity/special/queryByMemberId',
        queryAsteriskByMemberId:'/publics/memberIdentity/special/queryAsteriskByMemberId',
        addOrUpdateOffLine:'/publics/memberIdentity/special/addOrUpdate/offline',
        queryVIPById:'/publics/moutai/store/vip/queryById',
        vipBack:'/publics/moutai/vip/back'

        , eDalianLogin: '/web/member/eDalianLogin'
        , logout: '/web/member/logout' // 用户退出登录
    },
};
export default new Super(serverList,'mserv');
