var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.length === 1
        ? _c(
            "div",
            [
              _c("tg-wx-launch-poster", {
                attrs: {
                  data: _vm.data[0],
                  scp: _vm.scpC + ".0",
                  bk: _vm.data[0].bk,
                  "data-trace-id": "",
                },
              }),
            ],
            1
          )
        : _c(
            "cmui-slider",
            {
              staticClass: "tg-slider",
              attrs: {
                watch: _vm.data,
                page: true,
                loop: _vm.loop,
                options: _vm.options,
              },
              on: {
                "slide-change-transition-end": _vm.slideChangeTransitionEnd,
              },
            },
            _vm._l(_vm.data, function (item, index) {
              return _c("cmui-slider-item", { key: index }, [
                !item.videoUrl
                  ? _c(
                      "a",
                      {
                        class: "js-slider" + _vm.data[0].id,
                        attrs: {
                          href: item.clickUrl,
                          "data-scp": _vm.scpC + "." + index,
                          "data-bk": item.bk,
                          "data-real-index": index,
                        },
                      },
                      [
                        _c("cmui-img", {
                          staticClass: "radiusShadow",
                          attrs: {
                            src: item.imageUrl,
                            "pre-view-index": index,
                            "pre-view-list": _vm._selfList,
                            "pre-view": _vm.preview,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _vm.actTagImage &&
                        ((!_vm.data[0].videoUrl && index === 0) ||
                          (_vm.data[0].videoUrl && index === 1))
                          ? _c(
                              "div",
                              {
                                staticClass: "pos-a img-container",
                                staticStyle: {
                                  width: "auto",
                                  height: "auto",
                                  "z-index": "2",
                                  left: "0",
                                  right: "0",
                                  top: "0",
                                  bottom: "0",
                                },
                              },
                              [
                                _vm.actTagImage
                                  ? _c("img", {
                                      attrs: { src: _vm.actTagImage, alt: "" },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "ratio-container pos-r",
                        staticStyle: { background: "#000" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.emptyFunc.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("video", {
                          staticStyle: {
                            height: "100%",
                            width: "100%",
                            margin: "auto",
                          },
                          attrs: {
                            id: "video",
                            src: item.videoUrl,
                            poster: _vm.data[1].imageUrl,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.playVideo($event, index)
                            },
                            play: _vm.playingVideo,
                            pause: _vm.pauseVideo,
                            ended: _vm.endVideo,
                            error: _vm.errorVideo,
                          },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isPlayingVideo,
                              expression: "!isPlayingVideo",
                            },
                          ],
                          staticClass: "icon icon-discover_play text-center",
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            margin: "auto",
                            background: "#fff",
                            opacity: ".5",
                            "border-radius": "50%",
                            "font-size": "30px",
                            "padding-left": "10px",
                            "line-height": "80px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.playVideo($event, index)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.errorVideoLoad
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  height: "2em",
                                  color: "#fff",
                                  bottom: "50px",
                                  top: "auto",
                                },
                              },
                              [_vm._v("视频加载失败，请检查网络后重试")]
                            )
                          : _vm._e(),
                      ]
                    ),
              ])
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }