import domain from 'javascript/libs/tg/domain.js';
let setCookie = function(key,value,time){
    var opt = {path:'/',domain:domain.tgDomain};
    if(time){
        opt.expires = time;
    }
    return maple.cookie(key,value,opt);
};

let getCookie = function(key){
    return maple.cookie(key);
};

let removeCookie = function(key){
    var opt = {path:'/',domain:domain.tgDomain};
    return maple.cookie.remove(key,opt);
};

/**
 * 延迟渲染，全部渲染完成后返回一个promise，需要绑定vue对象 exp:lazyRander({vm,prop,data,count})
 * 如果count为一个数字组成的数组，那么会按照数组内个数分批渲染，比如输入【2，3】会第一次渲染两条，第二次渲染三条，第三次渲染其余的
 * @param props 需要设置的属性
 * @param data 渲染数据
 * @param count 每次渲染的数量
 * @returns {Promise<any>}
 */
function lazyRander({vm,prop,data,count=10}){
    if(!vm._isVue){
        return;
    }
    let keys=_.keys(data);
    let keyLen=keys.length;
    let rs=[];
    if(_.isNumber(count)){
        rs=[...Array(Math.ceil(keyLen/count))].map((item,index)=>{
            return Math.min((index+1)*count,keyLen);
        })
    }else if(_.isArray(count)&&_.every(count,_.isNumber)){
        for(let i=0,len=count.length;i<len;i++){
            let temp=count[i]+(_.last(rs)||0);
            if(temp<keyLen){
                rs.push(temp);
                if(i==len-1){
                    rs.push(keyLen);
                    break;
                }
            }else{
                rs.push(keyLen);
                break;
            }
        }
    }
    function rander(index=1,time=500){
        return new Promise(resolve => {
            if(rs[index]<=keyLen){
                let pick=_.pick(data,keys.slice(0,rs[index]));
                vm.$set(vm,prop,pick);
                vm.$nextTick(()=>{
                    _.delay(()=>{
                        rander(index+1,0).then(resolve);
                    },time?Math.max(time,rs[0]):0);
                });
            }else{
                resolve();
            }
        })
    }
    return rander();
}
function formatDate(date, format) {
  if (arguments.length < 2 && !date.getTime) {
    format = date;
    date = new Date();
  }
  typeof format !== 'string' && (format = 'YYYY年MM月DD日 hh时mm分ss秒');
  let week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', '日', '一', '二', '三', '四', '五', '六'];
  return format.replace(/YYYY|YY|MM|DD|hh|mm|ss|星期|周|www|week/g, function (a) {
    switch (a) {
      case 'YYYY':
        return date.getFullYear();
      case 'YY':
        return (date.getFullYear() + '').slice(2);
      case 'MM':
        return (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      case 'DD':
        return (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
      case 'hh':
        return (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
      case 'mm':
        return (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();
      case 'ss':
        return (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
      case '星期':
        return '星期' + week[date.getDay() + 7];
      case '周':
        return '周' + week[date.getDay() + 7];
      case 'week':
        return week[date.getDay()];
      case 'www':
        return week[date.getDay()].slice(0, 3);
    }
  });
};
export {setCookie,getCookie,removeCookie,lazyRander, formatDate};
