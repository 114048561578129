var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tgPoster-type67" },
    [
      _c(
        "cmui-list",
        { attrs: { border: true, col: [2, 3] } },
        [
          _c("cmui-list-item", [
            _c(
              "a",
              {
                attrs: {
                  "data-scp":
                    "ad_" + _vm.data.id + "_" + _vm.data.items[0].id + ".0",
                  "data-bk": _vm.data.items[0].bk,
                  href: _vm.data.items[0].clickUrl || _vm.nolink,
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.data.items[0].imageUrl,
                    alt: "",
                    width: "100%",
                    height: "100%",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "cmui-list-item",
            [
              _c(
                "cmui-list",
                { attrs: { border: true } },
                [
                  _c("cmui-list-item", [
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-scp":
                            "ad_" +
                            _vm.data.id +
                            "_" +
                            _vm.data.items[1].id +
                            ".1",
                          "data-bk": _vm.data.items[1].bk,
                          href: _vm.data.items[1].clickUrl || _vm.nolink,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.data.items[1].imageUrl,
                            alt: "",
                            width: "100%",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "cmui-list-item",
                    [
                      _c(
                        "cmui-list",
                        { attrs: { col: 2, border: true } },
                        [
                          _c("cmui-list-item", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  "data-scp":
                                    "ad_" +
                                    _vm.data.id +
                                    "_" +
                                    _vm.data.items[2].id +
                                    ".2",
                                  "data-bk": _vm.data.items[2].bk,
                                  href:
                                    _vm.data.items[2].clickUrl || _vm.nolink,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.data.items[2].imageUrl,
                                    alt: "",
                                    width: "100%",
                                    height: "100%",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("cmui-list-item", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  "data-scp":
                                    "ad_" +
                                    _vm.data.id +
                                    "_" +
                                    _vm.data.items[3].id +
                                    ".3",
                                  "data-bk": _vm.data.items[3].bk,
                                  href:
                                    _vm.data.items[3].clickUrl || _vm.nolink,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.data.items[3].imageUrl,
                                    alt: "",
                                    width: "100%",
                                    height: "100%",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }