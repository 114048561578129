var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isStationMail,
          expression: "isStationMail",
        },
      ],
      ref: "mailBox",
      staticClass: "mail-box fixed-bottom",
    },
    [
      _c(
        "a",
        {
          attrs: {
            href: _vm.clickUrl,
            "data-scp": "znx.0",
            "data-bk": "id-" + _vm.znxId,
            "data-trace-id": "",
          },
        },
        [
          _c("img", {
            staticStyle: {
              display: "inline-block",
              "vertical-align": "middle",
            },
            attrs: { src: _vm.iconImg, width: "24", height: "24", alt: "" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "fs-14 text-white text-bolder" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "fs-12", staticStyle: { color: "#e6e6e6" } },
            [_vm._v(_vm._s(_vm.subTitle))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mail-btn" }, [_vm._v("去看看")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mail-close",
          attrs: { "data-scp": "znx.1", "data-bk": "id-" + _vm.znxId },
          on: { click: _vm.closeMail },
        },
        [_c("div", { staticClass: "icon-close" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }