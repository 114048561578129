var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addressBanner.clickUrl
    ? _c(
        "a",
        {
          attrs: {
            "data-scp": "addr.select",
            href: _vm.addressBanner.clickUrl,
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex-container bg-white padding20 borderb" },
            [
              _c(
                "div",
                {
                  staticClass: "flex-container top left fs-11 flex1 text-dark",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "fs-13 text-dark lh-20 dis-i",
                      staticStyle: { "min-width": "26px" },
                    },
                    [_vm._v("送至")]
                  ),
                  _vm._v(" "),
                  _vm.addressBanner.hasAddress
                    ? _c(
                        "div",
                        { staticClass: "fs-13 text-darker marginl20 lh-20" },
                        [
                          _c("div", {
                            staticClass: "text-limit1",
                            domProps: { textContent: _vm._s(_vm.fullAddress) },
                          }),
                          _vm._v(" "),
                          _vm.addressBanner.canDelivery
                            ? _c("div", { staticClass: "fs-11" }, [
                                _vm.addressBanner.haveProduct
                                  ? _c("span", {
                                      staticClass: "text-red",
                                      domProps: {
                                        textContent: _vm._s(
                                          "现货" +
                                            (_vm.addressBanner.deliveryInfo
                                              ? "，"
                                              : "")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.addressBanner.haveProduct
                                  ? _c("span", {
                                      staticClass: "text-red",
                                      domProps: {
                                        textContent: _vm._s(
                                          "无货" +
                                            (_vm.addressBanner.deliveryInfo
                                              ? "，"
                                              : "")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "text-light",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.addressBanner.deliveryInfo
                                    ),
                                  },
                                }),
                              ])
                            : _c("div", { staticClass: "fs-11" }, [
                                _c("span", { staticClass: "text-red" }, [
                                  _vm._v("该地址不在该商品的配送范围内"),
                                ]),
                              ]),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "fs-13 text-darker marginl20 lh-20" },
                        [
                          _c("div", { staticClass: "text-limit1" }, [
                            _vm._v("请选择地址"),
                          ]),
                          _vm._v(" "),
                          _vm.addressBanner.canDelivery
                            ? _c("div", { staticClass: "fs-11" }, [
                                _vm.addressBanner.haveProduct
                                  ? _c("span", {
                                      staticClass: "text-red",
                                      domProps: {
                                        textContent: _vm._s(
                                          "现货" +
                                            (_vm.addressBanner.deliveryInfo
                                              ? "，"
                                              : "")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.addressBanner.haveProduct
                                  ? _c("span", {
                                      staticClass: "text-red",
                                      domProps: {
                                        textContent: _vm._s(
                                          "无货" +
                                            (_vm.addressBanner.deliveryInfo
                                              ? "，"
                                              : "")
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "text-light",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.addressBanner.deliveryInfo
                                    ),
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "lh-24 dis-i marginl10" }, [
      _c("span", { staticClass: "icon-right fs-11 text-light" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }