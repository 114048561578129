<template>
<!-- 送货地址 -->
<a data-scp="addr.select"
   :href="addressBanner.clickUrl"
   v-if="addressBanner.clickUrl">
	<div class="flex-container bg-white padding20 borderb">
		<div class="flex-container top left fs-11 flex1 text-dark">
			<span class="fs-13 text-dark lh-20 dis-i" style="min-width: 26px">送至</span>
			<div class="fs-13 text-darker marginl20 lh-20" v-if="addressBanner.hasAddress">
				<div class="text-limit1" v-text="fullAddress"></div>
				<div class="fs-11" v-if="addressBanner.canDelivery">
					<span class="text-red" v-if="addressBanner.haveProduct" v-text="'现货'+(addressBanner.deliveryInfo?'，':'')"></span>
					<span class="text-red" v-if="!addressBanner.haveProduct" v-text="'无货'+(addressBanner.deliveryInfo?'，':'')"></span>
					<span class="text-light" v-text="addressBanner.deliveryInfo"></span>
				</div>
				<div class="fs-11" v-else>
					<span class="text-red">该地址不在该商品的配送范围内</span>
				</div>
			</div>
			<div class="fs-13 text-darker marginl20 lh-20" v-else>
				<div class="text-limit1">请选择地址</div>
				<div class="fs-11" v-if="addressBanner.canDelivery">
					<span class="text-red" v-if="addressBanner.haveProduct" v-text="'现货'+(addressBanner.deliveryInfo?'，':'')"></span>
					<span class="text-red" v-if="!addressBanner.haveProduct" v-text="'无货'+(addressBanner.deliveryInfo?'，':'')"></span>
					<span class="text-light" v-text="addressBanner.deliveryInfo"></span>
				</div>
			</div>
		</div>
		<span class="lh-24 dis-i marginl10"><span class="icon-right fs-11 text-light"></span></span>
	</div>
</a>
</template>

<script>
export default {
	name: 'tg-address'
	, props: {
		addressBanner: {
			/**
			 * @example
			 * {
				"provinceName": "辽宁省",
				"cityName": "大连市",
				"districtName": "沙河口区",
				"street": "黑石礁(地铁站)",
				"cellPhone": "12312312312",
				"fullAddress": "对大多数",
				"buyerName": "xddd",
				"hasAddress": true,
				"login": true,
				"canDelivery": true,
				"haveProduct": true,
				"deliveryInfo": "16:00点前下单，预计3~36个工作日送达",
				"clickUrl": "//m.test.66buy.com.cn/mine/myAddress.html"
			}
			 * */
			type: Object
			, default: {}
		}
	}
	, computed: {
		fullAddress(){
			let addressBanner = this.addressBanner
				, rs = ''
				;

			if( addressBanner ){
				rs = (addressBanner.provinceName ? addressBanner.provinceName +' ' : '') +
					(addressBanner.cityName ? addressBanner.cityName +' ' : '') +
					(addressBanner.districtName ? addressBanner.districtName +' ' : '') +
					(addressBanner.street ? addressBanner.street +' ' : '') +
					(addressBanner.fullAddress || '');
			}

			return rs;
		}
	}
};
</script>