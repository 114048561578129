var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tgPoster-type4" }, [
    _c(
      "div",
      { staticClass: "list list-col2" },
      _vm._l(_vm.data.items, function (item, index) {
        return _c("div", { staticClass: "list-item" }, [
          _c(
            "a",
            {
              staticClass: "borderb-light",
              attrs: {
                href: item.clickUrl,
                "data-scp": "ad_" + _vm.data.id + "_" + item.id + ".0",
              },
            },
            [_c("img", { attrs: { src: item.imageUrl, alt: "" } })]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }