var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c("header", { staticClass: "tg-head flex-container bg-white" }, [
            _c(
              "div",
              {
                staticClass: "header_left paddingl10",
                staticStyle: { "min-width": "20%" },
              },
              _vm._l(_vm.leftItems, function (item, index) {
                return _c(item, { key: index, tag: "component" })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex1" },
              [
                !_vm.$slots.default
                  ? _c("div", {
                      staticClass: "fs-17 text-center text-limit1",
                      class: "text-" + _vm.titleColor,
                      domProps: { textContent: _vm._s(_vm.title) },
                      on: { click: _vm.titleClick },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default"),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "text-right header_right paddingr30 flex-container right",
                staticStyle: { "min-width": "20%" },
              },
              _vm._l(_vm.selfRightItems, function (item, index) {
                return _c(item, { key: index, tag: "component" })
              }),
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMenuList,
                expression: "showMenuList",
              },
            ],
            staticClass: "header_more",
          },
          [
            _c(
              "ul",
              {},
              _vm._l(_vm.selfMenuList, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "padding20 fs-15" },
                  [_c(item, { tag: "component" })],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }