'use strict';

import Super from "./class";

let serverList = {
	post: {
		list: '/applet/room/channel/list'
	}
};
export default new Super(serverList, 'live');
