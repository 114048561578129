var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { href: "javascript:void(0)", "data-scp": "back.0" },
      on: { click: _vm.goback },
    },
    [
      _c("i", { staticClass: "icon icon-back" }),
      _vm._v(" "),
      _c("span", [_vm._v("返回")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }