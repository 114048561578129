<template>
<div>
	<!-- 权益说明 -->
	<div class="marginv20 bg-white" v-show="introList.items.length" @click="introState=!introState" data-scp="clause.0">
		<div class="flex-container padding20 pos-r">
			<div class="fs-11 text-dark" v-html="introText"></div>
			<span class="lh-24 dis-i marginl10"><span class="icon-right fs-11 text-light"></span></span>
		</div>
	</div>
	<!-- 权益说明弹窗 -->
	<cmui-popup :visible.sync="introState" position="bottom" :stop-page-scroll="true">
		<div slot="top">
			<div class="text-dark text-center fs-15 padding20 pos-r">天狗权益</div>
			<i class="icon-wrong pos-a right20 top30 fs-12 text-light"
			   @click="introState = !introState"></i>
		</div>
		<div class="paddingh30" style="margin-bottom: -1px;">
			<div class="flex-container leftTop borderb paddingv30" v-for="item in introList.items">
		<span class="text-red fs-16" :class="item.imageUrl ? '' : 'icon-product_roundcheck'">
			<img v-if="item.imageUrl" :src="item.imageUrl" width="16" height="16" alt="" style="min-width: 16px;" class="lh-16"/>
		</span>
				<div class="marginl20 fs-13 text-darker">
					<div class="fs-14 text-darker" v-text="item.title"></div>
					<div class="fs-12 text-dark" v-text="item.subTitle"></div>
				</div>
			</div>
		</div>
		<div slot='bottom'>
			<div class="flex-container center bg-red text-white fs-15 bordert lh-24 padding20 margint40" @click="introState =! introState">确定</div>
		</div>
	</cmui-popup>
</div>
</template>

<script>
export default {
	name: 'intro'
	, props: {
		introList: {
			/**
			 * @example
			 * {
				"items": [
					{
						"title": "请于干燥阴凉处存放",
						"id": 23,
						"subTitle": "请于干燥阴凉处222存放",
						"imageUrl": "//test.img.tg-img.com/seller/201709/18/76D7B534-ABE5-4851-81B1-FA5349FC7529.jpg!s"
					}
				]
			}
			 * */
			type: Object
			, default: {
				items: []
			}
		}
	}
	, data(){
		return {
			introState: false
		}
	}
	, computed: {
		introText(){
			return this.introList.items.length ? this.introList.items.map(d=>d.title).join('&nbsp;&nbsp;•&nbsp;&nbsp;') : '';
		}
	}
};
</script>