/**
 * Created by chenqifeng on 2016/9/1.
 */

import url from '../util/url';
import {App} from './app_interface_for_js';

/**
 * 用于修改url参数
 * {key1:value1,key2:value2}
 */
App.updateUrlParams = function(obj){
    try{
        if(typeof obj === 'string'){
            obj = JSON.parse(obj);
        }
        var newUrl = url.updateParams(location.href,obj);
        history.replaceState(null,'app',newUrl);
    }catch(e){

    }
};

/**
 * 右上角menus事件
 */
App.headerMenuEvent = function(){};

/**
 * 百货首页更改门店
 */
App.headStoreEvent = function(){

};

/**
 * 我的关注变更状态
 */
App.changeState = function () {

};
/**
 * 右上角menus事件
 */
App.hideHeaderMenu = function(){
    try{
        var headerMenus = document.querySelectorAll("#moreHeaderMenus");
        for(var i = 0; i<headerMenus.length; i++){
            headerMenus[i].style.display = 'none';
        }
    }catch(e){

    }
};

App.showMsgRedPoint = function(){
    var dom =  document.getElementById('messageRedInfo');
    dom && (dom.style.display = 'block');
};
App.hideMsgRedPoint = function(){
    var dom =  document.getElementById('messageRedInfo');
    dom && (dom.style.display = 'none');
};
App.searchEvent = function(){

};

/**
 * 登录成功回调事件
 */
App.tokenCallback = function(){
};

/**
 * 登录成功回调事件
 */
App.gotoFittingShowWatch = function(){

};
/**
 * web的后退事假
 */
App.tgBackEvent = function(){

};

/**
 * web统计
 */
App.tgTrackPage = function(){

};

App.leftFling = function(){

};

App.rightFling= function(){
 
};

/**
 * 新版本 iOS webView 回调函数
 * */
App.nativeCallback = function(results){
	try{
	    let temp = JSON.parse( results );
	    App.prototype.nativeCallbackCache[temp.methodName] = temp.params;

	    let t = App.prototype.eventList[temp.methodName]
	        ;

	    if( t && t.length ){
		    let i = 0
			    , l = t.length
		        ;

		    for(; i < l; i++){
			    t[i]( temp.params );
		    }
	    }
    }
    catch(e){}
};
/**
 * 新版本 iOS webView 回调函数监听事件
 * */
App.on = function(methodName, callback){
	if( !(methodName in App.prototype.eventList) ){
		App.prototype.eventList[methodName] = [];
	}
	App.prototype.eventList[methodName].push( callback );
};

/**
 * 支付成功回调
 */
App.paySuccess = function(){

};

/**
 * 大转盘 app 分享回调，自己提供方法覆盖此方法
 * */
App.wheelShareSuccess = function(){

};

/**
 * 订单列表页弹起年份选择弹窗
 * */
App.openYearChoose = function(){

};

/**
 * app返回时触发该方法
 * */
App.tgPageShow = function(){

};


export default new App();