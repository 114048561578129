<template>
<cmui-popup :visible.sync="visible" position="bottom" :stop-page-scroll="true">
	<div slot='top'>
		<div class="flex-container left pos-r">
			<div class="pos-a left20 bg-white" style="top:-20px"
				 @click="imgClick">
				<img class="border" width="90" height="90"
					 :src="imgUrl">
			</div>
			<div class="marginl20 margint20" style="padding-left:100px">
				<div class="fs-12">
					<div class="text-red fs-15">¥<span class="fs-20" v-text="priceText"></span></div>
					<div class="text-light" v-text="remainText"></div>
<!--					<div class="text-light" v-text="`请选择 ${tags.join(' ')}`"></div>-->
					<div class="text-light">
						<span v-show="chooseTag || tags.length" v-text="chooseText"></span>
						<span class="marginr10" v-for="item in (chooseTag || tags)" v-text="item"></span>
					</div>
				</div>
			</div>
			<i class="icon-wrong pos-a right20 top30 fs-11 text-light" @click="visible=!visible"></i>
		</div>
	</div>
	<div class="padding20 sku" style="height:300px;">
		<div class="paddingb30 margint20 borderb" v-for="tag in tags">
			<div class="fs-13 text-dark" v-text="tag"></div>
			<div>
				<span class="badge radius badge-reverse marginr20 margint20" style="padding: 1px 10px;min-width: 60px;"
					  v-for="val in tagsValues[tag].values"
					  :class="choose[tag] && val===choose[tag]?
					  	'badge-red':
						(term && term[tag] && term[tag][val]?
							'badge-black':
							'badge-black disabled')"
					  @click="pick(tag, val)">
					<span class="dis-b paddingv10 fs-12" v-text="val"></span>
				</span>
			</div>
		</div>
<!--		<div class="paddingb30 margint20 borderb">-->
<!--			<div class="fs-13 text-dark paddingb20">颜色</div>-->
<!--			<div>-->
<!--				<span class="badge radius badge-reverse marginr20 badge-black" style="padding: 1px 10px;min-width: 60px;">-->
<!--					<span class="dis-b paddingv10 fs-12">白色</span>-->
<!--				</span>-->
<!--			</div>-->
<!--		</div>-->
		<div class="paddingb30 margint20 borderb">
			<div class="fs-13 text-dark paddingb20">数量</div>
			<div class="flex-container left">
				<cmui-number style="width:105px;" class="skuNumber"
						:max="currentMax"
						:min="1"
						:value="quantity"
						@input="changeNumber">
				</cmui-number>
				<div class="fs-12 text-light marginl20" v-text="saleMaxText"></div>
			</div>
<!--			<div class="fs-12 text-light lh-1 margint20">另付商品税费：<span class="text-darker">¥13.9</span></div>-->
			<div v-if="tax.amount" class="fs-12 text-light lh-1 margint20" @click="fold">
				<span v-text="taxText"></span>
				<span class="text-darker" v-if="!(tax.type === 0 && tax.amount < 50)" v-text="'¥'+ tax.amount"></span>
				<span class="text-darker" v-else>
					<del class="text-darker" v-text="'¥'+ tax.amount"></del>
					(<span class="text-green">关税≤50,免征收</span>)
				</span>
				<i class="fs-14"
				   style="vertical-align: middle;"
				   v-if="!(tax.type === 0 && tax.amount < 50)" :class="!isFold ? 'icon-unfold' : 'icon-fold'"></i>
				<div style="background: #ededed;"
					 class="text-darker padding20"
					 v-if="!(tax.type === 0 && tax.amount < 50)"
					 v-show="isFold">此税费为预估金额，结算税费以提交订单时应付总额明细为准</div>
			</div>
		</div>

	</div>
	<div slot='bottom'>
		<div v-if="state==='findSimilar'">
			<a :href="findSimilarUrl" data-scp="skuSimilar.00">
				<div class="flex-container center text-white fs-15 padding30">查看相似商品</div>
			</a>
		</div>
		<div v-else>
			<slot name="button"></slot>
		</div>
	</div>
</cmui-popup>
</template>

<script>
const MAX_PRICE_EFFECT_TAX_TYPE = [
		0		// 行邮税
		, 1		// 综合税
	]
	, SKU_ID = 'productSkuId'
	, REMAIN = 'remainQty'
	;

/**
 * @param	{Array}	items		sku 数组
 * @param	{Array}	tags		sku 标签数组
 * */
function count({items, tags}){
	return tags.reduce((all, d)=>{
		all[d] = items.reduce((a, t)=>{
			if( !(t.skuAttrMap[d] in a) ){
				a.values.push( t.skuAttrMap[d] );
				a[t.skuAttrMap[d]] = {
					ids: []
				};
			}

			a[t.skuAttrMap[d]][t[SKU_ID]] = t;
			a[t.skuAttrMap[d]].ids.push( t[SKU_ID] );

			return a;
		}, {values: []});

		return all;
	}, {});
}

export default {
	name: 'tg-sku-panel'
	, props: {
		visible: {	// 显示状态
			type: Boolean
			, default: false
		}
		, tax: {	// 税务信息
			type: Object
			// , default: null
			, default: {
				// rate: 0
				type: 1
				// , priceLimit: 5000
				, amount: 0
			}
		}
		, depot: {	// 同品多仓
			type: Boolean
			, default: false
		}
		, productInfo: {
			type: Object
			// , default: null
			, default: {
				storeId: ''
				, storeName: ''
				, productId: ''
				, activityId: ''
			}
		}
		, activityId: {
			type: String
			, default: ''
		}
		, sku: {
			type: Object
			, default: {
				items: []
			}
		}
		, mainImageUrl: {
			type: String
			, default: ''
		}
		, mainPrice: {
			type: String
			, default: ''
		}
		, limit: {	// 限购数量
			type: Number
			, default: Infinity
		}
	}
	, data(){
		return {
			tags: []
			, tagsValues: {}
			, choose: {}
			, chooseTag: null
			, term: {}
			, completed: true
			, quantity: 1
			, remain: Infinity
			, allRemain: Infinity
			// , limit: Infinity 	// 限购数量
			, limitOriginal: Infinity	// 本门店的限购数量
			, priceMax: 5000	// 最大购买金额
			, priceLimit: Infinity 	// 最大购买金额限购数量

			, productId: ''	//
			, storeId: ''
			, storeName: ''
			, price: 0
			, minPrice: 0
			, maxPrice: 0
			, minSkuPrice: 0
			, maxSkuPrice: 0

			, counterGroup: false	// 专柜团
			, showPriceKey: 'price'

			, taxRate: 0	// 税率
			, taxType: null	// 税类型

			, hasTax: false	// 是否有税率
			, bondedItem: false	// 是否为保税商品
			, groupItem: false	// 是否为组合品
			// , taxAmount: 0	// 税费

			, isFold: false	// 税费下的提示文字是否展开

			, state: ''
			, stateText: {
				offshelf: '商品已下架'
				, deleted: '商品已下架'
				, zero: '库存不足'
				, getData: '确认'
				, findSimilar: '查看相似商品'
			}
		}
	}
	, methods: {
		pick(tag, val){
			let items
				, temp
				, tempBig
				, choose
				, term
				, notBlank
				, completed
				, tempIndex = {}
				;

			// 处理选择条件
			if( !(tag in this.choose) || this.choose[tag] !== val ){	// 新加或改变条件

				if( !(val in this.term[tag]) ){	// 该选项不可选
					return;
				}
				else{
					if( !(tag in this.choose) ){	// 新加入条件
						choose = this.choose;
						choose.items = [];	// 清空已选项
						choose[tag] = val;
					}
					else{	// 改变已选项
						choose = this.tags.reduce((all, d)=>{

							if( d in this.choose && d !== tag ){
								all[d] = this.choose[d];
							}

							return all;
						}, {items: []});

						choose[tag] = val;
					}
				}
			}
			else{	// 删除已选条件
				if( this.tagsValues[tag].values.length === 1 ){	// 该条件不是唯一
					return;
				}

				choose = this.tags.reduce((all, d)=>{
					if( d !== tag && d in this.choose ){
						all[d] = this.choose[d];
					}

					return all;
				}, {items: []});
			}

			// 当前所选是否为空
			notBlank = this.tags.some((d)=>{
				return d in choose;
			});

			// 是否每一个类型都被选择了
			completed = this.tags.every((d)=>{
				return d in choose;
			});

			if( notBlank ){
				/**
				 * 统计可选择 sku 数据项
				 * */

				// 统计符合 choose 条件的选项
				items = this.tags.reduce((all, d)=>{
					if( d in choose ){
						temp = this.tagsValues[d][choose[d]];

						temp.ids.forEach((id)=>{

							if( !(id in tempIndex) ){
								let rs = this.tags.reduce((is, t)=>{
									if( t in choose && t !== d ){
										is = is && (temp[id].skuAttrMap[t] === choose[t]);	// 过滤掉库存为 0 的 sku
									}

									return is;
								}, true);

								if( rs ){
									all.push( temp[id] );
									tempIndex[id] = 1;
								}
							}
						});
					}

					return all;
				}, []);
				choose.items = items;

				// 统计符合 choose -1 条件的选项
				items = items.concat( this.tags.reduce((all, d)=>{
					if( d in choose ){
						tempBig = this.tagsValues[d];

						tempBig.values.forEach((v)=>{
							if( v !== choose[d] ){
								temp = this.tagsValues[d][v];

								temp.ids.forEach((id)=>{
									if( !(id in tempIndex) ){
										let rs = this.tags.reduce((is, t)=>{
											if( (t in choose) && (t !== d) ){
												is = is && (temp[id].skuAttrMap[t] === choose[t]);
											}
											else if( !(t in choose) ){
												is = is && items.some((p)=>{
													return p.skuAttrMap[t] === temp[id].skuAttrMap[t];
												});
											}

											return is;
										}, true);

										if( rs ){
											all.push( temp[id] );
											tempIndex[id] = 1;
										}
									}
								});
							}
						});
					}

					return all;
				}, []) );
			}
			else{
				items = this.sku.items;

				choose.items = items;
			}

			// 统计符合已选中条件数据的所有值
			term = count({
				items
				, tags: this.tags
			});

			this.term = term;
			this.choose = JSON.parse( JSON.stringify(choose) );

			this.completed = completed;

			this.quantity = 1;

			this.skuChange();
		}
		, changeNumber(num){
			// todo 税率
			this.quantity = num;

			this.skuChange();
		}
		, skuChange(){
			if( this.completed && this.choose.items && this.choose.items.length === 1 ){
				this.remain = this.choose.items[0][REMAIN] === -1 ? Infinity : this.choose.items[0][REMAIN];

				this.chooseTag = this.tags.reduce((all, d)=>{
					all.push( '"'+ this.choose.items[0].skuAttrMap[d] +'"' );

					return all;
				}, []);

				this.$emit('sku-change', {
					tags: this.tags
					, skuId: this.choose.items[0][SKU_ID]
					, skuNum: this.quantity
					, skuItem: this.choose.items[0]
					, choose: this.choose
					, completed: this.completed
				});
			}
			else{
				this.remain = this.allRemain;

				let i = 0
					;

				this.chooseTag = this.tags.reduce((all, d)=>{
					if( d in this.choose ){
						i++;
						all.push( '"'+ this.choose[d] +'"' );
					}
					else{
						all.push( d );
					}

					return all;
				}, []);

				if( i === 0 ){
					this.chooseTag = null
				}

				this.$emit('sku-change', {
					tags: this.tags
					, skuNum: this.quantity
					, choose: this.choose
				});
			}
		}
		, fold(){
			this.isFold = !this.isFold;
		}
		, imgClick(){
			this.$emit('img-click');
		}
	}
	, computed: {
		imgUrl(){
			if( this.completed && this.choose.items && this.choose.items.length === 1 ){
				return this.choose.items[0].imageUrl || this.mainImageUrl
			}
			return this.mainImageUrl;
		}
		, priceText(){
			if( this.mainPrice ){
				return this.mainPrice;
			}

			if( this.price ){
				return this.price;
			}

			if( this.showPriceKey === 'skuPrice' ){	// 专柜团加入购物车显示原价
				return this.minSkuPrice +(this.maxSkuPrice ? ('-'+ this.maxSkuPrice) : '');
			}
			return this.minPrice +(this.maxPrice ? ('-'+ this.maxPrice) : '');
		}
		, remainText(){
			if( this.state === '' || this.state === 'findSimilar' ){
				return this.remain === -1 ? '库存充足' : this.remain ? (this.remain > 49 ? '库存充足' : '库存'+ this.remain +'件') : '库存不足';
			}
			else{
				// return this.btnText[this.state];
			}
		}
		, saleMaxText(){
			let rs = ''
				;

			if( (this.limit && this.limit !== Infinity) && this.choose && this.choose.items && this.choose.items.length ){
				rs = '当前限购'+ this.limit +'件'
			}

			return rs;
		}
		, currentMax(){
			return Math.min(this.remain, this.limit, this.priceLimit);
		}
		, taxText(){
			if( this.tax.type !== null ){
				if( MAX_PRICE_EFFECT_TAX_TYPE.indexOf( this.tax.type ) !== -1 ){
					return '另付商品税费';
				}
				else{
					return '';
				}
			}
			else{
				return '';
			}
		}
		// , taxCount(){
		// 	let rs = 0
		// 		;
		//
		// 	if( this.tax.amount ){
		// 		return this.tax.amount;
		// 	}
		// 	// if( this.tax ){
		// 	// 	rs = Math.ceil(this.tax * (this.price || this.minPrice) * this.skuNum * 100) /100;
		// 	//
		// 	// 	if( !this.price && this.maxPrice ){
		// 	// 		rs += '-'+ Math.ceil(this.tax * this.maxPrice * this.skuNum * 100) /100;
		// 	// 	}
		// 	//
		// 	// 	// if( this.taxType === 0 ){
		// 	// 	// 	if( rs < 50 ){
		// 	// 	// 		rs = '<del>¥'+ rs +'</del>';
		// 	// 	// 	}
		// 	// 	//
		// 	// 	// 	rs += '(<span class="text-green">关税≤50,免征收</span>)';
		// 	// 	// }
		// 	// 	// else{
		// 	// 	// 	rs = '¥'+ rs;
		// 	// 	// }
		// 	// }
		//
		// 	return rs;
		// }
		, chooseText(){
			return !this.chooseTag ? '请选择' : (this.tags.length ? '已选' : '');
		}
	}
	, watch: {
		visible(v){
			// 手动触发属性值变更
			this.$emit('update:visible', v);
		}
		, price(value){
			if( this.priceMax !== Infinity && MAX_PRICE_EFFECT_TAX_TYPE.indexOf(this.tax.type) !== -1 ){
				if( value ){
					this.priceLimit = Math.floor( this.priceMax / value ) || 1;
				}
				else{
					this.priceLimit = Math.floor( this.priceMax / (this.maxPrice || this.minPrice) ) || 1;
				}
			}
		}
		, productInfo: {
			handler(value){
				let limit = value.limitQty
					;

				if( !limit || limit === -1 ){
					limit = Infinity
				}

				// this.limit = limit;
				this.limitOriginal = limit;

				this.productId = value.productId;
				this.storeId = value.storeId;
				this.storeName = value.storeName;

				this.price = value.minPrice || value.price;
				this.minPrice = value.minPrice;
				this.maxPrice = value.maxPrice;
				this.minSkuPrice = value.minSkuPrice;
				this.maxSkuPrice = value.maxSkuPrice;

				this.remain = value.remainQty || this.remain;
				this.allRemain = value.remainQty || this.allRemain;

				// if( 'taxRate' in value ){
				// 	this.taxRate = value.taxRate;
				// 	this.taxType = value.taxType;
				// 	this.priceMax = 5000;	// 限购价格
				// }
				// if( value.hasTax ){
				// 	this.hasTax = value.hasTax;
				// 	this.bondedItem = value.bondedItem;
				// 	this.groupItem = value.groupItem;
				// }
				if( 'counterGroup' in value ){
					this.counterGroup = value.counterGroup;
				}
			}
			, immediate: true
		}
// 		, tax: {
// 			handler(value){
//
// console.log(value)
// 			}
// 			, immediate: true
// 		}
		, sku: {
			handler(value){
				let items
					, tags = []
					, tagsValues = {}
					, choose = {}
					, term = {}
					, completed = true
					;

				if( value && value.items && value.items.length ){
					items = value.items;

					tags = 'skuAttrMap' in items[0] ? Object.keys( items[0].skuAttrMap ) : [];
					tagsValues = count({
						items
						, tags
					});

					// 数据只有一项时默认选中
					tags.forEach(d=>{
						if( tagsValues[d].values.length === 1 ){
							choose[d] = tagsValues[d].values[0];
						}
					});

					// 过滤符合已选中条件的数据
					items = items.filter(d=>{
						let rs = tags.reduce((is, t)=>{
							if( t in choose ){
								is = is && (d.skuAttrMap[t] === choose[t]);
							}

							return is;
						}, true);

						if( rs ){
							if( !choose.items ){
								choose.items = [];
							}
							choose.items.push( d );
						}

						return rs;
					});

					term = count({
						items
						, tags
					});

					completed = this.tags.reduce((is, d)=>{
						return is && (d in choose);
					}, completed);
				}

				this.remain = value.remainQty || this.remain;
				this.allRemain = value.remainQty || this.allRemain;

				this.tags = tags;
				this.tagsValues = tagsValues;
				this.choose = choose;
				this.term = term;
				this.quantity = 1;
				this.completed = completed;

				this.skuChange();
			}
			, immediate: true
		}
		, mainPrice(){

		}
	}
};
</script>

<style>
.sku .badge.badge-reverse.badge-black {
	border: 1px solid #999;
	color: #222;
}
.sku .badge.badge-reverse.badge-black.disabled{
	border-color: #ccc;
	color: #ccc;
}
</style>