<!--双十一推送券-->
<template>
    <div class="fixed-bottom mask-coupon-pop" v-show="isCouponPop" >
        <div class="bg-coupon-pop pos-a" id="popBox">
            <img src="//image1.51tiangou.com/tgou2/img2/coupon/pop-yanhua.gif"
                 class="pos-a" width="125" alt="" style="left: 49px;top:-60px">
            <img src="//image1.51tiangou.com/tgou2/img2/coupon/pop-yanhua.gif"
                 class="pos-a" width="125" alt="" style="right: 49px;transform: rotate(180deg);top:-60px">
            <img :src="titleImg" alt="" class="dis-b" style="width: 5.3rem;margin-top: 1.4rem">
            <div class="item-coupon flex-container left">
                <!-- 金额显示 -->
                <div class="text-center" style="width: 90px">
                    <span style="font-size:32px">
                        <span class="fs-20 lh-1" v-if="couponItem.subTitle.symbol">{{couponItem.subTitle.symbol}}</span>
                        <span class="lh-1">{{couponItem.subTitle.main}}</span><span class="fs-12 lh-1"
                                                                                    v-if="couponItem.subTitle.main">{{couponItem.subTitle.desc}}</span>
                    </span>
                    <!-- 限制条件 -->
                    <div class="">
                        <div class="fs-12" v-show="couponItem.limitStr">{{couponItem.limitStr}}</div>
                        <div class="fs-11" v-show="couponItem.max">{{couponItem.max}}</div>
                    </div>
                </div>
                <div>
                    <div class="fs-16" v-text="getTypeName(couponItem.type)"></div>
                    <div class="fs-12" v-text="couponItem.time"></div>
                </div>
            </div>
            <p class="fs-12 text-center" style="color: #ff3f8e;line-height: 32px">在“我的”-“优惠券”中查看</p>
            <div class="btn-receive text-white text-center"
                 data-scp="qsy.0" data-trace-id="" :data-bk="`id-${couponId}`"
                 @click="receiveCoupon">领取去使用</div>
        </div>
    </div>
</template>

<script>

import timeUtil from "javascript/libs/tg/util/time";
import couponService from 'spa/lib/api/coupon.js';
import domain from 'javascript/libs/tg/domain.js';
// 券类型
const COUPON_TYPE = {
    CASH: 1,         //现金券
    DISCOUNT: 2,     //折扣券
    REDUCE: 3,       //满减券
    GIFT: 4,         //买送券
    PARK: 8,         //停车券
    FRESH: 9,        //礼品券（保鲜礼）
    NEW_CASH: 10,    //抵值券
    NEW_DISCOUNT: 11, //新折扣券
    NEW_GIFT: 14,     //新礼品券
    Shipping: 15,      //运费券
    Gold: 16,          //黄金克减券
    Ship_REDUCE: 17,    //运费满减
}

export default {
    props: {
        page: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            isCouponPop: false,
            titleImg: '//image1.51tiangou.com/tgou2/img2/coupon/default-title.png',
            couponItem: {
                subTitle: {},
                type: 0,
                time: ''
            },
            couponId: 0
        }
    },
    methods: {
        requestCoupon() {
            couponService.sendDetail({
                data: {
                    redPacketId: this.redPacketId
                },
            }).then((data)=>{
                if (data && data.coupon) {
                    // 处理优惠券信息
                    this.handleCoupon(data.coupon);
                    // 标题广告位
                    couponService.advModule({
                        data: {
                            pageType: 145
                        },
                    }).then(advData => {
                        if( advData.length ){
                            let item = (advData.filter(function(i){
                                return i.id === 592;
                            }) || [])[0] || {};
                            if (item.positionList) {
                                this.titleImg = domain.getImageUrl(item.positionList[0].imageUrl);
                            }
                        }
                    });
                }
            });
        },
        handleCoupon(coupon) {
            this.couponId = coupon.id;
            let promotionRule = coupon.promotionRule && JSON.parse( coupon.promotionRule );
            if( coupon.type === COUPON_TYPE.CASH || coupon.type === COUPON_TYPE.Shipping ){
                // 现金券 和 运费券
                this.couponItem.subTitle = {
                    symbol: '¥',
                    main: promotionRule.cash + 0
                };
            }
            else if( coupon.type === COUPON_TYPE.DISCOUNT || coupon.type === COUPON_TYPE.NEW_DISCOUNT ){
                // 折扣券
                this.couponItem.subTitle = {
                    main: promotionRule.discount + 0
                    , desc: '折'
                };
            }
            else if( coupon.type === COUPON_TYPE.REDUCE || coupon.type === COUPON_TYPE.NEW_CASH  || coupon.type === COUPON_TYPE.Ship_REDUCE ){
                // 满减券 或 抵值券 和 运费满减券
                this.couponItem.subTitle = {
                    main: promotionRule.minus + 0
                    , desc: '元'
                };
            }
            else if( coupon.type === COUPON_TYPE.GIFT ){
                // 满送券
                this.couponItem.subTitle = {
                    main: '赠品'
                };
            }
            else if(coupon.type === COUPON_TYPE.NEW_GIFT){
                // 礼品券
                this.couponItem.subTitle = {
                    main: '礼品'
                };
            }
            else if( coupon.type === COUPON_TYPE.PARK ){
                this.couponItem.subTitle = {
                    main: promotionRule.cash + 0
                    , desc: '元'
                };
            }
            else if( coupon.type === COUPON_TYPE.Gold ){
                this.couponItem.subTitle = {
                    symbol: '¥'
                    , main: promotionRule.everyDiscount + 0
                };
            }
            this.couponItem.type = coupon.type;
            let startTime = coupon.couponToActivity.publishTime;
            let endTime = coupon.couponToActivity.offTime;
            this.couponItem.time = timeUtil.formatDate(new Date(startTime), 'YYYY.MM.DD') + '-' + timeUtil.formatDate(new Date(endTime), 'YYYY.MM.DD');
            this.showPop();
        },
        showPop() {
            this.isCouponPop = true;
            document.getElementById('popBox').classList.add('pop-arise');
            setTimeout(() => {
                this.closePop();
            }, 8000);
        },
        closePop() {
            if (!this.isCouponPop) {
                return;
            }
            document.getElementById('popBox').classList.add('pop-away');
            setTimeout(() => {
                this.isCouponPop = false;
            }, 1500)
        },
        getTypeName(item) {
            const TAG_NAME_LIST = {
                '1': '现金券',
                '2': '折扣券',
                '3': '满减券',
                '4': '买送券',
                '5': '单品券',//已废弃
                '6': '单品券',//已废弃
                '7': '信息券',//已废弃
                '8': '停车券',
                '9': '礼品券',//保鲜礼
                '10': '抵值券',
                '11': '打折券',
                '14': '礼品券',
                '15': '运费券',
                '16': '克减券',//黄金克减
                '17': '运费券'//运费满减
            };
            return TAG_NAME_LIST[item]
        },
        receiveCoupon() {
            couponService.send({
                data: {couponId: this.couponId},
            }).then(data => {
                tg.changePage('../search/searchResult.html', { "type": "coupon", "couponId": this.couponId });
            }).catch(e => {
                maple.notice(e.message)
            });
        }
    }
};
</script>
<style lang="scss">
.mask-coupon-pop {
    height: 45%;
    width: 100%;
    z-index: 1;

    .bg-coupon-pop {
        padding: 12px;
        width: 100%;
        height: 7.25rem;
        background: url("//image1.51tiangou.com/tgou2/img2/coupon/bg-coupon-pop.png") no-repeat center;
        background-size: 100% 100%;
    }
    .pop-arise {
        animation: popUp 1500ms;
        bottom: 50px;
    }
    .pop-away {
        animation: popDown 1500ms;
        bottom: -1.73rem;
    }
    .item-coupon {
        margin-top: 12px;
        width: 100%;
        height: 1.73rem;
        background: url("//image1.51tiangou.com/tgou2/img2/coupon/bg-coupon-item.png") no-repeat center;
        background-size: 100% 100%;
        color: #ff006a;
    }
    .btn-receive {
        width: 100%;
        height: 1.17rem;
        line-height: 1.17rem;
        background: url("//image1.51tiangou.com/tgou2/img2/coupon/btn-coupon-pop.png") no-repeat center;
        background-size: 100% 100%;
    }
}

@keyframes popUp {
    0%   {bottom: -7.25rem;}
    100% {bottom: 50px;}
}

@keyframes popDown {
    0%   {bottom: 50px;}
    100% {bottom: -7.25rem;}
}
</style>