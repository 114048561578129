/**
 * Created by chenqifeng on 2016/8/30.
 */

var parseUrl = function(url){
    if(Object.prototype.toString.call(url) === '[object Object]'){
        return url;
    }
    var urlParseRE = /^\s*(((([^:\/#\?]+:)?(?:(\/\/)((?:(([^:@\/#\?]+)(?:\:([^:@\/#\?]+))?)@)?(([^:\/#\?\]\[]+|\[[^\/\]@#?]+\])(?:\:([0-9]+))?))?)?)?((\/?(?:[^\/\?#]+\/+)*)([^\?#]*)))?(\?[^#]+)?)(#.*)?/,
        matches = urlParseRE.exec( url || "" ) || [];
    return {
        href:         matches[  0 ] || "",
        hrefNoHash:   matches[  1 ] || "",
        hrefNoSearch: matches[  2 ] || "",
        domain:       matches[  3 ] || "",
        protocol:     matches[  4 ] || "",
        doubleSlash:  matches[  5 ] || "",
        authority:    matches[  6 ] || "",
        username:     matches[  8 ] || "",
        password:     matches[  9 ] || "",
        host:         matches[ 10 ] || "",
        hostname:     matches[ 11 ] || "",
        port:         matches[ 12 ] || "",
        pathname:     matches[ 13 ] || "",
        directory:    matches[ 14 ] || "",
        filename:     matches[ 15 ] || "",
        search:       matches[ 16 ] || "",
        hash:         matches[ 17 ] || ""
    };
};

var Url = function(){
};

/**
 *FIXME: 这里可能将部分没有参数值的干掉
 *如 http://m.51tiangou.com/index.html?weixinCD 调用 $.that.addParams(location.href,{first:true})
 *之后变成： http://m.51tiangou.com/index.html?first=true
 */
Url.prototype.addParams = function(href,params,overlap){
    if(!href || !params ||$.isEmptyObject(params)){
        return href;
    }

    var hashArr = href.match(/#.*$/),
        hash = hashArr && hashArr.length && hashArr[0],
        paramArr = href.replace(/#.*$/,'').match(/\?.*$/),
        param = paramArr && paramArr.length && paramArr[0],
        url = href.split('#')[0].split('?')[0],
        data = $.extend({},this._getUrlData(param,true),params);
    for(var i in data){
        if(data[i] === undefined || data[i] === null){
            continue;
        }
        if(!/\?/.test(url)){
            url = url + '?' + encodeURIComponent(i) + '=' + encodeURIComponent(data[i]);
        }else{
            url = url + '&' + encodeURIComponent(i) + '=' + encodeURIComponent(data[i]);
        }

    }
    return url + (hash ? hash : '');
};
Url.prototype.removeParams = function(href){
    if(!href || !/\?/.test(href)){
        return href;
    }
    var hash = href.split('#'),
        url = hash[0],
        uri = url.split('?')[0],
        params = url.split('?')[1]?url.split('?')[1].split('&'):[],
        length = arguments.length,
        i;
    for(var j = 1; j<length; j++){
        for(i = 0; i<params.length; i++){
            if(params[i].indexOf(arguments[j]+'=') === 0){
                params.splice(i,1);
                i = i - 1;
            }
        }
    }
    return uri + (params.length ? ('?'+ params.join('&')) :'') + (hash[1] ? ('#' + hash[1]) : '');
};

Url.prototype._getUrlData = function(paramStr,flg,isList){
    var param = {},
        cutStr = '&',
        array,
        val,
        length,
        temp;
    if(!paramStr){
        return {};
    }
    paramStr.replace(/^\?/,'').split(cutStr).forEach(function(i,k){
        // array = i.split('=');

        array = /([^=]+)(?:=(.*))?/.exec( i );

        if( array ){
            array = array.slice(1);
        }
        else{
            return ;
        }

        length = array.length;
        //json字符串
        if(/^json/.test(array[0])){
            param[decodeURIComponent(array[0])] = JSON.parse(decodeURIComponent(array[1]));
        }else{
            var key = array[0]
            if(!flg){
                key = key.replace(/^cache_/,'');
            }

            key = decodeURIComponent(key);
            val = decodeURIComponent(array[1]);
            if(val !== 'null' && val !== 'undefined'){
                if(!isList){
                    param[key] = decodeURIComponent(array[1]);
                }else{
                    if(key in param){
                        temp = param[key];
                        if(Array.isArray(temp)){
                            param[key].push(decodeURIComponent(array[1]))
                        }else{
                            param[key] = [temp,decodeURIComponent(array[1])];
                        }
                    }else{
                        param[key] = decodeURIComponent(array[1]);
                    }
                }
            }
            //删除微信下的?10000skip
            if(param[key] && typeof param[key]==='string'){
                param[key]=param[key].replace(/\?10000skip(=true)?/,'')
            }
        }
    });
    return param;
};

Url.prototype.updateParams = function(href,obj){
    obj = obj || {};
    var params = this.getUrlParams(true),
        item = $.extend({},params,obj),
        url = parseUrl(href).hrefNoSearch;
    return this.addParams(url,item);
};


Url.prototype.getUrlParams = function(flg,isList){
    var queryString = window.location.search;
    if(!queryString){
        return {};
    }
    return this._getUrlData(queryString.slice(1, queryString.length),flg,isList);
};

export default new Url();