<!--站内信-->
<template>
    <div class="mail-box fixed-bottom" ref="mailBox" v-show="isStationMail">
        <a :href="clickUrl" data-scp="znx.0" :data-bk="`id-${znxId}`" data-trace-id="">
            <img :src="iconImg" width="24" height="24" style="display: inline-block; vertical-align: middle;" alt=""/>
            <span class="fs-14 text-white text-bolder">{{title}}</span>
            <span class="fs-12" style="color: #e6e6e6">{{subTitle}}</span>
            <div class="mail-btn">去看看</div>
        </a>
        <div class="mail-close" @click="closeMail" data-scp="znx.1" :data-bk="`id-${znxId}`">
            <div class="icon-close"></div>
        </div>
    </div>
</template>

<script>
import timeUtil from "javascript/libs/tg/util/time";
import pushserv  from 'spa/lib/api/pushserv.js';
import domain from 'javascript/libs/tg/domain.js';

export default {
    name: "stationMail",
    props: {
        page: {
            type: String,
            default: ''
        },
        foot: {
            type: Boolean,
            default: true
        },
        code: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isStationMail: false,
            iconImg: '',
            clickUrl: '',
            title: '',
            subTitle: '',
            znxId:''
        }
    },
    methods: {
        requestMail() {
            let znxArr = JSON.parse(localStorage.getItem('znxArr')) || [];
            const today = timeUtil.formatDate(new Date(), 'YYYY-MM-DD');
            // 该页当天是否展示过站内信
            let curPageItem = znxArr.filter((item) => {
                return item.page === this.page;
            })
            if (curPageItem && curPageItem.length) {
                let curDateItem = curPageItem.filter((item) => {
                    return item.date === today;
                })
                if (curDateItem && curDateItem.length) {
                    return;
                }
            }

            // znxArr中去掉每日展示一次且日期非当日
            znxArr = znxArr.filter((item) => {
                return !(item.showType === 2 && item.date !== today);
            })
            // localStorage中已展示的站内信id
            let localZnxIdArr = [];
            znxArr.map((item) => {
                localZnxIdArr.push(item.id);
            })
            pushserv.stationMail({
                data: {}
            }).then((data)=>{
                if (!data || !data.length) {
                    return;
                }
                let dataList = JSON.parse(JSON.stringify(data));
                // dataList中排除已展示
                dataList = dataList.filter((item) => {
                    return !localZnxIdArr.includes(item.id);
                });

                if (!dataList || !dataList.length) {
                    return;
                }

                this.iconImg = domain.getImageUrl(dataList[0].imageUrl);
                this.clickUrl = dataList[0].linkUrl;
                this.title = dataList[0].title;
                this.subTitle = dataList[0].subTitle;
                this.znxId = dataList[0].id;
                this.showMail();
                let obj = {
                    id: dataList[0].id,
                    showType: dataList[0].showType,
                    page: this.page,
                    date: today
                }
                znxArr.push(obj);
                localStorage.setItem('znxArr', JSON.stringify(znxArr));
            });
        },
        showMail() {
            this.isStationMail = true;
            this.$refs.mailBox.classList.value += ' mail-arise';
            setTimeout(() => {
                this.closeMail();
            }, 10000);
        },
        closeMail() {
            if (!this.isStationMail || !this.$refs.mailBox) {
                return;
            }
            this.$refs.mailBox.classList.value += ' mail-away';
            setTimeout(() => {
                this.isStationMail = false;
            }, 1500)
        }
    }
};
</script>
<style>
.mail-box {
    height: 40px;
    background: rgba(36, 36, 36, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    z-index: 1;
}
.mail-box a {
    display: block;
    padding: 0 12px;
    width: 100%;
    height: 100%;
}
.mail-box span {
    line-height: 40px;
}
.mail-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    color: #fff;
    background: url("//image1.51tiangou.com/tgou2/img2/index/bg-close.png") no-repeat;
}
.mail-btn {
    position: absolute;
    right: 12px;
    top: 0;
    margin-top: 8px;
    width: 56px;
    height: 24px;
    color: #fff;
    background: #FF2238;
    border-radius: 28px;
    line-height: 24px;
    text-align: center;
}
.mail-arise {
    animation: mailUp 1500ms;
    bottom: 74px;
}
@keyframes mailUp {
    0%   {bottom: 0;}
    100% {bottom: 74px;}
}
.mail-away {
    animation: mailDown 1500ms;
    bottom: 0;
}
@keyframes mailDown {
    0%   {bottom: 74px;}
    100% {bottom: 0;}
}
</style>