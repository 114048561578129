<template>
    <div>
        <header class="tg-head flex-container bg-white" v-if="show">
            <div class="header_left paddingl10" style="min-width: 20%;">
                <component :is="item" :key="index" v-for="(item,index) in leftItems"></component>
            </div>
            <div class="flex1">
                <div class="fs-17 text-center text-limit1"
                      :class=' "text-" + titleColor'
                      @click="titleClick"
                      v-if="!$slots.default"
                      v-text="title"
                ></div>
                <slot></slot>
            </div>
            <div class="text-right header_right paddingr30 flex-container right" style="min-width: 20%">
                <component :is="item" :key="index" v-for="(item,index) in selfRightItems"></component>
            </div>
        </header>
        <transition name="fade">
            <div class="header_more" v-show="showMenuList">
                <ul class="">
                    <li class="padding20 fs-15" :key="index" v-for="(item,index) in selfMenuList">
                        <component :is="item"></component>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
    import * as headItem from './headItem/index.js';
    import app from 'javascript/libs/tg/app/index.js';
    let hideMenuList=_.debounce(function(){
        this.showMenuList=false;
    },100);
    export default {
        name: "head",
        components:headItem,
        data(){
            return {
                show:!tg.getCookie('hybrid'),
                showMenuList:false
            }
        },
        watch:{
            title:{
                immediate:true,
                handler(value){
                    document.title=value;
                    maple.cookie('hybrid') && app.setTgTitle(value)
                }
            }
        },
        created(){
            let _this=this;
            app.constructor.headerMenuEvent=function(){
                _this.showMenuList=!_this.showMenuList
            };
            app.hideAppMenus();
            $(document).on('click',hideMenuList.bind(_this));
            $(document).on('scroll',hideMenuList.bind(_this));
        },
        destroyed(){
            let _this=this;
            $(document).off('click',hideMenuList.bind(_this));
            $(document).off('scroll',hideMenuList.bind(_this));
        },
        props:{
            leftItems:{type:Array,default:['back']},
            rightItems:{type:Array,default:[]},
            menuList:{type:Array,default:['index']},
            titleColor:{type:String,default:'black'},
            title:{type:String,default:'【大商天狗】大商集团电商平台'}
        },
        computed:{
            selfRightItems(){
                if(this.menuList.length){
                    return this.rightItems.concat(['more']);
                }
                return this.rightItems
            },
            selfMenuList(){
                if(!tg.getCookie('hybrid'))
                    return this.menuList && this.menuList.filter(item=>item!=='share');
                return this.menuList;
            }
        },
        methods:{
            titleClick(){
                this.$emit('title-click');
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    .tg-head{
        height:44px;
    }
    .hybrid .header_more{
        top:11px;
    }
    .header_more{
        position: fixed;
        top: 55px;
        right: 10px;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        background:#fff;
        border-radius: 2px;
        width: 135px;
        z-index: 99;
    }
    .header_more:after{
        content:'';
        width:10px;
        height:10px;
        background:#fff ;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        display: block;
        position: absolute;
        transform: rotate(135deg);
        top: -5px;
        right:10px;
    }
    .header_more ul{
        border-radius: 2px;
        background: #fff;
        position: relative;
        z-index: 99;
    }
    .header_more ul li{
        border-bottom:1px solid rgba(204,204,204,.6);
    }
    .header_more ul li:last-child{
        border-bottom:none;
    }
    .header_more ul li a{
        color: #222;
        display: -ms-flexbox;
        display: flex !important;
        -ms-flex-align: center;
        align-items: center;
	    -ms-flex-pack: start;
        justify-content: flex-start;
        i{
            font-size:20px;
            padding-right: .4rem;
        }
        span{
            line-height: 20px;
            display: inline-block;
        }
    }
    .header_right{
        span{display:none;}
        i{
            &:before{
                font-size:22px;
            }
        }
        i:not(:last-child){
            margin-right:.53333rem;
        }
    }
    .transparent{
        .tg-head{background: transparent}
        .header_left a,.header_right a{color:white}
    }
</style>
