<template>
    <div v-if="data.clickUrl" v-show="powderShow"
        class="powderShow pos-f left20 right20 bottom50 bg-white paddingh30 paddingv20">
        <!-- <a :href="data.clickUrl"> -->
        <a @click="changePage" data-scp="gz.0">
            <div class="flex-container">
                <div class="flex-container left">
                    <img src="//image1.51tiangou.com/tgou2/img2/index/img-powder_01.png" width="38" alt="">
                    <div class="fs-14 marginl20">
                        <div>成为大商天狗粉丝</div>
                        <div class="fs-11">
                            马上领取新人<span class="text-red">专享礼包</span>
                        </div>
                    </div>
                </div>
                <div class="badge big red pill pos-r"
                    style="padding: 6.5px 16px;font-size: 11px; margin-right:40px;margin-bottom:10px;">
                    关注
                    <img src="//image1.51tiangou.com/tgou2/img2/index/img-powder_02.png" style="right:-20px;"
                        class="pos-a" width="32" alt="">
                </div>
            </div>
        </a>
        <div class="pos-a top0 right0 padding10" @click="powderShow = false" data-scp="gb.0">
            <i class="icon-roundclosefill text-light fs-20"></i>
        </div>
    </div>
</template>

<script>
    // import tg from 'tgspa';
    export default {
        name: "followFans",
        props: {
            data: { type: Object, default: {} }
        },
        data() {
            return {
                powderShow: true
            }
        }
        , created() {
        }
        , destroyed() {
            // this.clearTimer();
        }
        , methods: {
            changePage: function() {
                // tg.changePage(this.data.clickUrl);
                window.location.href = this.data.clickUrl || '#';
            }
        }
        , watch: {}
    }
</script>

<style scoped>
    .powderShow {
        bottom: 60px;
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.10);
        border-radius: 8px;
        z-index: 100;
    }
</style>