<template>
    <a href="/purchase/index.html" data-scp="car.0"> <i class="icon icon-cart"></i><span>购物车</span> </a>
</template>

<script>
    export default {
        name: "cart"
    }
</script>

<style scoped>

</style>