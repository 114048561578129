var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tgPoster-type3" }, [
    _c("div", { staticClass: "list list-col2" }, [
      _c("div", { staticClass: "list-item" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.data.items[0].clickUrl,
              "data-scp":
                "ad_" + _vm.data.id + "_" + _vm.data.items[0].id + ".0",
            },
          },
          [_c("img", { attrs: { src: _vm.data.items[0].imageUrl, alt: "" } })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "list-item" }, [
        _c(
          "a",
          {
            attrs: {
              href: _vm.data.items[1].clickUrl,
              "data-scp":
                "ad_" + _vm.data.id + "_" + _vm.data.items[1].id + ".0",
            },
          },
          [_c("img", { attrs: { src: _vm.data.items[1].imageUrl, alt: "" } })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.data.items[2].clickUrl,
              "data-scp":
                "ad_" + _vm.data.id + "_" + _vm.data.items[2].id + ".0",
            },
          },
          [_c("img", { attrs: { src: _vm.data.items[2].imageUrl, alt: "" } })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }