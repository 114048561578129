<template>
    <div  class="tgPoster-type3">
        <div class="list list-col2">
            <div class="list-item">
                <a :href="data.items[0].clickUrl" :data-scp="`ad_${data.id}_${data.items[0].id}.0`">
                    <img :src="data.items[0].imageUrl" alt="">
                </a>
            </div>
            <div class="list-item">
                <a :href="data.items[1].clickUrl" :data-scp="`ad_${data.id}_${data.items[1].id}.0`">
                    <img :src="data.items[1].imageUrl" alt="">
                </a>
                <a :href="data.items[2].clickUrl" :data-scp="`ad_${data.id}_${data.items[2].id}.0`">
                    <img :src="data.items[2].imageUrl" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js';
    export default {
        name: "type3",
        mixins:[mixin],
    }
</script>

<style>

</style>