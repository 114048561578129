<template>
    <div class="activityTabbar pos-s" style="top: 0;z-index: 9">
        <div class="flex-container fs-14 lh-40" style="height: 40px;">
            <div class="scroll-container flex1" style="height: 42px;" ref="tabbarContainer">
                <span v-for="(item,index) in data" style="font-size:14px;" @click="change(index)" :data-scp="`aclick.${index}`" class="paddingh30 tabbarItem" :ref="`tabbarItem`" :class="{active:index===activeIndex}">{{item}}</span>
            </div>
            <div style="width:40px;" class="text-center" @click="show=!show" v-if="data.length>4">
                <i v-if="show" class="icon icon-fold" style="font-weight: bolder;transform: scale(0.8,1);"></i>
                <i v-else class="icon icon-unfold" style="font-weight: bolder;transform: scale(0.8,1);"></i>
            </div>
            <div class="list list-col3" style="top:100%;position: absolute;width:100%;z-index:9;" :style="{backgroundColor:bgColor}" v-show="show">
                <div class="list list-col3 paddingv10" style="background: rgba(0,0,0,.1);margin: 0;">
                    <div class="list-item text-center text-white" v-for="(item,index) in data" :class="{active:index===activeIndex}" :data-scp="`aclick.${index}`" @click="change(index)">
                        <i class="icon icon-locationfill marginr10" v-if="index===activeIndex"></i>{{item}}
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "tabbar",
        props:{
            data:{type:Array,default:[]},
            activeIndex:{type:Number,default:0},
            bgColor:{type:String,default:'rgba(26,24,24,0.86)'}
        },
        data:function(){
            return{
                show:false
            }
        },
        methods:{
            change(index){
                this.activeIndex=index;
                this.show=false;
                this.$emit('change',index);
            }
        },
        watch:{
            activeIndex(index){
                this.$refs.tabbarContainer.children[index].scrollIntoView(true)
            }
        }
    }
</script>

<style scoped lang="scss" type="text/scss">
    .tabbarItem{
        &.active{
            background-color: rgba(255, 255, 255,.2);
            border-bottom:1px solid white;
        }
    }
</style>
