import Super from "./class";
let serverList={
	get:{
		sign:'/publics/wechat/sign',
		getOpenId:'/publics/wechat/getOpenid',
        getUserInfoByAccessToken:'/publics/wechat/getUserInfoByAccessToken',
        getUserInfoByAppId:'/publics/wechat/getUserInfoByAppId'
		, webcastEntry: '/applet/broadcast/entry'
		, createScheme: '/publics/wechat/createScheme'
	},
	post:{
		getImageUrlByServerId: '/publics/wechat/getTempFile'
	},
};
export default new Super(serverList,'wechat');
