<template>
    <div class="tgPoster-type1">
<!--        <a :href="data.items[0].clickUrl" :data-scp="`one.0`">-->
<!--            <img :src="data.items[0].imageUrl" alt="">-->
<!--        </a>-->
	    <tg-wx-launch-poster :data="data.items[0]" :scp="`one.0`"></tg-wx-launch-poster>
    </div>
</template>

<script>
import mixin from './mixin.js';
import tgWxLaunchPoster   from '../../wxLaunch/poster.vue';
export default {
    name: "type1",
    mixins:[mixin],
	components: {
    	tgWxLaunchPoster
	}
}
</script>

<style>

</style>