var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-screen" }, [
    _vm.state === "ready"
      ? _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
          _c("div", {
            staticClass:
              "icon-close text-white btn-close text-center float-right",
            attrs: { "data-scp": "gb.0" },
            on: {
              click: function ($event) {
                return _vm.closeRedPacket("close")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-container", staticStyle: { width: "245px" } },
            [
              _c("img", {
                attrs: {
                  src: "//image1.51tiangou.com/tgou2/img2/redpacket/redpacket-start.png",
                  alt: "",
                  "data-scp": "qqhb.0",
                },
                on: { click: _vm.startRain },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn-start text-center text-bolder fs-16",
                  attrs: { "data-scp": "qqhb.0" },
                  on: { click: _vm.startRain },
                },
                [_vm._v("去抢红包")]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state === "countdown"
      ? _c(
          "div",
          {
            staticClass: "text-center",
            staticStyle: { width: "100%", height: "100%" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn-rule text-center text-bolder text-white float-left",
                attrs: { "data-scp": "hdgz.0" },
                on: { click: _vm.openRule },
              },
              [_vm._v("活动规则")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "icon-close text-white btn-close text-center float-right",
              attrs: { "data-scp": "gb.0" },
              on: {
                click: function ($event) {
                  return _vm.closeRedPacket()
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "animate-arise",
                staticStyle: { width: "100%", height: "100%" },
              },
              [
                _c("img", {
                  staticStyle: {
                    "margin-top": "134px",
                    width: "calc(100% - 48px)",
                  },
                  attrs: {
                    src: "//image1.51tiangou.com/tgou2/img2/redpacket/start-countdown.png",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "num-count text-bolder" }, [
                  _vm._v(_vm._s(_vm.downTimes) + "s后"),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.showRule
              ? _c("div", { staticClass: "modal-rule animate-arise" }, [
                  _c("div", { staticClass: "modal-bottom" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "rule-title text-center text-bolder fs-16 text-black",
                        staticStyle: { "margin-bottom": "21px" },
                      },
                      [_vm._v("活动规则")]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "fs-14 text-dark text-left",
                        staticStyle: { height: "260px", overflow: "auto" },
                      },
                      [_vm._v(_vm._s(_vm.rulesMes))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn-start text-center text-bolder fs-16",
                      staticStyle: { margin: "40px auto 0" },
                      on: { click: _vm.ruleStartRain },
                    },
                    [_vm._v("去抢红包")]
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state === "raining"
      ? _c(
          "div",
          {
            staticClass: "pos-r",
            staticStyle: { width: "100%", height: "100%", overflow: "hidden" },
          },
          [
            _c("div", { staticClass: "light-point point-1" }),
            _vm._v(" "),
            _c("div", { staticClass: "light-point point-2" }),
            _vm._v(" "),
            _c("div", { staticClass: "light-point point-3" }),
            _vm._v(" "),
            _c("div", { staticClass: "light-point point-4" }),
            _vm._v(" "),
            _c("div", { staticClass: "bg-red-rain" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pos-a text-white text-center click-count" },
              [_vm._v(_vm._s(_vm.clickCountDown) + "s")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pos-a fs-14 text-white",
                staticStyle: { top: "122px", left: "45px" },
              },
              [_vm._v("剩余时间")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "pos-a icon-close text-white btn-close text-center",
              staticStyle: { top: "80px", right: "40px", margin: "0" },
              attrs: { "data-scp": "gb.0" },
              on: {
                click: function ($event) {
                  return _vm.closeRedPacket()
                },
              },
            }),
            _vm._v(" "),
            _vm.clickCount >= 4 && _vm.clickCount < 8
              ? _c("img", {
                  staticClass: "pos-a animate-arise",
                  staticStyle: {
                    top: "calc(50% - 207px)",
                    left: "calc(50% - 114px)",
                    width: "228px",
                  },
                  attrs: {
                    src: "//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-1.png",
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.clickCount >= 8 && _vm.clickCount < 12
              ? _c("img", {
                  staticClass: "pos-a animate-arise",
                  staticStyle: {
                    top: "calc(50% - 207px)",
                    left: "calc(50% - 114px)",
                    width: "228px",
                  },
                  attrs: {
                    src: "//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-2.png",
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.clickCount >= 12 && _vm.clickCount < 16
              ? _c("img", {
                  staticClass: "pos-a animate-arise",
                  staticStyle: {
                    top: "calc(50% - 207px)",
                    left: "calc(50% - 114px)",
                    width: "228px",
                  },
                  attrs: {
                    src: "//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-3.png",
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.clickCount >= 16
              ? _c("img", {
                  staticClass: "pos-a animate-arise",
                  staticStyle: {
                    top: "calc(50% - 207px)",
                    left: "calc(50% - 114px)",
                    width: "228px",
                  },
                  attrs: {
                    src: "//image1.51tiangou.com/tgou2/img2/redpacket/tip-click-4.png",
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.clickCount,
                    expression: "clickCount",
                  },
                ],
                staticClass: "pos-a text-bolder num-click",
              },
              [_vm._v("+" + _vm._s(_vm.clickCount))]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "big-red-packet shake",
              attrs: { "data-scp": "qhb.0" },
              on: { click: _vm.addPacket },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state === "over"
      ? _c(
          "div",
          {
            staticClass: "animate-arise",
            staticStyle: { width: "100%", height: "100%" },
          },
          [
            _c("div", {
              staticClass:
                "icon-close text-white btn-close text-center float-right",
              attrs: { "data-scp": "gb.1" },
              on: {
                click: function ($event) {
                  return _vm.closeRedPacket()
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal-container",
                staticStyle: { width: "300px" },
              },
              [
                !_vm.prizeMes.isWin && _vm.lotteryOver
                  ? _c("img", {
                      attrs: {
                        src: "//image1.51tiangou.com/tgou2/img2/redpacket/no-lottery.png",
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.prizeMes.isWin && _vm.lotteryOver
                  ? _c("div", { staticClass: "get-prize" }, [
                      _c("img", {
                        attrs: {
                          src: "//image1.51tiangou.com/tgou2/img2/redpacket/get-prize.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "prize-info text-center pos-a" },
                        [
                          _c("span", { staticStyle: { "font-size": "28px" } }, [
                            _vm._v(_vm._s(_vm.prizeMes.couponInterestPoint)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "fs-16 text-bolder",
                              staticStyle: { "vertical-align": "text-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.prizeMes.couponType))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "fs-12 text-dark",
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.prizeMes.couponStartTime) +
                                  "-" +
                                  _vm._s(_vm.prizeMes.couponEndTime)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "fs-12 text-light" }, [
                            _vm._v("在我的-优惠券中查看"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.lotteryOver
                  ? _c("div", {
                      staticClass: "btn-start text-center text-bolder fs-16",
                      staticStyle: { margin: "40px auto 0" },
                      attrs: {
                        "data-scp": _vm.prizeMes.isWin ? "kxsx.0" : "wzdl.0",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.prizeMes.isWin ? "开心收下" : "我知道啦"
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeRedPacket()
                        },
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }