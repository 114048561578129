<template>
    <div class="img-container center tg-barcode" v-show="realContent">
        <img alt="">
    </div>
</template>

<script>
    import  "javascript/libs/tg/component/barcode/JsBarcode.all.min.js";
    export default {
        name: "barCode",
        mounted(){
            this.render();
        },
        props:{
            content:{
                type:String,
                default:''
            },
            color:{
                type:String,
                default:'black'
            },
            showText:{
                type:Boolean,
                default:true
            },
            background:{
                type:String,
                default:'transparent'
            }
        },
        computed:{
            realContent(){
                return this.content.match(/^[\x00-\xFF]+$/)?this.content:''
            }
        },
        methods:{
            render: function(){
                if(this.realContent){
                    JsBarcode(this.$el.getElementsByTagName('img')[0], this.realContent,{
                        lineColor: this.color,
                        displayValue:this.showText,
                        background:this.background
                    });
                }
            }
        },
        watch:{
            content(){
                this.render();
            },
        }
    }
</script>

<style scoped>

</style>