import Super from './class';

let serverList = {
        get: {
            getQuickPayConfig: '/publics/cashiers/getQuickPayConfig'    // 查询闪付收银台配置
            , prePayOrderParkInfo: '/publics/storePayMethod/queryPark'  // 收银台获取停车场订单信息
            , goldPointing: 'pay/result/goldPointing'
            , queryTimestamp: '/publics/ysf/timestamp/query'    // 获取服务器时间，url 加随机数防止缓存
        },
        post: {
            cardBalance: '/publics/dsk/cardBalance'  // 大商卡查询
            // , cardInfo: '/publics/virtual/dsk/query/info'   // 大商卡查询
            , cardBinded: '/publics/virtual/dsk/has/binded' // 是否已有绑定的线上大商卡
            , cardBind: '/publics/virtual/dsk/bindCard'     // 绑定线上大商卡
            , cardMine: '/publics/virtual/dsk/query/mine'   // 查询我的大商卡
            , cardCanUse: '/publics/virtual/dsk/can/use'    // 查询用户当前下单可用的大商卡金额
            , verifyPasswordSafe: '/publics/ysf/safe/password/verify'   // 验证支付密码
            , getCashiers: '/publics/cashiers/getCashiers'    // 收银台获取支付方式列表
            , payPark: '/publics/storePayMethod/parkPay'    // 收银台支付停车场
        }
    }
    ;

export default new Super(serverList, 'pay');