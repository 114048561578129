import Super from "./class";
let serverList={
  get:{
    // 发票抬头列表
    getReceiptTitleList: '/web/receiptTitle/getReceiptTitleList',
    // 默认抬头信息
    getDefaultReceiptTile: '/web/receiptTitle/getDefaultReceiptTile',
    // 获取发票信息
    getReceipt: '/web/receipt/getReceipt',
    // 撤销开票
    cancelReceipt: '/web/receipt/cancelReceipt',
    /**
     * 发送邮件
     * @param receiptId
     * @param email
     */
    sendEmailForReceipt: '/web/receipt/sendEmailForReceipt',
    /**
     * 查询订单可开发票金额
     * @param transNo - 交易识别码
     * @param orderId - 订单号
     */
    queryReceiptAmount: '/web/receipt/queryReceiptAmount',



  },
  post:{
    misOrderList: '/web/misMasterData/misOrderList',
    queryOrderDetail: '/web/misMasterData/queryOrderDetail',
    queryReturnOrder: '/web/misMasterData/queryReturnOrder',
    queryStores: '/web/misMasterData/queryStores',

    // 新增抬头
    addTitle: '/web/receiptTitle/addTitle',
    // 更新发票抬头
    updReceiptTitle: '/web/receiptTitle/updReceiptTitle',
    // 开票
    addReceipt: '/web/receipt/addReceipt',
    // 删除发票抬头
    delReceiptTitle: '/web/receiptTitle/delReceiptTitle',
    // 修改发票
    updReceipt: '/web/receipt/updReceipt',
    /**
     * 发票红冲（换票）
     * @param orderReceiptDTO - 换票的发票信息Json
     * @param originReceiptId - 发票id
     */
    changeReceipt: '/web/receipt/changeReceipt',

  },
};
export default new Super(serverList,'transplat');
