<template>
    <img alt="" width="100%" :data-img-placeholder="placeholder" :src="src" />
</template>

<script>
    export default {
        name: "img",
        props:{
            src:{type:String,default:''}
            , placeholder: {
                type: String
                , default: ''
            }
        }
    }
</script>

<style scoped>

</style>