import wechat from "./wx.js";
import { setCookie, getCookie, removeCookie } from "./util.js";
import baseService from "./api/base.js";
function autoPosition() {
	return new Promise(resolve => {
        if(!getCookie('cityName')||!getCookie('cityId')){
            setCookie('cityId','2554');
            setCookie('cityName','大连市');
        }
        if (!getCookie("isGPS")) {
            if (maple.device.weixin) {
                wechat.runFunction(function() {
                    wx.ready(function() {
                        wx.getLocation({
                            success: function({ latitude, longitude }) {
                                _updateGPSDate(latitude, longitude);
                            },
                            fail: function() {
                                setCookie("isGPS", true);
                            }
                        });
                    });
                });
            } else if(_.get(navigator,'geolocation.getCurrentPosition')){
                navigator.geolocation.getCurrentPosition(
                    function({ coords = {} }) {
                        let { latitude, longitude } = coords;
                        _updateGPSDate(latitude, longitude);
                    },
                    function() {
                        /*定位城市失败*/
                        setCookie("isGPS", true);
                    },
                    {
                        timeout: 2000,
                        enableHighAccuracy: true,
                        maximumAge: 5000
                    }
                );
            }
        }
        resolve();
	});
}
function _updateGPSDate(lat, lon) {
	if (!isNaN(lat) && !isNaN(lat)) {
		/*定位成功*/
		setCookie("isGPS", true, "1d");
		// 取得GPS定位城市
		_getGPSCity(lat, lon);
	} else {
		/*定位城市失败*/
		setCookie("isGPS", true);
	}
}
function _getGPSCity(lat, lon) {
	baseService
		.judgeStoreByCity({
			data:{ latitude: lat, longitude: lon }
		})
		.then(data => {
			if (data) {
				baseService
					.cityLocate({
						data:{ latitude: lat, longitude: lon }
					})
					.then(data => {
						let cityId = String(data.id);
						let cityName = String(data.name);
						let cityNameInCookie = getCookie("cityName");
						let cityIdInCookie = getCookie("cityId");
						console.log(cityId,cityIdInCookie);
						if (
							cityId &&
							cityId !== cityIdInCookie &&
							cityNameInCookie&&
                            cityNameInCookie!==cityName
						) {
							maple.confirm({
								title: "提示信息",
								content:
									"您当前选择的城市为" +
									cityNameInCookie +
									"，是否切换至" +
									cityName +
									"？",
								okFn: function() {
									setCookie("cityId", cityId, "365d");
									setCookie("cityName", cityName, "365d");
									removeCookie("storeId");
									setCookie("lat", lat, "1d");
									setCookie("lon", lon, "1d");
									maple.url().replace("cityId");
									maple.url().replace("storeId");
									maple.url().replace("source");
									location.reload();
								}
							});
						}
						setCookie("lat", lat, "1d");
						setCookie("lon", lon, "1d");
					});
			}
		});
}
export default autoPosition;