<template>
	<div class="coupon">
        <div class="text-red text-limit1 ribbon_next marginr10" v-for="item in data" :class="ribbonStyle(item)">
	        <div class="float-left"><span>{{item}}</span></div>
	    </div>
    </div>
</template>
<style type="text/css">
	.ribbon_next {
	    display: inline-block;
	    position: relative;
	    height: 20px;
	    min-width: 28px;
	    padding: 0 5px;
	    background: #fff4f5;
	    font-size: 11px;
	    line-height: 18px;
	    text-align: center;
	    vertical-align: middle;
	    white-space: nowrap;
	    overflow: hidden;
      border-top-left-radius:2px;
      border-top-right-radius:10px;
      border-bottom-right-radius:10px;
      border-bottom-left-radius:2px;
	}

    .ribbon_orange {
        border-radius: 10px;
        background: rgba(255, 145, 37, 0.1);
        border: 1px solid #FF9125;
        color: #FF9125;
    }

    .ribbon_orange_1 {
        border-radius: 10px;
        background: #FF9125;
        color: #fff;
    }

	.ribbon_next:before {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    height: 6px;
	    width: 6px;
	    border-radius: 50%;
	    background: #fff;
	    margin: auto;
	    content: "";
	    border: 1px solid #fff4f5
	}

	.ribbon_next:before {
	    left: -3px
	}
</style>
<script type="text/javascript">
export default {
    props: {
        data: Object,
        // 需求53109，天狗优选标签改为新版橘色
        isTgzy: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        // 秒杀标签单独样式
        ribbonStyle(item) {
            if (this.isTgzy) {
                if (item === '秒杀') {
                    return 'ribbon_orange_1'
                }
                return 'ribbon_orange';
            }
            return '';
        }
    }
}
</script>