<template>
<div>
	<!-- 产品参数 -->
	<div class="flex-container bg-white padding20 marginb20"
		 data-scp="parameter.0"
		 v-show="property.items.length"
		 @click="propertyState=!propertyState">
		<div class="text-dark">产品参数</div>
		<span class="dis-i marginl10"><span class="icon-right fs-11 text-light"></span></span>
	</div>
	<!-- 产品参数弹窗 -->
	<cmui-popup :visible.sync="propertyState" position="bottom" :stop-page-scroll="true">
		<div slot="top">
			<div class="text-dark text-center fs-15 padding20 pos-r">产品参数</div>
			<i class="icon-wrong pos-a right20 top30 fs-12 text-light"
			   @click="propertyState = !propertyState"></i>
		</div>
		<div class="margint-n10" style="overflow: hidden;margin-bottom: -1px;">
			<div class="paddingt40 paddingh30 bg-white" style="margin-bottom: -1px;" v-for="item in property.items">
				<div class="borderb paddingv30" v-text="item.title" v-show="property.items.length>1"></div>
				<div class="flex-container borderb paddingv30" v-for="property in item.property">
					<span class="fs-13 text-dark lh-20" v-text="property.propertyName + ':'" style="min-width:20%"></span>
					<span class="flex1 fs-12 lh-20" v-text="property.propertyValue"></span>
				</div>
			</div>
		</div>
		<div slot='bottom'>
			<div class="flex-container center bg-red text-white fs-15 bordert lh-24 padding20 margint40" @click="propertyState =! propertyState">确定</div>
		</div>
	</cmui-popup>
</div>
</template>

<script>
export default {
	name: 'tg-property'
	, props: {
		property: {
			/**
			 * @example
			 * {
				"items": [
					{
						"title": "产品参数",
						"property": [
							{
								"propertyName": "测试",
								"propertyValue": "boxing"
							}
						]
					}
				]
			}
			 * */
			type: Object
			, default: {
				items: []
			}
		}
	}
	, data(){
		return {
			propertyState: false
		}
	}
};
</script>