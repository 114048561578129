<template>
    <a href="javascript:void(0)" data-scp="share.0" @click="share">
        <i class="icon icon-share"></i><span>分享</span>
    </a>
</template>

<script>
    import app from 'javascript/libs/tg/app/index.js';
    export default {
        name: "share",
        methods:{
            share(){
                app.share(tg.getShare());
            }
        }
    }
</script>

<style scoped>

</style>