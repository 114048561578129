<template>
<!-- 进入门店 -->
<div class="borderv bg-white paddingb20" v-show="storeProductBanner.clickUrl">
	<a :href="storeProductBanner.clickUrl" data-scp="supermarket.store">
		<div class="flex-container margint20 padding20">
			<div class="flex-container left">
				<img :src="storeProductBanner.imageUrl||'//img1.tg-img.com/tgou2/img2/index/shop_default.png'"
					 onerror="this.src='//img1.tg-img.com/tgou2/img2/index/shop_default.png'" width="50" height="50" class="border">
				<div class="marginl20">
					<p class="fs-15 text-darker" v-text="storeProductBanner.title"></p>
					<p class="fs-11 text-dark margint10" v-if="storeProductBanner.subTitle"><span class="icon-locate text-dark"></span><span v-text="storeProductBanner.subTitle"></span></p>
				</div>
			</div>
			<div><span class="badge badge-reverse badge-pill badge-red" style="font-size:10px;padding: 4px 7px">进入门店</span></div>
		</div>
	</a>
	<div class="scroll-container bg-white paddingr20">
		<div class="border marginl20" style="width: 100px;"
			 v-for="(item, index) in storeProductBanner.items">
			<a :href="item.clickUrl" :data-scp="'supermarket.product'+index">
				<div class="ratio-container">
					<img :src="item.imageUrl||'//image1.51tiangou.com/tgou2/img/bg-load.png!s'" alt=""
						 onerror="this.src='//image1.51tiangou.com/tgou2/img/bg-load.png!s'">
				</div>
				<div class="padding10">
					<div class="text-limit1 fs-10 text-darker" v-text="item.title"></div>
					<div class="text-red flex-container margint10">
						<div class="fs-13" v-text="'¥'+ item.priceString"></div>
						<div @click.stop.prevent="itemEvent(item, 'supermarket.addToCart'+index)"><i class="icon-roundadd fs-16"></i></div>
					</div>
				</div>
			</a>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'tg-store'
	, props: {
		storeProductBanner: {
			/**
			 * @example
			 * {
				"title": "5026",
				"subTitle": "青泥洼桥9号",
				"imageUrl": "//test.img.tg-img.com/seller/201805/30/185BBCF0-DBD4-4730-BF4A-7F7828F433ED.jpg!y",
				"clickUrl": "//m.test.66buy.com.cn/store.html?storeId=1508",
				"items": [
					{
						"id": 5510763,
						"title": "miko超市饼干",
						"clickUrl": "//m.test.66buy.com.cn/product/listing.html?id=5510763",
						"imageUrl": "//test.img.tg-img.com/seller/201807/18/DF48A1F7-0E51-4A11-A31F-DF4C09336DE7.jpg!y",
						"storeId": 1508,
						"price": 690,
						"priceString": "690",
						"originalPrice": 690,
						"originalPriceString": "690",
						"soldQty": 0,
						"global": false
					}
				]
			}
			 * */
			type: Object
			, default: {
				items: []
			}
		}
	}
	, methods: {
		itemEvent(item, scp){
			this.$emit('add-item-to-cart', item, scp);
		}
	}
};
</script>